<div class="card-body-bloc bg-white border-0 p-3 customMargin">
    <div class="card-doc-title ml-3">
        <fa-icon class=" mr-2 fa-lg" [icon]="fa['faTasks']">
        </fa-icon> Détails
    </div>
    <form>
        <div class="row p-3">
            <div class="col-3">
                <div class="form-group">
                    
                    <mat-form-field class="w-100">
                        <mat-label>Sujet ticket</mat-label>
                        <input [(ngModel)]="informationTicket.sujet" matInput placeholder="Sujet ticket " required #sujetT="ngModel" name="sujetT" (change)="doSomething($event)">
                        <mat-error *ngIf="sujetT.invalid && (sujetT.dirty || sujetT.touched)" >
                            <div *ngIf="sujetT.errors.required">
                                <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                Le sujet de tickets est obligatoire!
                            </div>
                        </mat-error>
                    </mat-form-field>
                    
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                    <label class="text-secondary font-weight-bold">Affecté à</label>
                    <mat-form-field class="w-100">
                        <input type="text"  [(ngModel)]="this.informationTicket.affecte_a"  matInput [formControl]="myControlaff" [matAutocomplete]="auto" (change)="onInput($event.target.value)">
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectUser($event.option.value)">
                          <mat-option *ngFor="let option of allAffectefilter | async" [value]="option">
                            {{option.nom}} {{option.prenom}}
                          </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="myControlaff.invalid && (myControlaff.dirty || myControlaff.touched)" >
                            <div *ngIf="myControlaff.hasError('required')">
                                <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                                Utilisateur obligatoire!
                            </div>
                        </mat-error>
                        <mat-error *ngIf="myControlaff.errors?.invalidUser">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Veuillez vérifier l'utilisateur!
                        </mat-error>
                      </mat-form-field>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <mat-form-field class="w-100">
                        <mat-label>Prospect/Client</mat-label>
                        <input [(ngModel)]="informationTicket.prospect" name="prospect" placeholder="Prospect " matInput disabled (change)="doSomething($event)">
                    </mat-form-field>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <mat-form-field appearance="fill" class="w-100">
                    <mat-label>Date de création</mat-label>
                        <input matInput [matDatepicker]="picker1" [(ngModel)]="informationTicket.date_creation" name="date_creation" placeholder="Date de création" disabled (change)="doSomething($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-4">
                <div class="form-group">
                  
                    <mat-form-field appearance="fill" class="w-100">
                        <mat-label>Date Limite</mat-label>
                        <input matInput [matDatepicker]="picker3" [(ngModel)]="informationTicket.date_limit" name="date_limit" placeholder="Date Limite" [min]="minDate" (ngModelChange)="doSomething($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
     <!--        <div class="col-3" *ngIf="!isDisabled">
                <label class="text-secondary font-weight-bold" *ngIf="informationTicket.delai_traitement !== null">Délai Conventionnel</label>
                <ng-container *ngIf="loader_delai">
                    <div class="row">
                        <div class="col-12 text-center">
                            <div class="d-flex justify-content-center my-3">
                                <mat-spinner [diameter]="30"></mat-spinner>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!loader_delai">
                    <div class="card shadow bg-white rounded" *ngIf="informationTicket.delai_traitement !== null">
                        <div class="card-body text-center">
                            {{delai_convent}}
                        </div>
                    </div>
                    <div class="card shadow bg-white rounded mt-4" *ngIf="informationTicket.delai_traitement == null">
                        <div class="card-body text-center">
                            {{traitementDate}}
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="col-3" *ngIf="isDisabled ">
                <label class="text-secondary font-weight-bold">Date Fermeture</label>
                <ng-container *ngIf="loader_delai">
                    <div class="row">
                        <div class="col-12 text-center">
                            <div class="d-flex justify-content-center my-3">
                                <mat-spinner [diameter]="30"></mat-spinner>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!loader_delai">
                    <div class="card shadow bg-white rounded">
                        <div class="card-body text-center">
                            {{DateFermeture}}
                            <br> {{delai_ferm}}
                        </div>
                    </div>
                </ng-container>
            </div> -->
            <div class="col-12 mt-3">
                <app-add-tasks [id_opportunite]="tickets_id"  [fromType]="'T'"></app-add-tasks>

            </div>
            <!--  <div class="col-12">
                <form>
                    <div class="row">
                        <div class="col-11 ">
                            <mat-form-field appearance="outline" class="tache">
                                <mat-label>Ajouter une nouvelle tâche</mat-label>
                                <input [(ngModel)]="tacheName" name="newTodo" matInput placeholder="Titre/tâches">
                            </mat-form-field>
                        </div>
                        <div class="col-1 d-flex flex-row-reverse pr-2 pt-2">
                            <button type="button" class="btn add-btn-etache border-0 text-light p-1" [disabled]="tacheName===''" (click)="addTask()" data-toggle="modal" data-target="#exampleModal">
                <fa-icon style="font-size: 24px;" [icon]="fa['faPlusCircle']"></fa-icon>
              </button>
                        </div>
                    </div>
                </form>
                <ng-container *ngIf="loader">
                    <div class="row">
                        <div class="col-12 text-center">
                            <div class="d-flex justify-content-center my-3">
                                <mat-spinner [diameter]="30"></mat-spinner>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!loader">

                    <mat-label *ngIf="allTaches.length">Liste des tâches</mat-label>
                    <ul class="task-list pl-0">
                        <li class="example-box d-flex py-2 mb-2 border-bottom" *ngFor="let tache of allTaches;let i=index">
                            <div class="row w-100">
                                <div class="col-11">
                                    <mat-checkbox class="checkbox mt-0" [checked]="tache.statut" name="check" (change)="updateStatus(tache.id,$event)">
                                        <span *ngIf='tache.statut==0' class="task-added ml-2" [ngClass]="{'checked': tache.statut==0}">
                                          <b>  {{ tache.titre }}<span *ngIf="tache.prospect_id==null">( {{ tache.user_full_name }} )</span>
                                            <span *ngFor="let option of ListCLientProspect" >
                                                <span *ngIf="tache.prospect_id!=null && option.id==tache.prospect_id">({{option.full_name}})</span></span> </b>
                                                <span class=" text-break " > {{ tache.description }} </span>
                                        </span>
                                        <span *ngIf='tache.statut==1' class="task-added ml-2 w-100" style="text-decoration: line-through; " [ngClass]="{'checked': tache.statut==1}">
                                          <b> {{ tache.titre }}<span *ngIf="tache.prospect_id==null">( {{ tache.user_full_name }} )</span>
                                            <span *ngFor="let option of ListCLientProspect" >
                                                <span *ngIf="tache.prospect_id!=null && option.id==tache.prospect_id">({{option.full_name}})</span></span> </b>
                                                <span class=" text-break" > {{ tache.description }} </span>
                                        </span>
                                    </mat-checkbox>
                                </div>
                                <div class="col-1 d-flex flex-row-reverse pr-2">
                                  <button  type="button" class="btn-dt-list" (click)="modifyTask(tache, i)" data-toggle="modal" data-target="#exampleModal">
                                    <img src="/assets/icons/edit-icon.svg">
                                  </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </ng-container>
            </div> -->
            <div class="col-12 mt-4">
                <div class="form-group">
                    <label class="text-secondary font-weight-bold">Description</label>
                    <!-- <div [froalaEditor] [(froalaModel)]="informationTicket.commentaire" name="commentaire" style="z-index: 1;"></div> -->
                    <angular-editor class="w-100 mt-4"  name="commentaire"  (ngModelChange)="doSomething($event)"  [(ngModel)]="informationTicket.commentaire" style="z-index: 1;" [config]="config2" ></angular-editor>
                </div>
            </div>
        </div>
    </form>

</div>
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" data-backdrop="static" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-l" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel" *ngIf="modeAjoutTache">Ajouter une Tâche</h2>
                <h2 class="modal-title" id="exampleModalLabel" *ngIf="!modeAjoutTache">Modifier la Tâche</h2>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <form #tacheForm="ngForm">
                <div class="modal-body">
                    <div class="row">
                        <!-- <div class="form-group w-100"> -->
                        <div class="col-md-12">
                            <mat-form-field *ngIf="!modeAjoutTache" class="w-100">
                                <h4 class="w-100">Titre</h4>
                                <input [(ngModel)]="tickets_info.titre" matInput name="titre-tache" >
                            </mat-form-field>
                        </div>
                        <!-- <label class="title text-secondary font-weight-bold">Affecté à</label> -->
                        <div class="col-md-12">
                            <h4 class="w-100">Affecté à</h4>
                        </div>
                        <div class="col-md-12 d-flex">
                            <div class="col-md-6 " *ngFor="let type of types">
                                <input name="type" type="radio" id="{{type.viewValue}}" [checked]="typeselectedAffect==type.viewValue" (change)="getList(type.viewValue,$event)" >
                                <label class="ml-3" for="{{type.viewValue}}">{{type.viewValue}}</label>
                            </div>
                        </div>
                        <div class="col-md-12 " [ngSwitch]="typeselectedAffect">

                            <div class="col-md-12 mx-0 px-0" *ngSwitchCase="'Utilisateur'">
                                <!-- <mat-spinner *ngIf="!allAffecte.length" [diameter]="15"></mat-spinner>
                              <ng-autocomplete  #autoServicepopup [data]="allAffecte" [notFoundText]=" 'Utilisateur n\'existe pas'"  [(ngModel)]="selectedAffecte" (change)="onChangeSearchPopup($event.target.value)" [searchKeyword]="'nom_prenom'" [itemTemplate]="itemnomPrenomUser"
                              [notFoundTemplate]="notFoundnomPrenomUser" (selected)="getValue($event)" name="IdAffecte" placeholder="{{typeselectedAffect}}">
                              </ng-autocomplete>
                              <ng-template #itemnomPrenomUser let-item>
                                  <a [innerHTML]="item.nom+' '+item.prenom"></a>
                              </ng-template>
                              <ng-template #notFoundnomPrenomUser let-notFound>
                                  <div [innerHTML]="notFound">
                                  </div>
                              </ng-template> -->
                                <mat-form-field class="w-100">
                                    <mat-label>{{typeselectedAffect}}</mat-label>
                                    <input type="text" matInput [(ngModel)]="Utilisateur" [formControl]="myControlaff" [matAutocomplete]="auto" >
                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnn">
                                        <mat-option *ngFor="let option of allAffectefilter | async" [value]="option">
                                            {{option.nom}} {{option.prenom}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 mx-0 px-0" *ngSwitchDefault>
                                <mat-form-field class="w-100">
                                    <mat-label>{{typeselectedAffect}}</mat-label>
                                    <input type="text" matInput [(ngModel)]="prospect" [formControl]="myControl" [matAutocomplete]="auto" >
                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                            {{option.full_name}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- </div> -->
                    </div>
                    <!-- Edit 22/06/2022 -->

                    <div class="col-md-12" *ngIf="modeAjoutTache">
                        <h4 class="mb-0 w-100">Mots clés</h4>
                        <span *ngFor="let tagsAll of tagsList">
                        <span *ngFor="let tags of listselectedtags" >
                            <span class="badge badge-primary p-2 m-1" *ngIf="tagsAll.id==tags">{{tagsAll.libelle}}</span>
                        </span>
                        </span>
                    </div>
                    <div class="col-md-12" *ngIf="!modeAjoutTache">
                        <h4 class="mb-0 w-100">Mots clés</h4>
                        <span *ngFor="let tags of listselectedtags">
                            <span class="badge badge-primary p-2 m-1" >{{tags.libelle}}</span>
                        </span>
                    </div>
                    <div class="col-md-12 ">
                        <mat-form-field class="w-100">
                            <mat-label>Mots clés</mat-label>
                            <mat-select placeholder="tags" [(ngModel)]="motcles" (selectionChange)="changeTags($event.value)" [formControl]="tags" multiple>
                                <div class="input-icons w-100">
                                    <i class="fa fa-search p-1 position-absolute"></i>
                                    <input class="recherche p-1 w-100" onblur="this.focus()" (keyup)="onKey($event.target.value)" >
                                </div>
                                <mat-option *ngFor="let tags of tagsList" [value]="tags.id">{{tags.libelle}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div class="col-md-12 d-flex">

                        <div class="col-md-6 mx-0 px-0">
                            <h4 class="mb-0"> Priorité</h4>
                            <mat-form-field>
                                <mat-label>Priorité</mat-label>
                                <mat-select [(ngModel)]="priori" [formControl]="priorite">
                                    <mat-option *ngFor="let priorite of priorites" [value]="priorite.id">
                                        {{priorite.libelle}}
                                        <fa-icon [icon]="fa[priorite.icon]">
                                        </fa-icon>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>


                        <div class="col-md-6  mx-0 px-0">
                            <h4 class="mb-0">Date d'échéance</h4>
                            <mat-form-field>
                                <mat-label>Date d'échéance</mat-label>
                                <input matInput [matDatepicker]="picker" [(ngModel)]="echeance" [formControl]="echeanceC" >
                                <mat-hint>DD/MM/YYYY</mat-hint>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>

                        </div>
                    </div>

                    <!-- Fin Edit -->
                    <div class="col-md-12">
                        <h4 class="titleN"> Message/description </h4>
                    </div>
                    <div class="col-md-12">
                        <mat-form-field class="w-100">
                            <mat-label> Message/description </mat-label>
                            <textarea matInput [(ngModel)]="tickets_info.description" name="message" placeholder="Description"></textarea>
                        </mat-form-field>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="reset" class="btn-dt-cancel-popup" (click)="cancelAddOrUpdate()" data-dismiss="modal">
                    <fa-icon class="fa-icon-style mt-1" [icon]="fa['faTimesCircle']"></fa-icon>&nbsp;
                    Annuler
                  </button>

                    <button type="button" class="btn-dt-save-popup" (click)="addTodo($event)" data-dismiss="modal">
                    <fa-icon class="fa-icon-style mt-1" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
                    Enregistrer
                  </button>
                </div>
            </form>
        </div>
    </div>
</div>