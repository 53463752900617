  <div class="flex-column bg-white rounded-card">
    <div class="header-card-gestion py-3">
      <div class="col-3">
      <button type="button" class="btn-dt-add  ml-4" routerLink="fiche/nouveau"  data-title="Ajouter une gamme">
        <fa-icon  [icon]="fa['faPlus']"></fa-icon>
      </button>
    </div>
    </div>
    <div class="p-3 mb-3" >
      <table id="Table" class="table">
        <thead class="head-table">
          <tr>
            <th>#</th>
            <th>Nom de la Gamme</th>
            <th>Compagnie</th>
            <th>Professionnelle</th>
            <th>Url Tarification</th>
            <th>Ligne de Produit</th>
            <th>Action</th>
            </tr>
        </thead>
        <tbody class="body-table">
          <tr  *ngFor="let item of Gammes; let index = index">
            <td>{{ index+1 }}</td>
            <td>{{ item.nom }}</td>
            <td>{{ item.denomination }}</td>
            <td>
              <span  >
                <img src="/assets/icons/check-circle.svg" *ngIf="item.pro===1">
                <img src="/assets/icons/times-icon.svg" *ngIf="item.pro==0  || item.pro===null">
              </span>
            </td>
            <td>
              <span *ngIf="item.url_tarification">
                <a href="{{ item.url_tarification }}"  target = "_blank">
                  <button  type="button" class="btn-dt-ouvrir" >
                    <fa-icon class="icon-btn-ouvrir" [icon]="fa['faExternalLinkAlt']"></fa-icon>&nbsp;
                    Ouvrir
                  </button>
                </a>
              </span>
            </td>
            <td class="style-tds"> {{ item.type_contrat }}</td>
            <td>
              <button type="button" class="btn-dt-list" routerLink="fiche/{{item.id}}">
                <img src="/assets/icons/edit-icon.svg">
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
