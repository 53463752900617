<div class="card-headd text-center">
    <div class="row details-header">
        <button type="button" class="btn-dt-retourr mt-1" (click)="returnToList()">
            <div class="icon-position">
                <fa-icon class="fa-icon-style" [icon]="fa['faArrowLeft']"></fa-icon>&nbsp;
            </div>
        </button>
        <div class="row">
            <div class=" row details-spinner">
                <div class="col"> </div>
                <div class="mt-2 mr-2" *ngIf="loader">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </div>
            <!--     <div>
                    <button type="button" class="btn-load" id="clear" (click)="Actualise()">
                        <fa-icon [icon]="fa['faSyncAlt']"></fa-icon>
                    </button>
                </div> -->
            </div>


            <app-shared-button-enrg data-toggle="modal" data-target="#Actions" (click)="getStatus()" class="ml-2"
                [type]="'o'" [label]="Enregistrer" class="ml-2"></app-shared-button-enrg>
        </div>
    </div>

</div>


<div class="row custem-margin">
    <div class="col-4 w-100">
        <div class="card-head w-100">
            <div class="row justify-content-center">
              
                <div class="text-nowrap col-12" *ngIf="!informationTicket.id_affaire || !informationTicket.real_id_opp">
                    <label class="label-card-info"></label>
                </div>
                <div class="text-nowrap col-12">
                    Ticket N°:
                    <label class="label-card-info">
                        {{informationTicket.id_ticket}}
                    </label>
                </div>

                <div class="text-nowrap col-12" *ngIf="informationTicket.id_affaire">
                    Affaire N°:
                    <label class="label-card-info" >
                        <a target="_blank" class="a-style row" (click)="NaviagateToAffaireDetials(informationTicket.id_affaire)" style="cursor: pointer;">
                            {{informationTicket.real_id_affaire}}
                                    </a>
                    </label>
                </div>
                <div class="text-nowrap col-12" *ngIf="informationTicket.real_id_opp">
                    Opportunité N°:
                    <label class="label-card-info">
                        <a target="_blank" class="a-style row" (click)="NaviagateToOPpDetials(informationTicket.id_opp)" style="cursor: pointer;">
                            {{informationTicket.real_id_opp}}
                                    </a>
                    </label>
                </div>

            

            </div>
        </div>

    </div>

    <div class="col-4 w-100 ">
        <div class="card-head mr-2 w-100">
            <div class="row justify-content-center">
                <div class="col-12 text-nowrap ">
                    Crée par:
                    <label class="label-card-info">
                        {{informationTicket.createur}} 
                    </label>
                </div>
                <div class="text-nowrap col-12 ">
                    Date limite:
                    <label class="label-card-info">
                        {{informationTicket.date_limit ? informationTicket.date_limit :'Pas de date limite'}}  
                    </label>
                </div>

                <div class="text-nowrap col-12">
                    Catégorie:
                    <label class="label-card-info">
                        {{informationTicket.categorie}}
                    </label>
                </div>


            </div>
        </div>

    </div>

    <div class="col-4 w-100 ">
        <div class="card-head mr-2 w-100">
            <div class="row">
                <div class="text-nowrap col-12">
                    <div class="row text-nowrap">
                     <div class="col-2 mrgin  ">
                            <label class="label-card-info"> </label>
                     </div>
                     <div class="col-6"></div>
                     <div class="col">
                        <svg data-toggle="modal"  data-target="#Actions" (click)="openModalUpdate()"
                         class=" float-right cursor-pointer" width="25" height="25"
                        viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12.5" cy="12.5" r="12.5" fill="#D9D9D9" />
                        <path
                            d="M14.0857 6.91356L18.0862 10.9143L9.39929 19.6016L5.83251 19.9953C5.35502 20.0482 4.95159 19.6444 5.00471 19.1669L5.40158 15.5975L14.0857 6.91356ZM20.5606 6.31793L18.6822 4.43946C18.0962 3.85351 17.146 3.85351 16.56 4.43946L14.7929 6.20668L18.7934 10.2074L20.5606 8.44015C21.1465 7.85389 21.1465 6.90388 20.5606 6.31793Z"
                            fill="#474747" />
                    </svg>
                     </div>
                    
                    </div>
                    
                </div>
               
                <div class="text-nowrap col-12">
                  <label>  Statut actuel :</label>
                  <label class="VAD text-center border-0 ml-3"
                  [ngStyle]="{'background':informationTicket.statut_details?.background_color}">
                    <span [ngStyle]="{'color':informationTicket.statut_details?.color}" *ngIf="informationTicket.statut_details?.icon">
                    <fa-icon class="mr-2" [icon]="fa[informationTicket.statut_details?.icon]"></fa-icon>
                    {{informationTicket.statut_details?.libelle.split('(')[0].trim()}}
                    </span>
              </label>
                </div>
                <div class="text-nowrap col-12" *ngIf="!isDisabled">
                    
                        Délai Conventionnel: 
                          <label class="label-card-info" *ngIf="informationTicket.delai_traitement !== null">
                              {{delai_convent}}
                        </label>
                        <label class="label-card-info" *ngIf="informationTicket.delai_traitement == null">
                            {{traitementDate}}
                      </label>      
                </div>
                <div class="text-nowrap col-12" *ngIf="isDisabled">
                   
                        Date Fermeture
                        <label class="label-card-info">{{DateFermeture}}</label>
            
                  
                </div>

                </div>
        </div>

    </div>
</div>
















<div class="row">
    <div class="col ml-3" style="max-width: 248px;">
        <div class="sidebar-ticket" id="scrollbar-ticket">
            <div class="card-ticket bg-white text-center border-0">

                <div class="col py-1 ">
                    <button (click)="scroll('infoTicket')"
                        class="focus-style d-block border-0 full-width text-left pl-3">
                        <label class="align-details-icon">
                            <fa-icon class="fa-lg" [icon]="fa['faTasks']"></fa-icon>
                        </label>
                        <span>Détails</span>
                    </button>
                </div>

                <div class="col py-1 " *ngIf="informationTicket.id_prospect!==null">
                    <button (click)="scroll('infoProspect')"
                        class="focus-style d-block border-0 full-width text-left pl-3">
                        <label class="align-details-icon">
                            <fa-icon class="fa-lg" [icon]="fa['faUserTie']"></fa-icon>
                        </label>
                        <span *ngIf="informationTicket.id_prospect!==null && informationTicket.id_affaire===null">
                            Informations prospect</span>
                        <span *ngIf="informationTicket.id_affaire!==null && informationTicket.id_prospect!==null">
                            Informations client </span>
                    </button>
                </div>

                <div class="col py-1 ">
                    <button (click)="scroll('infoSpecifique')"
                        class="focus-style d-block border-0 full-width text-left pl-3">
                        <label class="align-details-icon">
                            <fa-icon class="fa-lg" [icon]="fa['faInfoCircle']"></fa-icon>
                        </label>
                        <span>Informations spécifiques</span>
                    </button>
                </div>

                <div class="col py-1 ">
                    <button (click)="scroll('porteDocument')"
                        class="focus-style d-block border-0 full-width text-left pl-3">
                        <label class="align-details-icon">
                            <fa-icon class="fa-lg" [icon]="fa['faFileImport']"></fa-icon>
                        </label>
                        <span>Porte Documents</span>
                    </button>
                </div>

                <div class="col py-1 ">
                    <button (click)="scroll('comment')" class="focus-style d-block border-0 full-width text-left pl-3">
                        <label class="align-details-icon">
                            <fa-icon class="fa-lg" [icon]="fa['faCommentDots']"></fa-icon>
                        </label>
                        <span>Commentaires</span>
                    </button>
                </div>

                <div class="col py-1 ">
                    <button (click)="scroll('history')" class="focus-style d-block border-0 full-width text-left pl-3">
                        <label class="align-details-icon">
                            <fa-icon class="fa-lg" [icon]="fa['faHistory']"></fa-icon>
                        </label>
                        <span>Historique</span>
                    </button>
                </div>
            </div>

            <div class="text-left">
                <shared-tags [tickets_id]="tickets_id"></shared-tags>
            </div>
        </div>
    </div>

    <div class="col overflow-auto">

        <div class="row w-100">
            <div class="col-12 p-0 w-100">
                <div class=" pt-1 mb-2 custem-R" id="infoTicket">
                    <app-informations-ticket (emitUpdateTicket)="alertConfirmUpdate($event)"></app-informations-ticket>
                </div>
                <div class=" pt-1 mb-2 customMargin custem-R" id="infoProspect" *ngIf="informationTicket.id_prospect || informationTicket.id_affaire ">
                    <app-ticket-coordonnees-prospect></app-ticket-coordonnees-prospect> 

                </div>

                <div class=" pt-1 mb-2 custem-R" id="infoSpecifique">
                    <app-ticket-informations-specifique (emitFormTicket)="geEmitInfoSpecifique($event)"
                        (changeFormInfoSpecifique)="getChangeForm($event)"></app-ticket-informations-specifique>
                </div>


                <div class=" pt-1 mb-2 custem-R" id="porteDocument">
                    <app-liste-documents></app-liste-documents>
                </div>

                <div class=" pt-1 customMargin pb-2 custem-R" id="comment">
                    <app-commentaires-client-ticket></app-commentaires-client-ticket>
                </div>

                <div class=" pt-1 mb-5 custem-R" id="history">
                    <app-ticket-historique></app-ticket-historique>
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="appearModel">
    <div class="modal fade" id="Actions" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog  actions-dialog" role="document">
            <div class="modal-content modal-first w-100">
                <div class="modal-header text-center">
                    <div class="row w-100">
                        <div class="col-3">

                        </div>
                        <div class="col-6">
                            <h2 class="text-center title justify-content-center text-nowrap">
                                Mise à jour Ticket
                            </h2>
                        </div>
                    </div>
                    <div class="row">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                            (click)="resetStatus()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
                <div class="modal-body" >
                    <div class="row text-center justify-content-center" *ngIf="!appear && !step">
                        <div class="row">
                            <p class="custemPara">
                                Pour modifier le statut de ce ticket, cliquez sur “Changer le statut”, sinon
                                enregistrer
                                directement les modifications. </p>
                        </div>
                        <div class="row d-flex justify-content-center mt-2 mb-3">
                            <div class="col-6">
                                <button type="button" class="btn-dt-save-popup sizeC mr-3"
                                    [allowed_to_update]="allowed_to_update_affaire" (click)="updateTicket()"
                                    data-dismiss="modal" aria-label="Close">
                                    <fa-icon class="fa-icon-style mt-1" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
                                    Enregistrer et quiter
                                </button>
                            </div>
                            <div class="col-6">
                                <button type="button" class="changeStatus" (click)="appearListStatut()">
                                    <span class="mb-1">Changer le statut</span>
                                    <fa-icon class="fa-icon-style sIcon mt-1" [icon]="fa['faArrowRight']"></fa-icon>&nbsp;
                                </button>
                            </div>



                        </div>
                    </div>
                    <div class="row text-center justify-content-center paddingBlock" *ngIf="appear ">
                        <ng-container *ngIf="loaderStatus">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <div
                                        style="width: min-content; margin: auto; margin-bottom: 50px; margin-top: 50px">
                                        <mat-spinner [diameter]="30"></mat-spinner>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <div class="row text-center justify-content-center w-100" *ngIf="!loaderStatus ">

                            <div class="col w-100 paddingBlock scrollAp">
                                <div class="row w-100">

                                    <div class="col-12 w-100">
                                        <div class="row w-100 justify-content-center mt-3">
                                            <ng-container >
                                                <div class="col-3 w-100 justify-content-center" *ngFor="let status of statusList">

                                                    <label class="ticket-status cursor-pointer ml-2  "
                                                        (click)="changeStatus(status.libelle,status.color,status.id,status.icon)"
                                                        [ngStyle]="{'background':status?.background_color}"
                                                        data-dismiss="modal">
                                                        <span [ngStyle]="{'color':status?.color}"
                                                            class="pt-1 text-center"
                                                            Style="font-family: 'Nunito Sans'; margin: auto;">
                                                            <fa-icon class="mr-1" [icon]="fa[status?.icon]"></fa-icon>
                                                            {{status.libelle.split('(')[0].trim()}}
                                                        </span>
                                                    </label>
                                                </div>
                                            </ng-container>

                                        </div>
                                        <div class="row w-100 mt-3 justify-content-center">
                                          
                                            <button type="reset" class="btn-dt-cancel-popup" data-dismiss="modal"
                                                aria-label="Close" (click)="resetStatus()">
                                                <fa-icon class="fa-icon-style fa-lg"
                                                    [icon]="fa['faTimesCircle']"></fa-icon>&nbsp;
                                                Annuler
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>