

            <div class="dropdown">

              <button class="btn-save" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" title="Enregistrer favoris"
                aria-expanded="false" (click)="showhidePopfirst()">
                <img  id="filterImage" src="assets/icons/iconfavorie-hover.svg" class="filter-image">
              </button>

              <div class="dropdown-menu popup" aria-labelledby="dropdownMenuButton" [ngClass]="{'hidden': !isDropdownVisible}">

                <div class="popup-content" (click)="stopClickPropagation($event)">
                  <div class="row mb-2">

                    <span class="title">Ajouter aux favories</span>
                    <button type="button"  (click)="showhidePop()" class="close-button">&times;</button>
                  </div>
                  <div class="row">
                  <div class=" col-md-12">
                    <div class="form-group w-100">
                      <mat-form-field class="full-width">
                        <mat-label>Nom</mat-label>
                        <input matInput [(ngModel)]="Libellefiltre" (keyup)="detecterror()" name="Libelle">
                          <mat-hint *ngIf="errrorFilterLibelle==true" >
                            Le Nom est obligatoire !
                          </mat-hint>
                        </mat-form-field>
                    </div>
                  </div>
                  <div class=" col-md-12">
                    <div class="form-group w-100">
                <mat-form-field class="w-100 p-0 m-0">
                    <mat-label>Mes collections</mat-label>

                    <mat-chip-list  #RechercheCollection>
                      <mat-chip *ngFor="let collection of listCollectionSelected" [selectable]="selectable"
                       [removable]="removable" (removed)="removeCollection(collection)"
                          >
                          {{collection.libelle}}
                          <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                          </fa-icon>
                      </mat-chip>
                      <input #collInput placeholder="Mes collections"
                      matInput [formControl]="collectionForm" [matAutocomplete]="auto"
                          [matChipInputFor]="RechercheCollection" [readonly]="isAutocompleteReadOnly" >
                  </mat-chip-list>
                    <mat-autocomplete  (optionSelected)="onSelectCollection($event)" autoActiveFirstOption [displayWith]="displayCollFn" #auto="matAutocomplete">
                        <mat-option *ngFor="let cardItem of filteredCollection |async" [value]="cardItem"  (click)="stopClickPropagation($event)">
                            {{cardItem.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-hint *ngIf="errrorCollectionLibelleA==true" >
                      Le libellé de la collection est obligatoire !
                    </mat-hint>
                  </mat-form-field>



                  </div>
                </div>
                <div class="col-md-12  d-flex justify-content-center align-items-center"
                [ngClass]="{'disabled': !isClickEnabled}">
                 <button [disabled]="!isClickEnabled" class="row newCollection" (click)="addNewCollection()">
                   <fa-icon [icon]="fa['faPlusCircle']"></fa-icon>&nbsp;
                   Nouvelle Collection
                 </button>
               </div>
               <div class="col-md-12 " *ngIf="checkAddCollection">
                <div class="form-group w-100">
                  <mat-form-field class="full-width">
                    <mat-label>Libellé Collection</mat-label>
                    <input matInput [(ngModel)]="LibelleCollection" name="LibelleCollection">
                      <mat-hint *ngIf="errrorCollectionLibelle==true" >
                        Le libellé de la collection est obligatoire !
                      </mat-hint>
                  </mat-form-field>
                </div>
              </div>
              </div>


                  <div class="row justify-content-center align-items-center footer-row">
                    <button class="  btn-dt-saved-filter2" type="button" (click)="AddFilterCollection()" [disabled]="isSaveDisabled" >
                      <fa-icon class="fa-lg" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
                    Enregistrer
                    </button> 
                    <button  type="button" class=" ml-2 btn-dt-cancel-popup" (click)="resetAddFilter()">
                      <fa-icon class="fa-icon-style" [icon]="fa['faTimesCircle']"></fa-icon>&nbsp;
                      Annuler
                    </button>
                   

                  </div>
                </div>
              </div>
            </div>
