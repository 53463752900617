import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as fa from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { ApiGroupGarantieService } from 'src/app/services/ApiGroupGarantie/api-group-garantie.service';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import swal from 'sweetalert2';

class GoupGarantie{
  libelle : string;
  descriotion : string;
}
@Component({
  selector: 'app-add-group-garantie',
  templateUrl: './add-group-garantie.component.html',
  styleUrls: ['./add-group-garantie.component.css']
})
export class AddGroupGarantieComponent implements OnInit {
  ModeAjout: boolean;
  fa = fa;
  grantieForm : FormGroup;
  group_id :any;
  group_garantie : GoupGarantie;
  listeOfUnsubscribeWebService: Subscription[] = [];

  icon: any ='fa';

  constructor( private routes: Router,
    private sharedMenuObserverService: SharedMenuObserverService,
    private route: ActivatedRoute,
    private  apiGroupGarantieService : ApiGroupGarantieService ,) { }

  ngOnInit(): void {
    this.sharedMenuObserverService.updateMenu('Gestion groupe des garanties ➜ Ajouter');
    this.grantieForm = new FormGroup({
      description: new FormControl(),
      libelle: new FormControl('',[Validators.required]),
      id: new FormControl(),
      icon: new FormControl(),

 });
 this.showloader()
 this.route.paramMap.subscribe((params) => {
  if (params.get('id') !== 'nouveau') {
    this.sharedMenuObserverService.updateMenu('Gestion groupe des garanties ➜ Détails');
    this.ModeAjout = false;
    const unsubscribeGetCamapagneById = this.apiGroupGarantieService.getGroupGarantieById(params.get('id')).subscribe((data: any) => {
      this.group_garantie = data;
      this.group_id = params.get('id');
         this.grantieForm.get('libelle').setValue(this.group_garantie[0].libelle);
         this.grantieForm.get('description').setValue(this.group_garantie[0].description);
         this.grantieForm.get('id').setValue(this.group_garantie[0].id);
         this.grantieForm.get('icon').setValue(this.group_garantie[0].icon)
         this.icon =this.group_garantie[0].icon;

         this.hideloader()
    }); this.listeOfUnsubscribeWebService.push(unsubscribeGetCamapagneById);
   
  }else{
    this.ModeAjout = true;
    this.hideloader()
  }

});

  }


  retourToList(){
      this.routes.navigate(['/groupe-garantie']);
  
  }
  clearForm() {
    if(this.ModeAjout){
      this.grantieForm.reset();
      this.icon ="fa"
    }else{
      this.grantieForm.get('libelle').setValue(this.group_garantie[0].libelle);
      this.grantieForm.get('description').setValue(this.group_garantie[0].description);
      this.grantieForm.get('id').setValue(this.group_garantie[0].id);
      if(this.group_garantie[0].icon !== '' && this.group_garantie[0].icon!== null){
      this.grantieForm.get('icon').setValue(this.group_garantie[0].icon)
      this.icon =this.group_garantie[0].icon;
      }else{
        this.icon = 'fa';
      }
    }
 
    // this.grantieForm.get('description').setValue('')
    // this.grantieForm.get('libelle').setValue('');
    // this.grantieForm.get('icon').setValue('')


  }

  checkedIcon =true;
  onCheckedNameIcon(event){
    this.checkedIcon =event;
  }
  add(){
    this.grantieForm.get('libelle').markAsTouched();
    if (this.grantieForm.valid && this.checkedIcon){
      if (this.grantieForm.value.description ===null){
        this.grantieForm.value.description=""
      }
      if (!this.grantieForm.value.icon){
        this.grantieForm.value.icon="fa"
      }
    if (this.ModeAjout) {
     

      const unsubscribeInsertGarantie = this.apiGroupGarantieService.addGroupGarantie(this.grantieForm.value).subscribe(
        (Response) => {
       
           
           this.alertSuccess(Response.message, Response.id, true);
          
        },
        (err) => {
          this.alertError("Erreur", "Erreur lors de l'ajout de la garantie");
         
        }
      ); this.listeOfUnsubscribeWebService.push(unsubscribeInsertGarantie);
    } else {
      const unsubscribeUpdateGarantie = this.apiGroupGarantieService.UpdateGroupGarantie(this.grantieForm.value).subscribe(
        (Response) => {
  
            
            this.alertSuccess(Response.message, Response.id, false);
           
        },
        (err) => {
          this.alertError('Erreur', "Erreur lors de la modification du groupe des garanties");
          
        }
      ); this.listeOfUnsubscribeWebService.push(unsubscribeUpdateGarantie);
    } 
   }else if(this.grantieForm.invalid){
      this.alertError('Formulaire non valide', 'Merci de remplire les champs obligatoires');
    }else if(this.grantieForm.valid&&!this.checkedIcon){
      this.alertError('Icône invalide !', 'Il faut choisir une icône valide.');

    }
  }




  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }

  alertSuccess(data, idGarantie, modeedit) {
    swal
      .fire({
        title: 'Opération Réussie!',
        text: data,
        icon: 'success',
        showDenyButton: true,
        showConfirmButton: true,
        showCloseButton: true,
        confirmButtonColor: '#68a45b',
        focusConfirm: true,
        showCancelButton: modeedit,
        denyButtonText: 'Nouveau Groupe Garanties',
        denyButtonColor: '833626',
        cancelButtonText: 'Modifier Groupe Garanties',
        confirmButtonText: 'Liste des Groupes Garanties',
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.hideloader();
          this.routes.navigate(['/groupe-garantie']);
        }
        if (result.isDismissed) {
          this.hideloader();
          if (this.ModeAjout) this.routes.navigate(['/add-groupe-garantie/' + idGarantie]);
        }
        if (result.isDenied) {
          this.hideloader();
          this.routes.navigate(['/add-groupe-garantie/nouveau']).then(() => {
            window.location.reload();
          });
        }
      });
  }


  alertError(data, err?) {
    swal.fire({
      title: data,
      //text: "Quelque chose s'est mal passé!",
      text: err,
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }


  onSelectIcon(event: any) {
    this.grantieForm.get('icon').setValue(event);
    this.icon =event;

  }

  onSelectNameIcon(event: any) {
    this.grantieForm.get('icon').setValue(event);
    this.icon =event;

  }


}
