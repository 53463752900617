import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ApiOrganismeService } from 'src/app/services/ApiOrganisme/api-organisme.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-status-convention',
  templateUrl: './status-convention.component.html',
  styleUrls: ['./status-convention.component.css']
})
export class StatusConventionComponent implements OnInit {
  statusListe: [] = [];
  showLaoder = true;
  constructor(private dialogRef: MatDialogRef<StatusConventionComponent>,
    private apiOrgaismeServices: ApiOrganismeService,
    @Inject(MAT_DIALOG_DATA) public data: any,) { 
      
    }

  ngOnInit(): void {
    this.apiOrgaismeServices.getListStatusConvention(this.data.idConv).subscribe((data) => {
      this.statusListe = data.status
      this.showLaoder = false;
    });
  }
  onClose() {
    this.dialogRef.close();
  }

  onchangeStatus(value: string) {
    let data = {
      index: this.data.index,
      data: value
    }
    this.dialogRef.close(data);
  }


}
