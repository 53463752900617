import { ApiUsersService } from 'src/app/services/ApiUsers/api-users.service';
import { DatePipe } from '@angular/common';
import { Validators, FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { map, startWith } from 'rxjs/operators';
import { ActionUserAffaire } from 'src/app/entity/Affaires/FilterAffaires';
@Component({
  selector: 'app-filtre-action-user',
  templateUrl: './filtre-action-user.component.html',
  styleUrls: ['./filtre-action-user.component.css'],
})
export class FiltreActionUserComponent implements OnInit, OnChanges {
  userSelected: any[] = [];
  fa = fa;
  date_creation_action: string = null;
  date_fin_action: string = null;

  // Mat Chips user
  visible = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  userFormCtrl: FormControl = new FormControl('', [Validators.required]);

  filteredUser: Observable<any[]>;
  ListUser: string[] = [];
  allUser: any[] = [];

  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoUser') matAutocompleteUser: MatAutocomplete;

  filterActionUtilisateur: ActionUserAffaire = new ActionUserAffaire();
  @Input() Reset: any;
  @Output() returnToparent = new EventEmitter<boolean>();
  @Output() onPushFilterActionUtilisateur: EventEmitter<ActionUserAffaire> = new EventEmitter();
  constructor(private apiUsersService: ApiUsersService, private chRef: ChangeDetectorRef, private datePipe: DatePipe) {}

  // User ---------------------------------------------------------------------------------------------

  selectedUser(event: MatAutocompleteSelectedEvent): void {
    this.ListUser.push(event.option.viewValue);
    this.userSelected.push(event.option.value);
    this.userInput.nativeElement.value = '';
    this.userFormCtrl.setValue(null);
  }

  removeUser(util: string): void {
    const index = this.ListUser.indexOf(util);
    if (index >= 0) {
      this.ListUser.splice(index, 1);
      this.userSelected.splice(index, 1);
    }
  }

  private _filter(): any[] {
    let filterValue = this.userFormCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.allUser.filter((utils) =>
      utils.nom != null ? utils.nom.toLowerCase().indexOf(filterValue.toLowerCase()) === 0 : null
    );
  }

  ngOnInit(): void {
    this.getUser();
    this.filteredUser = this.userFormCtrl.valueChanges.pipe(
      startWith(null),
      map((utils: string | null) => (utils ? this._filter() : this.allUser.slice()))
    );
  }

  getUser() {
    this.apiUsersService.getAllUsers().subscribe((data: any[]) => {
      this.allUser = data;
    });
  }

  checking(event) {
    if (event.target.checked === true) {
      this.filterActionUtilisateur.dernier_action = 'on';
    } else {
      this.filterActionUtilisateur.dernier_action = 'off';
    }
  }

  action(event) {
    if (event.target.checked === true) {
      this.filterActionUtilisateur.enreg_avec_action = 'on';
    } else {
      this.filterActionUtilisateur.enreg_avec_action = 'off';
    }
  }

  simple(event) {
    if (event.target.checked === true) {
      this.filterActionUtilisateur.enreg_simple = 'on';
    } else {
      this.filterActionUtilisateur.enreg_simple = 'off';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.resetActionUtilisateurForm(changes.Reset.currentValue);
  }

  resetActionUtilisateurForm(Reset) {
    this.chRef.detectChanges();
    if (Reset === true) {
      this.ListUser = [];
      this.filterActionUtilisateur.list_user = this.userSelected = [];
      this.filterActionUtilisateur.dernier_action = '';
      this.filterActionUtilisateur.date_creation_action = null;
      this.filterActionUtilisateur.date_fin_action = null;
      this.filterActionUtilisateur.enreg_simple = '';
      this.filterActionUtilisateur.enreg_avec_action = '';
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterActionUtilisateur.emit(this.filterActionUtilisateur);
    }
  }

  EmitFilterActionUtilisateur() {
    this.filterActionUtilisateur.list_user = this.userSelected;
    this.filterActionUtilisateur.date_creation_action = this.datePipe.transform(
      this.date_creation_action,
      'yyyy-MM-dd'
    );
    this.filterActionUtilisateur.date_fin_action = this.datePipe.transform(this.date_fin_action, 'yyyy-MM-dd');
    this.onPushFilterActionUtilisateur.emit(this.filterActionUtilisateur);
  }
}
