<div class=" m-0">
   
    <div class=" add_panel m-1"  >
        <div class="row " >
          
          <button type="button" class="btn-dt-send w-100 pt-1 mb-2">
            <fa-icon (click)="inexpand()" class="fa fa-lg float-left ml-2" [icon]="fa['faTimesCircle']"></fa-icon>&nbsp;
            {{typeTache ==='' ? 'Ajouter une tâche' : 'Modifier la tâche'}}
          </button>
        </div>
        
        <!-- <div class="row justify-content-end " *ngIf="typeTache">
            
                <button class="btn disable mt-3 m-2 " (click)="changerStatus()">
                  <fa-icon *ngIf="!isDisabled" style="color: #2a96ff; " [icon]="fa['faPowerOff']">
                  </fa-icon>  
                  <fa-icon *ngIf="isDisabled" style="color: #2a96ff;" [icon]="fa['faHourglassEnd']">
                  </fa-icon>  
                    {{ isDisabled ? 'Activer la tache' : 'Terminer la tache' }}
                </button>
            
        </div> -->
        <div class="row">
            <div class="col-md-12">
                <mat-form-field class="input-width area w-100 " >
                    <mat-label>Titre</mat-label>
                    <input matInput  [(ngModel)]="taskDetail.titre" name="titre" titre="ngModel" required>
                        <mat-error><span> Le titre est obligatoire!
                        </span></mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-12" *ngIf="typeTache!=='affecte'" >
                <h4 class="description">Affecter à</h4>
                <div class="row " >
                    <div class="col" *ngFor="let type of types">
                        <input  name="type" type="radio" [id]="type.viewValue" [(ngModel)]="typeselectedAffect" [value]="type.viewValue"  (change)="getList(type.viewValue)">
                        <label   class="ml-3 greyColor" for="{{type.viewValue}}">{{type.viewValue}}</label>
                    </div>
                </div>    
                <mat-spinner *ngIf="loader" [diameter]="20"></mat-spinner>
                <div class="row" *ngIf="typeTache!=='affecte'">
                    <mat-form-field class=" w-100" >
                        <mat-label>{{typeselectedAffect}}</mat-label>
                        <input type="text" matInput required  [(formControl)]="myControl" [matAutocomplete]="auto">
                        <mat-error><span> Il faut spécifier le prospect ou bien le client .
                        </span></mat-error>
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{option.full_name}}
                          </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                
            </div>
            
            <div class="col-md-12">
                <mat-form-field class="input-width area w-100 " >
                    <mat-label>Lien</mat-label>
                    <input matInput [disabled]="taskDetail?.id_entity !==null && taskDetail?.id_entity!=='' " [(ngModel)]="taskDetail.url" name="lien" #lien="ngModel"
                     pattern="[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))(?::\d{2,5})?(?:\/[^\s]*)?" >
                    <mat-error *ngIf="lien.invalid && (lien.dirty || lien.touched)"
                    class="message-erreur">
                    <mat-error *ngIf="lien.errors.pattern">
                        <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                        Format du l'url invalide!
                    </mat-error>
                </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-12 ">
                <mat-form-field class=" w-100" >
                    <mat-label>Mots clés</mat-label>
                    <mat-select placeholder="tags"  [(ngModel)]="tags" multiple (selectionChange)="onTagSelectionChange()">
                        <!-- <div class="input-icons mb-2 w-100">
                    
                            <fa-icon  [icon]="fa['faSearch']" class="p-2 icon position-absolute">
                            </fa-icon> 
                            <input class=" w-100 p-2 text-center input-field" (keyup)="searchTags($event.target.value)">
                        </div> -->
                        <mat-option *ngFor="let tags of tagsList" [value]="tags.id">{{tags.libelle}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div> 
         <div class="col-md-12">
                <span *ngFor="let tagsAll of taskDetail.tags">
                    <span class="badge badge-primary ml-2" [ngStyle]="{'color': tagsAll.couleur,'background-color': tagsAll.couleur_background}"  >{{tagsAll.libelle}}</span>
                
            </span>
            </div>
       
            
               
                <div class="col-md-12 ">
                    <mat-form-field  class=" w-100">
                        <mat-label>Priorité</mat-label>
                        <mat-select  [(ngModel)]="taskDetail.priorite" >
                          <mat-option  *ngFor="let priorite of priorites" [value]="priorite.id">
                            {{priorite.libelle}}
                             <fa-icon  [icon]="fa[priorite.icon]">
                            </fa-icon> 
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-12">
                    <mat-form-field  class="  w-100">
                        <mat-label>Date d'échéance</mat-label>
                        <input  matInput [matDatepicker]="picker"  [(ngModel)]="dateEcheance" readonly (focus)="picker.open()">
                        <mat-hint>DD/MM/YYYY</mat-hint>
                        <mat-icon matDatepickerToggleIcon (click)="clearDate($event)">clear</mat-icon>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error><span> Veuillez vérifier le date
                        </span></mat-error>
                    </mat-form-field>
                    
                </div>
            

            <div class="col-md-12">
                <mat-form-field  class=" w-100 area">
                    <mat-label>Description</mat-label>
                    <textarea matInput [(ngModel)]="taskDetail.description" >
                </textarea>
                </mat-form-field>
            </div>
            <div *ngIf="loaderAjout" class="col-12 d-flex justify-content-center text-secondary ">
                <div class="spinner">
                    <mat-spinner [diameter]="20"></mat-spinner>
                </div>
            </div>
            <div class="col-md-12 d-flex justify-content-center">
                <button  type="button" class=" m-2 btn-dt-save-popup " (click)="enregistrer()">
                    <fa-icon class="fa-lg " [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
                      Enregistrer
                  </button>
                  
                  <button type="button" (click)="inexpand()" class="m-2 btn-dt-cancel-popup">
                    <fa-icon class="fa-icon-style fa-lg " [icon]="fa['faTimesCircle']"></fa-icon>&nbsp;
                    Annuler
                  </button>
            </div>
        </div>
    </div>
</div>
