<div class="row card" *ngIf="prospect_id !== null">

    <label class="card-doc-title row">

      <div class="col-8">
    <fa-icon [icon]="fa['faStream']"> </fa-icon>
      <ng-container *ngIf="prospect_id !== null && affaire_id === null">
        Informations prospect
      </ng-container>
      <ng-container *ngIf="prospect_id !== null && affaire_id !== null">
        Informations client
      </ng-container>
    </div>
    <div class="col-4 text-right">
      <button type="button" class="btn-dt-link" (click)="toFiche()" >
  <fa-icon class="fa-icon-style" [icon]="fa['faLink']" > </fa-icon>&nbsp;
  Fiche d’Adhérent/Souscripteur
  </button>
  </div>
  </label>


    <ng-container  *ngIf="prospect.id">
       
        <app-prospect-form style="pointer-events: none; opacity: 0.9;"  [allowed_to_update]="false" [ticket]="'ticket'" [prospect]="prospect" [type]="3" [schemaValue]="prospect.form" [isReadOnly]="true" (onUpdateProspect)="updateProspect($event,-1)">
        </app-prospect-form>
    </ng-container>

</div>
