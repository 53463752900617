<div class="flex-column bg-white rounded-card">
    <div class="p-3 mb-3">
        <table id="opp" class="table">
            <thead class="head-table">
                <tr>
                    <th> # </th>
                    <th> Campagne </th>
                    <th> Statut opportunité </th>
                    <th> Statut affaire </th>
                    <th> Affaire</th>
                    <th> Opportunité</th>
                    <th> Ligne de produits</th>
                </tr>
            </thead>
            <ng-container *ngIf="show_loading_add">
                <div class="col-12">
                    <div class="spinner-style">
                        <mat-spinner [diameter]="30"></mat-spinner>
                    </div>
                </div>
            </ng-container>
            <tbody class="body-table">
                <tr *ngFor="let item of listOpportunite; let index = index">
                    <td>{{index+1}}</td>
                    <td> {{item.campagne}}</td>
                    <td>
                        <div class="btn-status d-flex justify-content-center" *ngIf="item.statut_opp"
                            [style.background]="item.color_affaire">{{item.statut_opp}}</div>
                        <div *ngIf="!item.statut_opp">-</div>
                    </td>
                    <td>
                        <div class="btn-status d-flex justify-content-center" *ngIf="item.statut_affaire"
                            [style.background]="item.color_opp">{{item.statut_affaire}}</div>
                        <div *ngIf="!item.statut_affaire">-</div>
                    </td>
                    <td>
                        <a *ngIf="item.id_affaire" target="_blank" href="/affaires/details/{{item.id_affaire}}">
                            <fa-icon class="fa-icon-style" [icon]="fa['faLink']"></fa-icon>&nbsp;
                            Affaire
                        </a>
                        <div *ngIf="!item.id_affaire">-</div>
                    </td>
                    <td>
                        <a *ngIf="item.id_opp" target="_blank" href="/opportunities/details/{{item.id_opp}}">
                            <fa-icon class="fa-icon-style" [icon]="fa['faLink']"></fa-icon>&nbsp;
                            Opportunité
                        </a>
                        <div *ngIf="!item.id_opp">-</div>
                    </td>
                    <td> {{item.produit ? item.produit : '-' }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>