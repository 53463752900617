<div   class=" row mt-2 " >
    <div class="col-6">
        <div class="form-group">
            <mat-form-field class="w-100" >
                <input (keyup.enter)="EmitFilterCourrier()" (click)="EmitFilterCourrier()"
                    [(ngModel)]="filterCourrier.ref_notif" matInput placeholder="Réference maileva" type="text">
            </mat-form-field>

        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field class="w-100">
                <input (keyup.enter)="EmitFilterCourrier()" (click)="EmitFilterCourrier()"
                    [(ngModel)]="filterCourrier.ref_client" matInput placeholder="Réference client  " type="text">
            </mat-form-field>

        </div>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field class="w-100">
                <input [(ngModel)]="filterCourrier.ref_preuve" (keyup.enter)="EmitFilterCourrier()" matInput
                    placeholder="Réference preuve éléctronique" type="text">
            </mat-form-field>

        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field class="w-100">
                <input [(ngModel)]="filterCourrier.sujet" (change)="EmitFilterCourrier()" matInput
                    placeholder="Sujet" type="text">
            </mat-form-field>

        </div>
    </div>


</div>
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;margin-top: -10px;">
                <mat-select (selectionChange)="EmitFilterCourrier()" [(ngModel)]="filterCourrier.sent_from"
                    placeholder="Envoyé à travers" multiple>
                    <mat-option value="interface">
                        Interface
                    </mat-option>
                    <mat-option value="autres">
                        Autres
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

    </div>

    <div class="col-6">

        <div class="form-group">
            <mat-form-field appearance="fill"  class="selectType ">
                <mat-select [(ngModel)]="filterCourrier.type_courrier" (selectionChange)="EmitFilterCourrier()"
                    placeholder="Type de courrier" multiple>
                    <mat-option value="E">
                        Entrant
                    </mat-option>
                    <mat-option value="S">
                        Sortant
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>


<div class="row" >
    <div class="col-6" >
        <div >
            <label class="">Date de Création</label>
        </div>
    </div>

</div>
<div class="row align-items-center">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" class="w-100">
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate [(ngModel)]="date_debut_creation" readonly (click)="openDateCreation()" placeholder="Du" />
                    <input matEndDate [(ngModel)]="date_fin_creation"  readonly (click)="openDateCreation()" placeholder="à" />

                </mat-date-range-input>

                <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="picker">    <mat-icon matDatepickerToggleIcon>
                    <img src="assets/icons/calendar.svg" > 
                  </mat-icon></mat-datepicker-toggle>
                  <img  class="remouveDate" matSuffix  (click)="clearDateCreation()" src="assets/icons/delete-left.svg">
                  <mat-date-range-picker (closed)="EmitFilterCourrier()" [calendarHeaderComponent]="rangeDateCreation" #picker></mat-date-range-picker>


       
            </mat-form-field>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group d-flex ">
          <input [(ngModel)]="filterCourrier.heure_debut_creation" class="style-timePicker"  aria-label="24hr format" [ngxTimepicker]="Debutcreation" [format]="24"  placeholder="Heure début">
          <span> - </span>
          <input [(ngModel)]="filterCourrier.heure_fin_creation" class="style-timePicker" aria-label="24hr format"  placeholder="Heure fin "[ngxTimepicker]="fincreation" [format]="24" >
          <ngx-material-timepicker (closed)="EmitFilterCourrier()" #Debutcreation></ngx-material-timepicker>
          <ngx-material-timepicker (closed)="EmitFilterCourrier()" #fincreation></ngx-material-timepicker>
        </div>
      </div>
    </div>

<div class="row">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%;margin-top: 7px;">
                <mat-date-range-input [rangePicker]="Planifie">
                    <input [(ngModel)]="date_debut_envoie" matStartDate placeholder="Date d'envoi planifié du " (click)="openDatePlanifie()" />
                    <input [(ngModel)]="date_fin_envoie" matEndDate placeholder="..à" (click)="openDatePlanifie()" />

                </mat-date-range-input>

                <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="Planifie">    <mat-icon matDatepickerToggleIcon>
                    <img src="assets/icons/calendar.svg" > 
                  </mat-icon></mat-datepicker-toggle>
                  <img  class="remouveDate" matSuffix  (click)="clearDatePlanifie()" src="assets/icons/delete-left.svg">
                  <mat-date-range-picker (closed)="EmitFilterCourrier()" [calendarHeaderComponent]="rangeDateEnvoie" #Planifie></mat-date-range-picker>




            </mat-form-field>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" style="width: 100%">
                <mat-select [(ngModel)]="filterCourrier.enveloppe_courrier" (selectionChange)="EmitFilterCourrier()"
                    (selectionChange)="getSelectedTypeEnvoi($event)" placeholder="Type d'enveloppe" multiple>
                    <mat-option value="C4">
                        C4
                    </mat-option>
                    <mat-option value="C6">
                        C6
                    </mat-option>

                </mat-select>
            </mat-form-field>

        </div>
    </div>
    <!-- <div class="col-6">
        <div class="form-group">

            <mat-form-field appearance="fill" style="width: 100%">
                <mat-select (selectionChange)="SelectListModels()" [(ngModel)]="modelSelectionnee" placeholder="Groupe">
                    <mat-option *ngFor="let item of listModels" value="{{item}}">
                        {{item}}
                    </mat-option>

                </mat-select>
            </mat-form-field>

        </div>
    </div> -->

</div>
<!-- <div class="row" *ngIf="listEmpty">
    <div class="col-12">
        <mat-form-field class="w-100" appearance="standard">
            <mat-label>Modéle</mat-label>
            <mat-select placeholder="Modéle" (selectionChange)="EmitFilterCourrier()"
                (selectionChange)="getSelectedModels($event)" multiple>
                <mat-option [value]="list.id" *ngFor="let list of listEmpty">{{list.nom}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div> -->
<!-- <div class="row"> -->
  

    <!-- <div class="col-6">
        <div class="form-group">
            <ng-container *ngIf="type_envoie.length > 0">
                <mat-form-field style="width: 100%;margin-top: 11px;">
                    <mat-label>Type d'envoi</mat-label>
                    <mat-chip-list #typeEnvoi>
                        <mat-chip *ngFor="let typeEv of ListTypeEnvoie" [selectable]="selectable"
                            [removable]="removable" (removed)="removeTypeEnvoie(typeEv)"
                            (removed)="EmitFilterCourrier()">
                            {{typeEv}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)="EmitFilterCourrier()" placeholder="Selectionner Type d'envoi..."
                            #TypeEnvoieInput [formControl]="typeEnvoieFromCtrl" [matAutocomplete]="autoTypeEnvoie"
                            [matChipInputFor]="typeEnvoi" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoTypeEnvoie="matAutocomplete" (optionSelected)="selectedTypeEnvoie($event)">
                        <mat-option *ngFor="let typeEv of filteredTypeEnvoie | async" [value]="typeEv.libelle">
                            {{typeEv.libelle}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div> -->
<!-- </div> -->
<div class="row">

</div>


<div class="row" >
    <div class="col-6" >
        <div >
            <label class="">Date d'affranchissement </label>
        </div>
    </div>

</div>
<div class="row align-items-center">
    <div class="col-6">
        <div class="form-group">
            <mat-form-field appearance="fill" class="w-100">
                <mat-date-range-input [rangePicker]="affranchissement">
                    <input matStartDate [(ngModel)]="date_debut_livraison" readonly (click)="openDateAffranchissement()" placeholder="Du" />
                    <input matEndDate [(ngModel)]="date_fin_livraison" (click)="openDateAffranchissement()"  readonly placeholder="à" />

                </mat-date-range-input>

                <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="affranchissement">    <mat-icon matDatepickerToggleIcon>
                         <img src="assets/icons/calendar.svg" > 
                  </mat-icon></mat-datepicker-toggle>
                  <img  class="remouveDate" matSuffix  (click)="clearDateAffranchissement()" src="assets/icons/delete-left.svg">
                  <mat-date-range-picker (closed)="EmitFilterCourrier()" [calendarHeaderComponent]="rangeDateLivraison" #affranchissement></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group d-flex ">
          <input [(ngModel)]="filterCourrier.heure_debut_livraison" class="style-timePicker"  aria-label="24hr format" [ngxTimepicker]="Debutlivraison" [format]="24"  placeholder="Heure début">
          <span> - </span>
          <input [(ngModel)]="filterCourrier.heure_fin_livraison" class="style-timePicker" aria-label="24hr format"  placeholder="Heure fin "[ngxTimepicker]="finlivraison" [format]="24" >
          <ngx-material-timepicker (closed)="EmitFilterCourrier()" #Debutlivraison></ngx-material-timepicker>
          <ngx-material-timepicker (closed)="EmitFilterCourrier()" #finlivraison></ngx-material-timepicker>
        </div>
      </div>
    </div>


    <div class="row">
        <div class="col-6" >
            <div >
                <label class="">Date de preuve électronique </label>
            </div>
        </div>
    
    </div>
    <div class="row align-items-center">
        <div class="col-6">
            <div class="form-group">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-date-range-input [rangePicker]="prevue">
                        <input matStartDate [(ngModel)]="date_debut_prevue" readonly (click)="openDateprevue()" placeholder="Du" />
                        <input matEndDate [(ngModel)]="date_fin_prevue"  readonly (click)="openDateprevue()" placeholder="à" />
    
                    </mat-date-range-input>

                    <mat-datepicker-toggle class="m-0 p-0" matPrefix [for]="prevue">    <mat-icon matDatepickerToggleIcon>
                        <img src="assets/icons/calendar.svg" > 
                      </mat-icon></mat-datepicker-toggle>
                      <img  class="remouveDate" matSuffix  (click)="clearDateprevue()" src="assets/icons/delete-left.svg">
                      <mat-date-range-picker (closed)="EmitFilterCourrier()" [calendarHeaderComponent]="rangeDatePrevue" #prevue></mat-date-range-picker>
                </mat-form-field>
            </div>
        </div>
        <div class="col-6">
            <div class="form-group d-flex ">
              <input [(ngModel)]="filterCourrier.heure_debut_prevue" class="style-timePicker"  aria-label="24hr format" [ngxTimepicker]="DebutReception" [format]="24"  placeholder="Heure début">
              <span> - </span>
              <input [(ngModel)]="filterCourrier.heure_fin_prevue" class="style-timePicker" aria-label="24hr format"  placeholder="Heure fin "[ngxTimepicker]="finprevue" [format]="24" >
              <ngx-material-timepicker (closed)="EmitFilterCourrier()" #DebutReception></ngx-material-timepicker>
              <ngx-material-timepicker (closed)="EmitFilterCourrier()" #finprevue></ngx-material-timepicker>
            </div>
          </div>
        </div>
