<div class="card-4" >
    <div class="container">
        <div class="row details-nav">
            <div class="col-4 pr-0">
                <button class="button-style left-btn" routerLink="contrat-actuel">
                Contrat Actuel :
            </button>
            </div>
            <div class="col-4 px-0">
                <button class="button-style middle-btn" routerLink="information-complementaires">
                Informations complémentaires campagne:
            </button>
            </div>
            <div class="col-4 pl-0">
                <button class="button-style right-btn" routerLink="information-specifiques">
                Informations complémentaires ligne des produits:
            </button>
            </div>
        </div>
        <div class="row details-body">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
