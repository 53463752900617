<div class="row">
    <div class="col-12">

            <!-- <div class="col" [ngClass]="{'selectedEtat': detailsClone.etatActuel==='N/R'}">
                <input (change)="pushInfo()" [(ngModel)]="detailsClone.etatActuel" style="display: none" id="etatactuel1" type="radio" class="form-check-input" value="N/R" name="EtatActuel">
                <label class="d-block text-white text-center m-0" [ngClass]="{'selectedEtat': detailsClone.etatActuel==='N/R'}" for="etatactuel1">N/R</label>
            </div>
            <div class="col" [ngClass]="{'selectedEtat': detailsClone.etatActuel==='Non Assure'}"><input (change)="pushInfo()" [(ngModel)]="detailsClone.etatActuel" style="display: none" id="etatactuel2" type="radio" class="form-check-input" value="Non Assure" name="EtatActuel">
                <label class="d-block text-white text-center m-0" [ngClass]="{'selectedEtat': detailsClone.etatActuel==='Non Assure'}" for="etatactuel2">Non Assure</label>
            </div>
            <div class="col" [ngClass]="{'selectedEtat': detailsClone.etatActuel==='Assure'}"><input (change)="pushInfo()" [(ngModel)]="detailsClone.etatActuel" style="display: none" id="etatactuel3" type="radio" class="form-check-input" value="Assure" name="EtatActuel">
                <label class="d-block text-white text-center m-0" [ngClass]="{'selectedEtat': detailsClone.etatActuel==='Assure'}" for="etatactuel3">Assure</label>
            </div> -->
            <section class="TypeContrat row">
                <label class="col-5 m-0"><p class="text-center m-auto">
                     Est-ce que vous bénéficiez actuellement d'un contrat d'assurance ?
                    </p>
                    </label>
                <mat-radio-group class="col-7 d-flex align-items-center m-0" [(ngModel)]="detailsClone.etatActuel" (change)="pushInfo()">
                  <mat-radio-button class="col-3" value="N/R">N/R</mat-radio-button>
                  <mat-radio-button class="col-3" value="Non Assure">Non Assure</mat-radio-button>
                  <mat-radio-button class="col-3" value="Assure">Assure</mat-radio-button>
                </mat-radio-group>
              </section>
    </div>  
</div> 
<div class="row d-flex align-items-center  justify-content-center">
        <div  class="col-8" *ngIf="detailsClone.etatActuel=='Assure'" >
            <form [formGroup]="formContrat" class="example-form">
                <div class="row assureBox">
                    <div class="col-6">
                        <mat-form-field class="full-width">
                            <mat-label>Cie</mat-label>
                            <input formControlName="cie_text" type="text" required matInput [matAutocomplete]="auto">
                            <input formControlName="cie_text_id" type="text" hidden>
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                <mat-option (onSelectionChange)="onSelectCie(item)" *ngFor="let item of cieAutoComplete$ | async; let index = index" [value]="item.text">
                                    {{ item.text | titlecase}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <div *ngIf="formContrat.controls['cie_text'].invalid && (formContrat.controls['cie_text'].dirty || formContrat.controls['cie_text'].touched)" class="text-danger">
                            <div *ngIf="formContrat.controls['cie_text'].errors?.required">
                                Cie obligatoire !
                            </div>
                        </div>
                    </div>
                    <div class="col-6">

                        <mat-form-field class="full-width" appearance="standard">
                            <mat-label>Nom du produit</mat-label>
                            <input (change)="pushInfo()" matInput type="text" formControlName="nom_produit">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="full-width" appearance="standard">
                            <mat-label>PAN TTC</mat-label>
                            <input (change)="pushInfo()" matInput type="number" formControlName="pan_ttc">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="full-width" appearance="standard">
                            <mat-label>PM TTC</mat-label>
                            <input (change)="pushInfo()" matInput type="number" formControlName="pm_ttc">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="full-width" appearance="standard">
                            <mat-label>Nombre d'assurés</mat-label>
                            <input (keypress)="numberOnly($event)" min="0" (change)="pushInfo()" max="11" type="number" matInput placeholder="0" formControlName="nombre_assure">

                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field class="full-width" appearance="standard">
                            <mat-label>Date d'échéance principale</mat-label>
                            <input (change)="pushInfo()" matInput [matDatepicker]="picker" formControlName="date_echeance_principale">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>
    
</div>
<hr>
<ng-container *ngIf="info_complementaires">
    <form (ngSubmit)="onSubmitInfoComp()">
        <mat-accordion class="example-headers-align">
            <mat-expansion-panel class="expension-secondary-color" [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h2 class="font-primary-geoprod">
                            <fa-icon class="fa-lg" [icon]="fa['faInfoCircle']"></fa-icon>&nbsp;&nbsp; Informations complémentaires campagne</h2>
                    </mat-panel-title>
                    <mat-panel-description>
                        <!--Description-->
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-12 form-group-container">
                        <formly-form [model]="model_info_complementaires" [fields]="info_complementaires" [options]="options" [form]="form_info_complementaires"></formly-form>
                    </div>
                </div>

            </mat-expansion-panel>
        </mat-accordion>
    </form>
    <hr>
</ng-container>
<ng-container *ngIf="info_specifiques">

    <form (ngSubmit)="onSubmitInfoSpec()">
        <mat-accordion class="example-headers-align">
            <mat-expansion-panel class="expension-secondary-color" [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h2 class="font-primary-geoprod">
                            <fa-icon class="fa-lg" [icon]="fa['faInfoCircle']"></fa-icon>&nbsp;&nbsp; Informations complémentaires ligne des produits</h2>
                    </mat-panel-title>
                    <mat-panel-description>
                        <!--Description-->
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="row">
                    <div class="col-12 form-group-container">
                        <formly-form [fields]="info_specifiques" [model]="model_info_specifiques" [options]="options" [form]="form_info_specifiques"></formly-form>
                    </div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

    </form>
</ng-container>
<hr>
<ng-container *ngIf="campagne.objectAssurer.length>0 && bien">

    <div >

      <form >
        <mat-accordion class="example-headers-align">
            <mat-expansion-panel class="expension-secondary-color" [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h2 class="font-primary-geoprod">
                            <fa-icon class="fa-lg" [icon]="fa['faObjectUngroup']"></fa-icon>&nbsp;&nbsp; Objet(s) / Bien(s) à assurer</h2>
                    </mat-panel-title>
                    <mat-panel-description>

                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div class="row" *ngFor="let objetAssure of campagne.objectAssurer;index as i; first as isFirst ">

                    <app-form-objet-assurer class="w-100" [index]="i" [formObjectAssurer]="objetAssure" (dataform)="getformdata($event)" (dataformcopie)="copieformdata($event)" (dataformdelete)="deleteformdata($event)"></app-form-objet-assurer>

                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </form>
</div>
</ng-container>
<hr>
<!-- <div class="row" style="margin-top: 30px;">
    <div class="col-12">
        <mat-form-field class="full-width" appearance="standard">
            <mat-label>Commentaire </mat-label>
            <textarea (change)="pushInfo()" [(ngModel)]="detailsClone.commentaire" rows="8" matInput placeholder="Exemple ..."></textarea>
        </mat-form-field>
    </div>
</div> -->
