<div class="">
    <div class="d-flex row card-body">
        <div class="col-6 p-2">
       
    <button type="button" class="btn-dt-retourr mt-1" (click)="returnTo()">
        <div class="icon-position">
            <fa-icon class="fa-icon-style" [icon]="fa['faArrowLeft']"></fa-icon>&nbsp;
        </div>
    </button>
        </div>
        <!-- <div class="p-2">
            <button (click)="onPred()" *ngIf="listOfProcess[0]?.class!=='active'" type="button" style="margin-top: 4px;" class="btn-dt-chevron-white">
          <img src="assets/icons/chevron-white-icon.svg">
        </button>

            <button (click)="onNext()" *ngIf="listOfProcess[listOfProcess.length-2]?.class!=='done'" style="margin-left:10px" type="button" class="btn-dt-chevron">
          <img src="assets/icons/chevron-black-icon.svg">
        </button>
        </div> -->
        <div class="col-6 align-items-center d-flex justify-content-end p-2">
            <button type="button" class="btn-load mt-0" type="button" (click)="resetAddOpportunite()">
        <fa-icon [icon]="fa['faRedo']"></fa-icon>
      </button>
            <button (click)="onSubmit()" *ngIf="listOfProcess[3]?.class=='active'" type="submit" class="btn-dt-save-popup">
        <fa-icon class="fa-icon-style mt-1" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
        Enregistrer
      </button>
        </div>
    </div>
    <div class="row card-nav">
        <div class="col-12">
            <app-status-bar (onClickStatus)="navigateToView($event)" [listOfProcesses]="listOfProcess"></app-status-bar>
        </div>
    </div>

   
 <div *ngIf="listOfProcess[0]?.class=='active'" class="row card-body">
        <ng-container *ngIf="cycleVieHorsComp$ && (cycleVieComp$ | async).length>0 && campagnie$">
            <app-campagne [listCycleVieComp]="cycleVieComp$ | async" [listCycleVieHorsComp]="cycleVieHorsComp$ | async" [listLignesProduits]="listLignesProduits$ | async" [campagne]="campagnie$ | async" (onUpdateInfo)="updateCampagne($event)"  (ongetObjetAssurer)="getObjetAssurer($event)" (onProductSelected)="setInfoSpecifiques($event)"
            (onCampagneSelected)="setInfoComplementaires($event)" (bien)="getbien($event)" (person)="getperson($event)" class="w-100"></app-campagne>
        </ng-container>
    </div>

    <div *ngIf="listOfProcess[1]?.class=='active'" class="row card-body">

        <app-add-prospect [modeupdateProspect]="'false'" [person]="isperson"></app-add-prospect>
    </div>

    <div *ngIf="listOfProcess[2]?.class=='active'" class="row card-body">
        <app-details [bien]="isbien" [campagne]="campagnie$ | async" [details]="details$ | async" (onUpdateComapagne)="compagneupdate($event)" (onUpdateInfo)="updateDetailsInfo($event)" [info_specifiques]="infoSpecifiquesShema$ | async" [info_complementaires]="infoComplementaireShema$ |async" class="w-100"></app-details>
    </div>

    <div *ngIf="listOfProcess[3]?.class=='active'" class="row card-body">
        <app-calendrier [listCommerciaux]="listCommerciaux$| async" [listStatusCampagne]="listStatusCampagne$ | async" [calendar]="calendar$ | async" (onUpdateInfo)="updateCalendar($event)" (onSelectStatus)="updateStatus($event)" class="w-100"></app-calendrier>
    </div>

    <div class="card-body d-flex justify-content-center mb-5" >
        <div>
            <button (click)="onPred()" [disabled]="listOfProcess[0]?.class=='active'" type="button" class="btn-suivant">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" >
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1377 0.268984C13.4963 0.627628 13.4963 1.20911 13.1377 1.56775L5.70542 9L13.1377 16.4322C13.4963 16.7909 13.4963 17.3724 13.1377 17.731C12.779 18.0897 12.1975 18.0897 11.8389 17.731L3.75726 9.64938C3.39862 9.29074 3.39862 8.70926 3.75726 8.35062L11.8389 0.268984C12.1975 -0.0896612 12.779 -0.0896612 13.1377 0.268984Z"  />
                  </svg>
                Précédent 
              
              
      </button>
            <button (click)="onNext()"   *ngIf="this.step !==3"  type="button" class="btn-suivant ml-4">
                Suivant 
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.86234 17.731C4.50369 17.3724 4.50369 16.7909 4.86234 16.4322L12.2946 9L4.86234 1.56775C4.50369 1.20911 4.50369 0.627628 4.86234 0.268982C5.22098 -0.0896626 5.80246 -0.0896626 6.1611 0.268982L14.2427 8.35062C14.6014 8.70926 14.6014 9.29074 14.2427 9.64938L6.1611 17.731C5.80246 18.0897 5.22098 18.0897 4.86234 17.731Z" />
                  </svg>
      </button>
        </div>
    </div>

</div>
