import { DatePipe } from '@angular/common';
import {
  ActionUserAffaire,
  Action_user,
  Affaire,
  ContratActuel,
  FilterAffaires,
  Filtre_opp,
  global,
  Prospect,
  
  Rdv,
  StatutActuel,
  TelephonieOpp,
  Utilisateur,
  UtilisateurOpp,
} from './../../../../../entity/Affaires/FilterAffaires';

import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  faSearch,
  faPlus,
  faCalendar,
  faChartBar,
  faChartPie,
  faBriefcase,
  faUserTie,
  faFileAlt,
  faPhoneSquareAlt,
  faUser,
  faUserEdit,
  faFile,
  faCheckCircle,
  faSyncAlt,
  faFilter,
} from '@fortawesome/free-solid-svg-icons';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { FilterActionUserOppComponent } from '../filter-action-user-opp/filter-action-user-opp.component';
import { BlocQualiteComponent } from '../bloc-qualite/bloc-qualite.component';
import { FilterOpportunityAffaireComponent } from '../filter-opportunity-affaire/filter-opportunity-affaire.component';
import { FilterStatutActuelOppComponent } from '../filter-statut-actuel-opp/filter-statut-actuel-opp.component';
import { FilterTelephonieOppComponent } from '../filter-telephonie-opp/filter-telephonie-opp.component';
import { FilterUserOppComponent } from '../filter-user-opp/filter-user-opp.component';
import { FiltreActionUserComponent } from '../filtre-action-user/filtre-action-user.component';
import { FiltreAffaireComponent } from '../filtre-affaire/filtre-affaire.component';
import { FiltreInfoContratActuelComponent } from '../filtre-info-contrat-actuel/filtre-info-contrat-actuel.component';
import { FiltreProspectComponent } from '../filtre-prospect/filtre-prospect.component';
import { FiltreQualiteComponent } from '../filtre-qualite/filtre-qualite.component';
import { FiltreStatutActuelComponent } from '../filtre-statut-actuel/filtre-statut-actuel.component';
import { FiltreUserComponent } from '../filtre-user/filtre-user.component';
import { FilterHistoryService } from 'src/app/services/ApiFilterSave/filter-history.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-filter-affaire-global',
  templateUrl: './filter-affaire-global.component.html',
  styleUrls: ['./filter-affaire-global.component.css'],
})
export class FilterAffaireGlobalComponent implements OnInit ,OnChanges {
  fa = fa;
  faSearch = faSearch;
  faPlus = faPlus;
  faCalendar = faCalendar;
  faChartBar = faChartBar;
  faChartPie = faChartPie;
  faBriefcase = faBriefcase;
  faUserTie = faUserTie;
  faFileAlt = faFileAlt;
  faFile = faFile;
  faPhoneSquareAlt = faPhoneSquareAlt;
  faUser = faUser;
  faUserEdit = faUserEdit;
  faCheckCircle = faCheckCircle;
  faSyncAlt = faSyncAlt;
  faFilter = faFilter;
  filterAffaires: global = new global();
  datesAffaire: global = new global();
  panelOpenState = false;
  collapseFilterBox: boolean = true;
  
  @Output() onPushFilters: EventEmitter<global> = new EventEmitter();
  @Output() onSubmitFilter: EventEmitter<any> = new EventEmitter();
  @Input() filterSsearch;
  onResetAffaire: boolean = false;
  onResetProspect: boolean = false;
  onResetUtilisateur: boolean = false;
  onResetContratActuel: boolean = false;
  onResetTelephonie: boolean = false;
  onResetActionUtilisateur: boolean = false;
  onResetStatutActuel: boolean = false;
  onResetOpportunityAffaire: boolean = false;
  onResetUtilisateurOpp: boolean = false;
  onResetStatutActuelOpp: boolean = false;
  onResetActionUtilisateurOpp: boolean = false;
  onResetQualite: boolean = false;
  detect: boolean = false;
@ViewChild(BlocQualiteComponent) BlocQualiteComponent:BlocQualiteComponent
  @ViewChild(FilterActionUserOppComponent) FilterActionUserOppComponent: FilterActionUserOppComponent;
  @ViewChild(FilterOpportunityAffaireComponent) FilterOpportunityAffaireComponent: FilterOpportunityAffaireComponent;
  @ViewChild(FilterStatutActuelOppComponent) FilterStatutActuelOppComponent: FilterStatutActuelOppComponent;
  @ViewChild(FilterTelephonieOppComponent) FilterTelephonieOppComponent: FilterTelephonieOppComponent;
  @ViewChild(FilterUserOppComponent) FilterUserOppComponent: FilterUserOppComponent;
  @ViewChild(FiltreActionUserComponent) FiltreActionUserComponent: FiltreActionUserComponent;
  @ViewChild(FiltreAffaireComponent) FiltreAffaireComponent: FiltreAffaireComponent;
  @ViewChild(FiltreInfoContratActuelComponent) FiltreInfoContratActuelComponent: FiltreInfoContratActuelComponent;
  @ViewChild(FiltreProspectComponent) FiltreProspectComponent: FiltreProspectComponent;
  @ViewChild(FiltreStatutActuelComponent) FiltreStatutActuelComponent: FiltreStatutActuelComponent;
  @ViewChild(FiltreUserComponent) FiltreUserComponent: FiltreUserComponent;
  filterParamString: string = null;
  collectionParamString: string = null;
  @Output() collapseCloseToparent: EventEmitter<boolean> = new EventEmitter();
  @Input()collapseFromParent : boolean = false;

  constructor(
    private chRef: ChangeDetectorRef,
    private datePipe: DatePipe,
    private notificationsService: NotificationsService,
    private apiFIlterHistoryService: FilterHistoryService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((queryParams) => {
      this.filterParamString = queryParams.get('filter');
      this.collectionParamString = queryParams.get('collection');
      if (this.filterParamString !== null && this.collectionParamString !== null) {
        if (!this.filterParamString || !this.collectionParamString) {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = 'Opération echoué';
          notificationMessage.message = "La valeur du filtre n'est pas un nombre valide.";
          this.notificationsService.sendMessage(notificationMessage);
        } else {
          this.getFilterByID(this.filterParamString, this.collectionParamString);
          this.collapseFilterBox = true;
        }
        this.collapseFilterBox = false;
      }
    });
  }

  toggleFilterCorp() {
        this.collapseFilterBox = !this.collapseFilterBox;
    this.collapseCloseToparent.emit(false);
  }

  closefilter(event) {
    this.collapseFilterBox = !this.collapseFilterBox;
  }

  getFilterByID(IDFilter, IDCollection) {
    const unsubscribeFilterSave = this.apiFIlterHistoryService.getFiltrebyID(IDFilter, IDCollection).subscribe(
      (response) => {
        this.getFilterFromCollection(response.input);
      },
      (error) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = 'Opération echoué';
        notificationMessage.message = error.error.message;
        this.notificationsService.sendMessage(notificationMessage);
      }
    );
  }

  resetForm() {
    this.router.navigate([], {
      queryParams: {
        filter: null,
        collection: null,
      },
      queryParamsHandling: 'merge',
    });
    localStorage.removeItem('savedFilter');
    localStorage.removeItem('savedCollection');
    this.onResetAffaire = true;
    this.onResetProspect = true;
    this.onResetUtilisateur = true;
    this.onResetContratActuel = true;
    this.onResetTelephonie = true;
    this.onResetActionUtilisateur = true;
    this.onResetStatutActuel = true;
    this.onResetOpportunityAffaire = true;
    this.onResetUtilisateurOpp = true;
    this.onResetStatutActuelOpp = true;
    this.onResetActionUtilisateurOpp = true;
    this.onResetQualite = true;
    this.onSubmitFilter.emit('reset');
    this.toggleFilterCorp();
    this.chRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterSsearch) {
      if (this.filterSsearch === true) {
        this.onResetAffaire = true;
        this.onResetProspect = true;
        this.onResetUtilisateur = true;
        this.onResetContratActuel = true;
        this.onResetTelephonie = true;
        this.onResetActionUtilisateur = true;
        this.onResetStatutActuel = true;
        this.onResetOpportunityAffaire = true;
        this.onResetUtilisateurOpp = true;
        this.onResetStatutActuelOpp = true;
        this.onResetActionUtilisateurOpp = true;
        this.onResetQualite = true;
        this.chRef.detectChanges();
      }
    }
    if(this.collapseFromParent){
      this.collapseFilterBox = true;
    }
   
  }

  getResetAffaire($event) {
    this.onResetAffaire = $event;
  }

  getResetProspect($event) {
    this.onResetProspect = $event;
  }

  getResetUtilisateur($event) {
    this.onResetUtilisateur = $event;
  }

  getResetContratActuel($event) {
    this.onResetContratActuel = $event;
  }

  getResetTelephonie($event) {
    this.onResetTelephonie = $event;
  }

  getResetActionUtilisateur($event) {
    this.onResetActionUtilisateur = $event;
  }

  getResetStatutActuel($event) {
    this.onResetStatutActuel = $event;
  }

  getResetOpportunityAffaire($event) {
    this.onResetOpportunityAffaire = $event;
  }

  getResetUtilisateurOpp($event) {
    this.onResetUtilisateurOpp = $event;
  }

  getResetActionUtilisateurOpp($event) {
    this.onResetActionUtilisateurOpp = $event;
  }

  getResetStatutActuelOpp($event) {
    this.onResetStatutActuelOpp = $event;
  }

  getResetQualite($event) {
    this.onResetQualite = $event;
  }

  EmitFilters() {
    this.onPushFilters.emit(this.filterAffaires);
  }

  EmitFilterAffaire($event: Affaire) {
    this.detect = true;
    this.filterAffaires.aff.affaire = $event;
    this.EmitFilters();
  }

  EmitFilterUtilisateur($event: Utilisateur) {
    this.filterAffaires.aff.utilisateur = $event;

    this.EmitFilters();
  }

  EmitFilterProspect($event: Prospect) {
    this.filterAffaires.aff.prospect = $event;
    this.EmitFilters();
  }

  EmitFilterContratActuel($event: ContratActuel) {
    this.filterAffaires.aff.contrat_actuel = $event;
    this.EmitFilters();
  }

  EmitFilterTelephonie($event: TelephonieOpp) {
    this.filterAffaires.opp.telephonie = $event;
    this.EmitFilters();
  }

  EmitFilterActionUtilisateurOpp($event: Action_user) {
    this.filterAffaires.opp.action_user = $event;
    this.EmitFilters();
  }

  EmitFilterActionUtilisateur($event: ActionUserAffaire) {
    this.filterAffaires.aff.action_user = $event;
    this.EmitFilters();
  }

  EmitFilterStatutActuel($event: StatutActuel) {
    this.filterAffaires.aff.statut_actuel = $event;
    this.EmitFilters();
  }

  EmitFilterOpportunityAffaire($event: Filtre_opp) {
    this.filterAffaires.opp.filtre_opp = $event;
    this.EmitFilters();
  }

  EmitFilterStatutActuelOpp($event: Rdv) {
    this.filterAffaires.opp.rdv = $event;
    this.EmitFilters();
  }

  EmitFilterQualite($event) {
    this.filterAffaires.aff.qualite = $event;
    this.EmitFilters();
  }

  EmitFilterUtilisateurOpp($event: UtilisateurOpp) {
    this.filterAffaires.opp.utilisateur = $event;
    this.EmitFilters();
  }

  submitFilter() {
    if (!this.detect) {
      this.onSubmitFilter.emit('submit');
    } else {
      this.onSubmitFilter.emit('affaire');
      this.detect = false;
    }

    this.toggleFilterCorp();
  }

  getFilterFromCollection($event) {
    localStorage.setItem('filtre', JSON.stringify($event));
    this.FilterActionUserOppComponent?.ngOnInit();
    this.FilterOpportunityAffaireComponent?.ngOnInit();
    this.FilterStatutActuelOppComponent?.ngOnInit();
    this.FilterTelephonieOppComponent?.ngOnInit();
    this.FilterUserOppComponent?.ngOnInit();
    this.FiltreActionUserComponent?.ngOnInit();
    this.FiltreAffaireComponent?.ngOnInit();
    this.FiltreInfoContratActuelComponent?.ngOnInit();
    this.FiltreProspectComponent?.ngOnInit();
    this.BlocQualiteComponent?.ngOnInit();
    this.FiltreStatutActuelComponent?.ngOnInit();
    this.FiltreUserComponent?.ngOnInit();
    this.FiltreUserComponent?.ngAfterViewInit();
    this.onPushFilters.emit($event);

    this.submitFilter();
  }

  submitFilterEnter(event: any) {
    if (event.keyCode === 13) {
      this.onSubmitFilter.emit('');
      this.toggleFilterCorp();
    }
  }
}
