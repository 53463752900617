import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild,ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { Gamme } from 'src/app/entity/Generic/Gamme';
import { FilterEcheances } from 'src/app/entity/FilterEcheances';

import { ApiContratsService } from 'src/app/services/ApiContrats/api-contrats.service';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { LigneProduit } from 'src/app/entity/Bpm/LigneProduits';
import { ApiCampagniesService } from 'src/app/services/ApiCompagnies/api-compagnies.service';
import { OrganismeListe } from 'src/app/entity/OrganismeListe';

@Component({
  selector: 'app-filtre-affaires',
  templateUrl: './filtre-affaire.component.html',
  styleUrls: ['./filtre-affaire.component.css']
})
export class FiltreAffaireComponent implements OnInit , OnChanges {
  fa = fa;
  filterAffaire: FilterEcheances = new FilterEcheances();

    /* Gamme */
  gammeFromCtrl: FormControl = new FormControl('');
  filteredGammes: Observable<Gamme[]>;
  ListGammes: string[] = [];
  allGammes: Gamme[] = [];
  gammeSelected: any[] = [];
  @ViewChild('gammeInput') gammeInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoGamme') matAutocompleteGamme: MatAutocomplete;
  selectableGamme = true;
  removableGamme = true;

  /* ligne de produit */
  filteredProducts: Observable<LigneProduit[]>;
  ListProducts: string[] = [];
  allProducts: LigneProduit[] = [];
  ProductsFromCtrl: FormControl = new FormControl('');
  listProductSelected: string[] = [];
  _unsubscribeAll: Subscription[]=[];
  selectableLigneProduit = true;
  removableLigneProduit = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('ProductsInput') ProductsInput: ElementRef<HTMLInputElement>;
 /** organisme */

 ListOrganisme: string[] = [];
 allOrganisme:OrganismeListe[] = [];
 listOrganisme: OrganismeListe[] = [];
 organismeFromCtrl: FormControl = new FormControl('');
 @ViewChild('OrganismeInput') OrganismeInput: ElementRef<HTMLInputElement>;
 @ViewChild('autoOrganisme') matAutocompleteOrganisme: MatAutocomplete;
 filteredOrganisme: Observable<OrganismeListe[]>;
 OrganismeSelectionees : any [] = [];
 selectable = true;
 removable = true;

 @Output() returnToparent = new EventEmitter<boolean>();
 @Output() onPushFilterAffaire: EventEmitter<FilterEcheances> = new EventEmitter();
   @Input () resetAffaire : boolean ;
  constructor(    private apiOpportuniteService: ApiOpportunitService,
    private apiContratsService: ApiContratsService,
    private apiCampagniesService: ApiCampagniesService,
    private cdRef:ChangeDetectorRef
    ) { }

  ngOnInit(): void {

    this.getGamme();
    
    this.listeOrganisme();
    this.getListLignesProduits()
    this.filteredGammes = this.gammeFromCtrl.valueChanges.pipe(
      startWith(null),
      map((gamme: string | null) => (gamme ? this._filterGammes() : this.allGammes.slice()))
    );



    this.filteredProducts = this.ProductsFromCtrl.valueChanges.pipe(
      startWith(null),
      map((product: string | null) => (product ? this._filter() : this.allProducts.slice()))
    );


    this.filteredOrganisme = this.organismeFromCtrl.valueChanges.pipe(
      startWith(null),
      map((organisme: string | null) => (organisme ? this._filterOrganisme() : this.allOrganisme.slice()))
    );

 
    
  }

  selectOptiongamme(option: string): void {
    this.filterAffaire.operateur_gamme = option;
    this.EmitFilterAffaire()
    this.cdRef.detectChanges();

  }

  selectOptionProduit(option: string): void {
    this.filterAffaire.operateur_ligne_de_produit= option;
    this.EmitFilterAffaire()
    this.cdRef.detectChanges();

  }
  
  selectOptionOrganisme(option: string): void {
    this.filterAffaire.operateur_organisme= option;
    this.EmitFilterAffaire()
    this.cdRef.detectChanges();

  }

  

  /** gammme */
  selectedGammes(event: MatAutocompleteSelectedEvent): void {
    if (!this.gammeSelected.includes(event.option.value)) {
    this.ListGammes.push(event.option.viewValue);
    this.gammeSelected.push(event.option.value);}
    this.gammeInput.nativeElement.value = '';
    this.gammeFromCtrl.setValue(null);
    this.EmitFilterAffaire()
  }

  removeGammes(gamme: string): void {
    const index = this.ListGammes.indexOf(gamme);
    if (index >= 0) {
      this.ListGammes.splice(index, 1);
      this.gammeSelected.splice(index, 1);
    }
    if (this.gammeSelected.length===0){
      this.filterAffaire.operateur_gamme="="
    }
  }



  getGamme() {
    this.apiOpportuniteService.getAllGammes().subscribe((Response: Gamme[]) => {
      this.allGammes = Response;
    });
  }




 /** Linge de produit */

 getListLignesProduits() {
  const unsubscribeLignedeProduit = this.apiContratsService
    .listeAllProduits()
    .pipe(
      tap(({ lignes_produits }) => {
        this.allProducts = lignes_produits;
      })
    )
    .subscribe();
  this._unsubscribeAll.push(unsubscribeLignedeProduit)
}

selectedProducts(event: MatAutocompleteSelectedEvent): void {
  if (!this.listProductSelected.includes(event.option.value)) {
  this.ListProducts.push(event.option.viewValue);
  this.listProductSelected.push(event.option.value);}
  this.ProductsInput.nativeElement.value = '';
  this.ProductsFromCtrl.setValue(null);
  this.EmitFilterAffaire()
}

removeProducts(product: string): void {
  const index = this.ListProducts.indexOf(product);
  if (index >= 0) {
    this.ListProducts.splice(index, 1);
    this.listProductSelected.splice(index, 1);
  }
  if (this.listProductSelected.length ===0){
    this.filterAffaire.operateur_ligne_de_produit="="
  }
}

private _filter(): any[] {
  let filterValue = this.ProductsFromCtrl.value.toString().toLowerCase();
  if (filterValue === null) filterValue = '';
  return this.allProducts.filter((product) => product.type.toLowerCase().includes(filterValue));
}

private _filterOrganisme(): OrganismeListe[] {
  let filterValue = this.organismeFromCtrl.value.toString().toLowerCase();
  if (filterValue === null) filterValue = '';
  
  return this.allOrganisme.filter((organisme) => organisme.nom.toLowerCase().includes(filterValue.toLowerCase()) );
}


private _filterGammes(): Gamme[] {
  let filterValue = this.gammeFromCtrl.value.toString().toLowerCase();
  if (filterValue === null) filterValue = '';

  return this.allGammes.filter((gamme) =>
    gamme.nom  ? gamme.nom.toLowerCase().includes(filterValue.toLowerCase())  : null
  );
}

/** organisme */

listeOrganisme(){
  this.apiCampagniesService.getOrganismes().subscribe((Response: OrganismeListe[]) => {
    this.allOrganisme = Response;
  });

}



selectedOrganisme(event: MatAutocompleteSelectedEvent): void {
  if (!this.OrganismeSelectionees.includes(event.option.value)) {
  this.ListOrganisme.push(event.option.viewValue);
  this.OrganismeSelectionees.push(event.option.value);}
  this.OrganismeInput.nativeElement.value = '';
  this.organismeFromCtrl.setValue(null);
  this.EmitFilterAffaire()

}

removeOrganisme(organisme: string): void {
  const index = this.ListOrganisme.indexOf(organisme);
  if (index >= 0) {
    this.ListOrganisme.splice(index, 1);
    this.OrganismeSelectionees.splice(index, 1);
  }
  if (this.OrganismeSelectionees.length===0){
    this.filterAffaire.operateur_organisme="="
  }
}



EmitFilterAffaire() {
  if (this.gammeSelected.length===0){
    this.filterAffaire.operateur_gamme="="
  }
  if (this.listProductSelected.length ===0){
    this.filterAffaire.operateur_ligne_de_produit="="
  }
   if (this.OrganismeSelectionees.length===0){
    this.filterAffaire.operateur_organisme="="
  }
 this.filterAffaire.organisme =  this.OrganismeSelectionees;
 this.filterAffaire.gamme = this.gammeSelected;
 this.filterAffaire.ligne_de_produit = this.listProductSelected;
 this.onPushFilterAffaire.emit(this.filterAffaire);

}

ngOnChanges(changes: SimpleChanges) {
  this.resetAffaireForm(changes.resetAffaire.currentValue);
}

resetAffaireForm(Reset) {

  if (Reset === true) {
  
     this.filterAffaire.organisme = this.OrganismeSelectionees =[];  
     this.ListOrganisme=[]; 
    this.filterAffaire.gamme = this.gammeSelected =[];
    this.ListGammes =[];
    this.filterAffaire.ligne_de_produit = this.listProductSelected = [];  
    this.ListProducts= []
    this.filterAffaire.operateur_ligne_de_produit = '='; 
    this.filterAffaire.operateur_organisme = '='; 
    this.filterAffaire.operateur_gamme = '=';  
    Reset = false;
    this.returnToparent.emit(Reset);
      this.onPushFilterAffaire.emit(this.filterAffaire);
  }
}
}
