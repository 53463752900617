
<div class="card-head text-center">
  <div class="row details-header">

     <div class="col-3"> 
     
      <button type="button" (click)="retourToList()" class="btn-dt-retourr mt-1" >
        <div class="icon-position">
            <fa-icon class="fa-icon-style" [icon]="fa['faArrowLeft']"></fa-icon>&nbsp;
        </div>
    </button>
     </div>
     <div class="col-6">

     </div>
     <div class="col-3"> 
      <p>
        Titre:
         <label class="label-card-info" style="margin-top: 13px;">
           {{title}}
         </label>
       </p>
     </div>
</div>
</div>  
<div class="card" style="margin-bottom: 5rem;">
  <div class="row">
    <div class="col-12">
      <div *ngIf="diagramNodeData.length>0">
        Diagramme
        <gojs-diagram
          [initDiagram]='initDiagram'
          [nodeDataArray]='diagramNodeData'
          [linkDataArray]='diagramLinkData'
          [skipsDiagramUpdate]='skipsDiagramUpdate'
          (modelChange)='diagramModelChange($event)'
          [divClassName]='diagramDivClassName'>
        </gojs-diagram>
      </div>
    </div>
  </div>
</div>
