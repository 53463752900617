import { ProspectClient } from './../../entity/prospect-client';
import { Prospect } from 'src/app/entity/Prospect';
import { ProspectCLientService } from './../../services/prospect-client.service';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SharedMenuObserverService } from 'src/app/services/SharedMenuObserver/shared-menu-observer.service';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-prospect-client',
  templateUrl: './prospect-client.component.html',
  styleUrls: ['./prospect-client.component.css'],
})
export class ProspectClientComponent implements OnInit {
  limit = 15;
  pages = 1;
  totalPages: number = 15;
  intervale = 1;
  sSearch: string = '';
  listProspectClient: any[]=[];
  fa = fa;
  ProspectClientForm: FormGroup;
  FilterProspectClient: ProspectClient = new ProspectClient();

  subscribegetListProspectClient: Subscription;
  totalLength  : number =0;
  constructor(
    private sharedMenuObserverService: SharedMenuObserverService,
    private prospectcLientservice: ProspectCLientService,
    private chRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.sharedMenuObserverService.updateMenu('Prospect/Client');
    this.showloader();
    this.getListProspectCLient();
    this.ProspectClientForm = new FormGroup({});
  }

  getListProspectCLient() {
    this.subscribegetListProspectClient = this.prospectcLientservice
      .getListProspectClient(this.FilterProspectClient, this.pages, this.limit)
      .subscribe((response: any) => {
        this.listProspectClient = response.list_prospects;
        this.chRef.detectChanges();
        this.totalLength = response.iTotalDisplayRecords;
        this.calculate_nb_pages(response.iTotalDisplayRecords);
        this.hideloader();
      },
      ()=>{
        this.hideloader();
      });
  }

  calculate_nb_pages(iTotalDisplayRecords) {
    this.totalPages = Math.floor(iTotalDisplayRecords / this.limit);
    if (iTotalDisplayRecords % this.limit !== 0) {
      this.totalPages += 1;
    }
  }

  getchangeLimite() {
    if (this.intervale <= this.totalPages) {
      this.pages = this.intervale;
      if (this.pages <= this.totalPages) {
        this.getListProspectCLient();
      }
    }
  }

  first() {
    this.setPage(1);
  }

  prev() {
    this.setPage(Math.max(1, this.pages - 1));
  }

  next() {
    this.setPage(Math.min(this.totalPages, this.pages + 1));
  }

  last() {
    this.setPage(this.totalPages);
  }

  setPage(val: number) {
    this.pages = val;
    this.getListProspectCLient();
  }

  isFirst(): boolean {
    return this.pages === 1;
  }

  isLast(): boolean {
    return this.pages === this.totalPages;
  }

  updateListProspect() {
    this.pages = 1;
    this.getListProspectCLient();
  }

  changepage(number: number) {
    this.pages = number;
    localStorage.setItem('pages', number.toString());
    this.getListProspectCLient();
  }

  getDataValue(event) {
    if ((event.target as HTMLInputElement).value.length > 3 || (event.target as HTMLInputElement).value.length === 0) {
      this.FilterProspectClient.sSearch = (event.target as HTMLInputElement).value;
      this.getListProspectCLient();
    }
  }

  hideloader() {
    document.getElementById('loadingBar').style.display = 'none';
  }

  showloader() {
    document.getElementById('loadingBar').style.display = 'block';
  }
}
