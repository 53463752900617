<div class="row" style="margin-top: 15px">
    <div class="col-6">
        <div class="form-group">
            <ng-container >
                <mat-form-field style="width: 100%;">
                    <mat-label>Organisme</mat-label>
                    <ng-container class="p-3" *ngIf="allOrganisme?.length === 0">
                        <div class="row">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="15"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                    <mat-chip-list #organisme>
                        <mat-chip *ngFor="let organisme of ListOrganisme" [selectable]="selectable"
                            [removable]="removable" (removed)="removeOrganisme(organisme)"
                            (removed)="EmitFilterUtilisateurOpp()">
                            {{organisme}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)="EmitFilterUtilisateurOpp()" placeholder="Selectionner Organisme..."
                            #OrganismeInput [formControl]="organismeFromCtrl" [matAutocomplete]="auto"
                            [matChipInputFor]="organisme" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedOrganisme($event)">
                        <mat-option *ngFor="let organisme of filteredOrganisme | async" [value]="organisme.id">
                            {{organisme.nom}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <ng-container >
                <mat-form-field style="width: 100%;">
                    <mat-label>Liste des créateurs</mat-label>
                    <ng-container class="p-3" *ngIf="allCreateur?.length === 0">
                        <div class="row">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="15"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                    <mat-chip-list #ListeCreateur>
                        <mat-chip *ngFor="let createur of ListCreateur" [selectable]="selectable"
                            [removable]="removable" (removed)="removeCreateur(createur)"
                            (removed)="EmitFilterUtilisateurOpp()">
                            {{createur}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)="EmitFilterUtilisateurOpp()" placeholder="Selectionner Liste des créateurs..."
                            #CreateurInput [formControl]="CreateurFromCtrl" [matAutocomplete]="autoCreateur"
                            [matChipInputFor]="ListeCreateur" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoCreateur="matAutocomplete" (optionSelected)="selectedCreateur($event)">
                        <mat-option *ngFor="let createur of filteredCreateur | async" [value]="createur.id">
                            {{createur.nom + " " +createur.prenom}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-6">
        <div class="form-group">
            <ng-container >
                <mat-form-field style="width: 100%;">
                    <mat-label>Liste des affectations</mat-label>
                    <ng-container class="p-3" *ngIf="allListeAffectation?.length === 0">
                        <div class="row">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="15"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                    <mat-chip-list #ListeAffectation>
                        <mat-chip *ngFor="let affectation of ListAffectation" [selectable]="selectable"
                            [removable]="removable" (removed)="removeListeAffectation(affectation)"
                            (removed)="EmitFilterUtilisateurOpp()">
                            {{affectation}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)="EmitFilterUtilisateurOpp()" placeholder="Selectionner Liste des affectations..."
                            #listeAffectationInput [formControl]="listeAffectationFromCtrl"
                            [matAutocomplete]="autoListeAffectation" [matChipInputFor]="ListeAffectation"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoListeAffectation="matAutocomplete"
                        (optionSelected)="selectedListeAffectation($event)">
                        <mat-option *ngFor="let affectation of filteredListeAffectation | async"
                            [value]="affectation.id">
                            {{affectation.nom +" "+affectation.prenom}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div>
<!-- 
        <div class="col-6">
            <div class="form-group">
                <ng-container >
                    <mat-form-field style="width: 100%;">
                        <mat-label>Services</mat-label>
                        <ng-container class="p-3" *ngIf="allService?.length === 0">
                            <div class="row">
                                <div class="text-center m-auto">
                                    <mat-spinner [diameter]="15"></mat-spinner>
                                </div>
                            </div>
                        </ng-container>
                        <mat-chip-list #Services>
                            <mat-chip *ngFor="let service of ListService" [selectable]="selectable" [removable]="removable"
                                (removed)="removeService(service)" (removed)="EmitFilterUtilisateurOpp()">
                                {{service}}
                                <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                                </fa-icon>
                            </mat-chip>
                            <input (click)="EmitFilterUtilisateurOpp()" placeholder="Selectionner Services..." #serviceInput
                                [formControl]="serviceFromCtrl" [matAutocomplete]="autoService" [matChipInputFor]="Services"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                        </mat-chip-list>
                        <mat-autocomplete #autoService="matAutocomplete" (optionSelected)="selectedService($event)">
                            <mat-option *ngFor="let service of filteredService | async" [value]="service.id">
                                {{service.libele}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </ng-container>
            </div>
        </div> -->

    <!-- <div class="col-6">
        <div class="form-group">
            <ng-container >
                <mat-form-field style="width: 100%;">
                    <mat-label>Zone</mat-label>
                    <ng-container class="p-3" *ngIf="allZones?.length === 0">
                        <div class="row">
                            <div class="text-center m-auto">
                                <mat-spinner [diameter]="15"></mat-spinner>
                            </div>
                        </div>
                    </ng-container>
                    <mat-chip-list #Zone>
                        <mat-chip *ngFor="let Zone of ListZones" [selectable]="selectable" [removable]="removable"
                            (removed)="removeZones(Zone)" (removed)="EmitFilterUtilisateurOpp()">
                            {{Zone}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)="EmitFilterUtilisateurOpp()" placeholder="Selectionner Zones..." #ZonesInput
                            [formControl]="zoneFromCtrl" [matAutocomplete]="autoZones" [matChipInputFor]="Zone"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoZones="matAutocomplete" (optionSelected)="selectedZones($event)">
                        <mat-option *ngFor="let Zone of filteredZones | async" [value]="Zone.id">
                            {{Zone.nom}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div> -->
    <!-- <div class="col-6">
        <div class="form-group" style="margin-bottom: 0px;">
            <label></label>
            <mat-form-field appearance="fill" style="width: 100%;margin-top: -11px;">
                <mat-select (selectionChange)="EmitFilterUtilisateurOpp()"
                    [(ngModel)]="filterUtilisateurOpp.operateur_recherche_group" placeholder="Opérateur">
                    <mat-option value="OU">
                        Ou
                    </mat-option>
                    <mat-option value="ET">
                        Et
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
    </div> -->
</div>
<!-- <div class="row"> -->
    <!-- <div class="col-6">
        <div class="form-group">
            <ng-container *ngIf="allGroupeAffectee.length > 0">
                <mat-form-field style="width: 100%;">
                    <mat-label>Groupes Affectés</mat-label>
                    <mat-chip-list #GroupesAffectes>
                        <mat-chip *ngFor="let groupe of ListGroupeAffectee" [selectable]="selectable"
                            [removable]="removable" (removed)="removeGroupeAffectee(groupe)"
                            (removed)="EmitFilterUtilisateurOpp()">
                            {{groupe}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)="EmitFilterUtilisateurOpp()" placeholder="Selectionner Groupes Affectés..."
                            #groupeAffecteeInput [formControl]="groupeAffecteeFromCtrl"
                            [matAutocomplete]="autoGroupeAffectee" [matChipInputFor]="GroupesAffectes"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoGroupeAffectee="matAutocomplete"
                        (optionSelected)="selectedGroupeAffectee($event)">
                        <mat-option *ngFor="let groupe of filteredGroupeAffectee | async" [value]="groupe.id">
                            {{groupe.nom}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div> -->
    <!-- <div class="col-6">
        <div class="form-group" style="margin-bottom: 0px;">
            <label></label>
            <mat-form-field appearance="fill" style="width: 100%;margin-top: -11px;">
                <mat-select (selectionChange)="EmitFilterUtilisateurOpp()"
                    [(ngModel)]="filterUtilisateurOpp.operateur_recherche_group_affet" placeholder="Opérateur">
                    <mat-option value="OU">
                        Ou
                    </mat-option>
                    <mat-option value="ET">
                        Et
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
    </div> -->
<!-- </div> -->
<!-- <div class="row"> -->
    <!-- <div class="col-6">
        <div class="form-group">
            <ng-container *ngIf="allGroupeAffectee.length > 0">
                <mat-form-field style="width: 100%;">
                    <mat-label>Groupes créateurs</mat-label>
                    <mat-chip-list #Groupescreateurs>
                        <mat-chip *ngFor="let groupe of ListGroupeCreateur" [selectable]="selectable"
                            [removable]="removable" (removed)="removeGroupeCreateur(groupe)"
                            (removed)="EmitFilterUtilisateurOpp()">
                            {{groupe}}
                            <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']">
                            </fa-icon>
                        </mat-chip>
                        <input (click)="EmitFilterUtilisateurOpp()" placeholder="Selectionner Groupes créateurs..."
                            #groupeCreateurInput [formControl]="groupeCreateurFromCtrl"
                            [matAutocomplete]="autoGroupeCreateur" [matChipInputFor]="Groupescreateurs"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                    </mat-chip-list>
                    <mat-autocomplete #autoGroupeCreateur="matAutocomplete"
                        (optionSelected)="selectedGroupeCreateur($event)">
                        <mat-option *ngFor="let groupe of filteredGroupeCreateur | async" [value]="groupe.id">
                            {{groupe.nom}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </ng-container>
        </div>
    </div> -->
  
<!-- </div> -->
