
<div class="row operateurContainer">
    <div class="col-2 d-flex justify-content-center align-items-center">

        <button (click)="selectOptiongamme('=')" class="operateur mr-3" [ngClass]="{ 'active': filterAffaire.operateur_gamme === '=' }">
            <fa-icon  [icon]="fa['faEquals']"></fa-icon>
        </button>
        <button (click)="selectOptiongamme('!=')"  class="operateur" [ngClass]="{ 'active': filterAffaire.operateur_gamme === '!=' }">
            <fa-icon  [icon]="fa['faNotEqual']"></fa-icon>
        </button>
    </div>
    <div class="col-10 mt-2">
        <ng-container >
            <mat-form-field class="example-full-width w-100" >
                <mat-label>Gammes</mat-label>
                <ng-container class="p-3" *ngIf="allGammes.length === 0">
                    <div class="row">
                        <div class="text-center m-auto">
                            <mat-spinner [diameter]="15"></mat-spinner>  
                        </div>
                    </div>
                </ng-container>
                <mat-chip-list #Gammes>
                    <mat-chip *ngFor="let gammes of ListGammes" [selectable]="selectableGamme" [removable]="removableGamme"
                        (removed)="removeGammes(gammes)" (removed)=" EmitFilterAffaire()">
                        {{gammes}}
                        <i matChipRemove *ngIf="removableGamme" class="fa fa-times" aria-hidden="true"></i>

                        <!-- <fa-icon matChipRemove *ngIf="removableGamme" style="color: white;" [icon]="fa['faTimes']"> 
                        </fa-icon>-->
                    </mat-chip>
                    <input (change)=" EmitFilterAffaire()" placeholder="Selectionner Gammes..." #gammeInput
                        [formControl]="gammeFromCtrl" [matAutocomplete]="autoGamme" [matChipInputFor]="Gammes"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
                </mat-chip-list>
                <mat-autocomplete #autoGamme="matAutocomplete" (optionSelected)="selectedGammes($event)">
                    <mat-option *ngFor="let gammes of filteredGammes | async" [value]="gammes.id">
                        {{gammes.nom}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </ng-container>
    </div>
</div>
<div class="row operateurContainer">
      <div class="col-2 d-flex justify-content-center align-items-center">

        <button (click)="selectOptionProduit('=')" class="operateur mr-3" [ngClass]="{ 'active':filterAffaire.operateur_ligne_de_produit === '=' }">
            <fa-icon  [icon]="fa['faEquals']"></fa-icon>
        </button>
        <button (click)="selectOptionProduit('!=')"  class="operateur" [ngClass]="{ 'active': filterAffaire.operateur_ligne_de_produit=== '!=' }">
            <fa-icon  [icon]="fa['faNotEqual']"></fa-icon>
        </button>
    </div>
<div class="col-10">
    <div class="form-group">
      <mat-form-field class="example-full-width w-100">
        <mat-label>Ligne de Produit</mat-label>
        <ng-container class="p-3" *ngIf="allProducts.length === 0">
            <div class="row">
                <div class="text-center m-auto">
                    <mat-spinner [diameter]="15"></mat-spinner>  
                </div>
            </div>
        </ng-container>
        <mat-chip-list #chipListProduct>
            <mat-chip *ngFor="let product of ListProducts" [selectable]="selectableLigneProduit" [removable]="removableLigneProduit" (removed)="removeProducts(product)" (removed)="EmitFilterAffaire()">
                {{product}}
                <i matChipRemove *ngIf="removableLigneProduit" class="fa fa-times" aria-hidden="true"></i>

                <!-- <fa-icon matChipRemove *ngIf="removableLigneProduit" style="color: white;" [icon]="fa['faTimes']"></fa-icon> -->
            </mat-chip>
            <input (change)="EmitFilterAffaire()" placeholder="Selectionner produit..." #ProductsInput [formControl]="ProductsFromCtrl" [matAutocomplete]="autoProduit" [matChipInputFor]="chipListProduct" [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
        </mat-chip-list>
        <mat-autocomplete #autoProduit="matAutocomplete" (optionSelected)="selectedProducts($event)">
            <mat-option *ngFor="let product of filteredProducts | async" [value]="product.id">
                {{product.type}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    </div>
  </div>
 </div>
 <div class="row operateurContainer">

  <div class="col-2 d-flex justify-content-center align-items-center">

    <button (click)="selectOptionOrganisme('=')" class="operateur mr-3" [ngClass]="{ 'active':filterAffaire.operateur_organisme === '=' }">
        <fa-icon  [icon]="fa['faEquals']"></fa-icon>
    </button>
    <button (click)="selectOptionOrganisme('!=')"  class="operateur" [ngClass]="{ 'active': filterAffaire.operateur_organisme=== '!=' }">
        <fa-icon  [icon]="fa['faNotEqual']"></fa-icon>
    </button>
</div>
  <div class="col-10">

    <div class="form-group">
   
            <mat-form-field  class="w-100">
              <mat-label>Organisme</mat-label>
              <ng-container class="p-3" *ngIf="allOrganisme.length === 0">
                <div class="row">
                    <div class="text-center m-auto">
                        <mat-spinner [diameter]="15"></mat-spinner>  
                    </div>
                </div>
            </ng-container>
            <mat-chip-list #organisme>
                <mat-chip *ngFor="let organisme of ListOrganisme" [selectable]="selectable" [removable]="removable"
                  (removed)="removeOrganisme(organisme)" (removed)="EmitFilterAffaire()">
                  {{organisme}}
                  <i matChipRemove *ngIf="removable" class="fa fa-times" aria-hidden="true"></i>
                  <!-- <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon> -->
                </mat-chip>
                <input  (change)="EmitFilterAffaire()" placeholder="Selectionner Organisme..." #OrganismeInput
                  [formControl]="organismeFromCtrl" [matAutocomplete]="auto" [matChipInputFor]="organisme"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes">

              </mat-chip-list>
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedOrganisme($event)">
                <mat-option *ngFor="let organisme of filteredOrganisme | async" [value]="organisme.real_id">

                    {{organisme.nom}}
                </mat-option>

              </mat-autocomplete>

             

            </mat-form-field>


    </div>
  </div>
  
</div>


