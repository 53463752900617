<div class="flex-column bg-white rounded-card">
  <div class="header-card-gestion py-3">
    <div class="col-3">
    <button type="button" class="btn-dt-add  ml-4" routerLink="fiche/Nouveau"  data-title="Ajouter une compagnie">
      <fa-icon  [icon]="fa['faPlus']"></fa-icon>
    </button>
  </div>
  </div>
  <div class="p-3 mb-3" >
    <table id="Table" class="table">
      <thead class="head-table">
        <tr>
          <th> Identifiant</th>
          <th> Immatriculation </th>
          <th> Raison sociale </th>
          <th> Type</th>
          <th> Action</th>
        </tr>
      </thead>
      <tbody class="body-table">
        <tr  *ngFor="let item of Companies; let index = index">
          <td>{{ item.siren }}</td>
          <td>{{ item.matricule }}</td>
          <td>{{ item.denomination }}</td>
          <td>{{ item.types }}</td>
          <td>
            <button type="button" class="btn-dt-list" routerLink="fiche/{{item.siren}}">
              <img src="/assets/icons/edit-icon.svg">

            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
