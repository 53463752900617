import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import * as fa from '@fortawesome/free-solid-svg-icons';

library.add(fas, far);

@Component({
  selector: 'app-icon-picker',
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,


})
export class IconPickerComponent implements OnInit  {
  @Output() onChangeIcon: EventEmitter<string> = new EventEmitter();
  @Output() onChangeNameIcon: EventEmitter<string> = new EventEmitter();
  @Output() checkedNameIcon: EventEmitter<boolean> = new EventEmitter();

  icons: FontAwesomeIcon[] = [];
  @Input() searchText: string = "";
  @Input() req = 'true';
  @Input() touched :boolean = false
  fa = fa
  constructor() {
    let fasArray = Object.keys(fas);
    let farArray = Object.keys(far);
    fasArray.forEach(icon => this.icons.push({ 'fas': icon }));
    farArray.forEach(icon => this.icons.push({ 'far': icon }));
  }



  ngOnInit(): void {

  }

  onChange(newValue) {
    this.searchText = newValue;
    this.onChangeIcon.emit(newValue);
    this.onChangeNameIcon.emit(newValue);
   if( this.filter(newValue).length == 0 && newValue !== ''){
    this.checkedNameIcon.emit(false)
   } else{
    this.checkedNameIcon.emit(true)
   }
 
  }

  getIconObject(icon: FontAwesomeIcon): string {
    const key = Object.keys(icon)[0];
    return icon[key];
  }

  getIconName(icon): string {
    const key = Object.keys(icon)[0];
    return icon[key];
  }

  filter(itemList: string): FontAwesomeIcon[] {
    if (itemList != "") {
      return this.icons.filter(i => i[Object.keys(i)[0]].indexOf(itemList) > -1);
    } else {
      return []
    }
  }
  onSelectIcon(icoName: string) {
    this.onChangeIcon.emit(icoName);
    this.checkedNameIcon.emit(true)
    this.touched = false;

  }
}

export interface FontAwesomeIcon {
  [pack: string]: string;
}
