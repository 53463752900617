<form (keyup.enter)="submitFilterEnter($event)">
  <div class="filter-bubble">
    
        <div class="filter-bubble-icon" (click)="toggleFilterCorp()">
          <fa-icon *ngIf="collapseFilterBox" [icon]="faFilter"></fa-icon>
          <fa-icon *ngIf="!collapseFilterBox" [icon]="fa['faTimes']"></fa-icon>
    </div>
  </div>
  <div class="filter-corp" [ngClass]="{'collapse_filter_corp':collapseFilterBox}">
    <div class="row m-0">

      <button type="button" class="filtrerBtnHeader mb-2 w-100">
        <!-- <img (click)="toggleFilterCorp()" class="fa fa-lg float-left ml-2"
          src="assets/icons/filter-circle-xmark-solid.svg" />&nbsp; -->
        Filtrer la recherche
      </button>
    </div>
    <!-- <div class="filter-close-corp-bubble" (click)="toggleFilterCorp()">
      <div class="close-element">
        <fa-icon [icon]="faPlus"></fa-icon>
      </div>
    </div> -->
    <div class="ml-1">

      <div class="row">
        <div class="col-6">
          <div class="row  align-self-center  float-left">
            <button type="button" class="btn-load" type="button" (click)="resetForm()">
              <fa-icon [icon]="fa['faRedoAlt']"></fa-icon>
            </button>
             <app-list-filter-saved [entity]="'A'" (filterOpportunite)="getFilterFromCollection($event)"  (reloadParentEvent)="closefilter($event)"></app-list-filter-saved>
            <!-- <div class="FILTRES__RECHERCHES" >
              <fa-icon class="fa-2x mr-2" (click)="toggleFilterCorp()" [icon]="faSearch"></fa-icon>
                <span>{{'opportunities.label.morefilters' | translate}}</span>
            </div> -->
          </div>
        </div>
        <div class="col-6">
          <div class="row align-self-center float-right">


            <app-save-filtre [filterOpportunite]="filterAffaires" [entity]="'A'"></app-save-filtre>
            <button type="submit" class="ml-2 btn-dt-save-Filtre" (click)="submitFilter()">
              <fa-icon class="fa-lg" [icon]="fa['faSearch']"></fa-icon>&nbsp;
              {{'opportunities.label.startasearch' | translate}}
            </button>
          </div>
        </div>
      </div>
      <hr>
            <div class="scrolling"  id="style-1">
                <mat-tab-group mat-align-tabs="center" style="z-index: 0;">
                    <mat-tab label="Affaires">
                        <div class="row mb-2 mt-3" >

                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <div class="row w-100 d-flex justify-content-center" >
                                                        <div class="row filtre-spn">
                                                              <fa-icon class="fa-lg mr-2" [icon]="fa['faBriefcase']" width="28px"></fa-icon>
                                                                <span> Affaires</span>
                                                        </div>
                                                  </div>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3" >
                                            <app-filtre-affaire  class="w-100" (onPushFilterAffaire)="EmitFilterAffaire($event)"
                                                (returnToparent)="getResetAffaire($event)" [Reset]="onResetAffaire"
                                            >
                                            </app-filtre-affaire>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div>



                        <!-- <div class="row mb-2" >

                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              <div class="row w-100 d-flex justify-content-center" >
                                                    <div class="row filtre-spn" >
                                                        <fa-icon class="fa-lg mr-2" [icon]="fa['faFileContract']"></fa-icon>
                                                        <span> Informations Contrat Actuel</span>
                                                    </div>
                                                </div>

                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3" >
                                            <app-filtre-info-contrat-actuel  class="w-100"
                                                (onPushFilterContratActuel)="EmitFilterContratActuel($event)"
                                                (returnToparent)="getResetContratActuel($event)"
                                                [Reset]="onResetContratActuel" >
                                            </app-filtre-info-contrat-actuel>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div> -->



                        <div class="row mb-2" >
                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <div class="row w-100 d-flex justify-content-center">
                                                    <div class="row filtre-spn" >
                                                        <fa-icon class="fa-lg mr-2" [icon]="fa['faUser']"></fa-icon>
                                                        <span>Utilisateurs</span>
                                                    </div>
                                                </div>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3">
                                            <app-filtre-user class="w-100" (onPushFilterUtilisateur)="EmitFilterUtilisateur($event)"
                                                (returnToparent)="getResetUtilisateur($event)"
                                                [Reset]="onResetUtilisateur" >
                                            </app-filtre-user>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div>


                        <!-- <div class="row mb-2" >
                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                                <div class="row w-100 d-flex justify-content-center" >
                                                    <div class="row filtre-spn" >
                                                        <fa-icon class="fa-lg mr-2" [icon]="fa['faUserCheck']"></fa-icon>
                                                        <span>Actions Utilisateurs</span>
                                                    </div>
                                                </div>

                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3" >
                                            <app-filtre-action-user class="w-100"
                                                (onPushFilterActionUtilisateur)="EmitFilterActionUtilisateur($event)"
                                                (returnToparent)="getResetActionUtilisateur($event)"
                                                [Reset]="onResetActionUtilisateur">
                                            </app-filtre-action-user>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div> -->




                        <div class="row mb-2">
                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              <div class="row w-100 d-flex justify-content-center" >
                                                    <div class="row filtre-spn" >
                                                        <fa-icon class="fa-lg mr-2" [icon]="fa['faUserTie']"></fa-icon>
                                                        <span>Client</span>
                                                    </div>
                                                </div>

                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3" >
                                            <app-filtre-prospect class="w-100"  (onPushFilterProspect)="EmitFilterProspect($event)"
                                                (returnToparent)="getResetProspect($event)" [Reset]="onResetProspect"
                                              >
                                            </app-filtre-prospect>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div>


                        <div class="row mb-2">
                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              <div class="row w-100 d-flex justify-content-center" >
                                                   <div class="row filtre-spn" >
                                                      <fa-icon class="fa-lg mr-2" [icon]="fa['faLightbulb']"></fa-icon>
                                                      <span>Statuts actuels</span>
                                                    </div>
                                              </div>

                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3" >
                                            <app-filtre-statut-actuel
                                                (onPushFilterStatutActuel)="EmitFilterStatutActuel($event)" (returnToparent)="getResetStatutActuel($event)" [Reset]="onResetStatutActuel"
                                                 class="w-100">
                                            </app-filtre-statut-actuel>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              <div class="row w-100 d-flex justify-content-center" >
                                                    <div class="row filtre-spn" >
                                                          <fa-icon class="fa-lg mr-2" [icon]="fa['faMedal']"></fa-icon>
                                                          <span>Bloc qualité</span>
                                                    </div>
                                                </div>

                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3" >
                                            <app-bloc-qualite class="w-100"  (onPushFilterBlocQualite)="EmitFilterQualite($event)"
                                            (returnToparent)="getResetQualite($event)" [Reset]="onResetQualite"
                                          >
                                            </app-bloc-qualite>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div>


                        <!-- <div class="row mb-2">
                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              <div class="row w-100 d-flex justify-content-center" >
                                                <div class="row filtre-spn" >
                                                      <fa-icon class="fa-lg mr-2" [icon]="fa['faStar']"></fa-icon>
                                                       <span>Qualité</span>
                                                </div>
                                              </div>

                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3" >
                                            <app-filtre-qualite  class="w-100" (onPushFilterQualite)="EmitFilterQualite($event)"
                                                (returnToparent)="getResetQualite($event)" [Reset]="onResetQualite"
                                               >
                                            </app-filtre-qualite>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div> -->
                    </mat-tab>
                    <mat-tab label="Opportunités">
                        <div class="row mb-2 mt-3" >

                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              <div class="row w-100 d-flex justify-content-center" >
                                                <div class="row filtre-spn" >
                                                    <fa-icon class="fa-lg mr-2" [icon]="fa['faBullhorn']"></fa-icon>
                                                    <span> Opportunités</span>
                                                </div>
                                              </div>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3" >
                                            <app-filter-opportunity-affaire
                                                (onPushFilterOpportunityAffaire)="EmitFilterOpportunityAffaire($event)"
                                                (returnToparent)="getResetOpportunityAffaire($event)"
                                                [Reset]="onResetOpportunityAffaire" class="w-100">
                                            </app-filter-opportunity-affaire>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div>


                        <div class="row mb-2">
                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              <div class="row w-100 d-flex justify-content-center" >
                                                      <div class="row filtre-spn" >
                                                              <fa-icon class="fa-lg mr-2" [icon]="fa['faUser']"></fa-icon>
                                                              <span>Utilisateurs</span>
                                                      </div>
                                                </div>


                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3">
                                            <app-filter-user-opp style="width: 100%;" class="w-100" (returnToparent)="getResetUtilisateurOpp($event)"
                                                [Reset]="onResetUtilisateurOpp"
                                                (onPushFilterUtilisateurOpp)="EmitFilterUtilisateurOpp($event)"
                                               class
                                               >
                                            </app-filter-user-opp>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div>



                        <!-- <div class="row mb-2" >
                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              <div class="row w-100 d-flex justify-content-center" >
                                                  <div class="row filtre-spn" >
                                                      <fa-icon class="fa-lg mr-2" [icon]="fa['faPhone']"></fa-icon>
                                                      <span>Téléphonie</span>
                                                  </div>
                                              </div>
                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3" >
                                            <app-filter-telephonie-opp (returnToparent)="getResetTelephonie($event)"
                                                [Reset]="onResetTelephonie"
                                                (onPushFilterTelephonie)="EmitFilterTelephonie($event)"
                                                class="w-100">
                                            </app-filter-telephonie-opp>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div> -->

                        <div class="row mb-2">
                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              <div class="row w-100 d-flex justify-content-center" >
                                                    <div class="row filtre-spn" >
                                                          <fa-icon class="fa-lg mr-2" [icon]="fa['faLightbulb']"></fa-icon>
                                                          <span>Statuts Actuels</span>
                                                    </div>
                                                </div>

                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3" >
                                            <app-filter-statut-actuel-opp class="w-100"
                                            (returnToparent)="getResetStatutActuelOpp($event)"
                                                [Reset]="onResetStatutActuelOpp"
                                                (onPushFilterStatutActuelOpp)="EmitFilterStatutActuelOpp($event)">
                                            </app-filter-statut-actuel-opp>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div>
                        
                    


                        <!-- <div class="row mb-2" >
                            <div class="col-12">
                                <mat-accordion>
                                    <mat-expansion-panel hideToggle>
                                        <mat-expansion-panel-header>
                                            <mat-panel-title>
                                              <div class="row w-100 d-flex justify-content-center" >
                                                  <div class="row filtre-spn" >
                                                            <fa-icon class="fa-lg mr-2" [icon]="fa['faUserCheck']"></fa-icon>
                                                            <span>Actions Utilisateurs</span>
                                                    </div>
                                                </div>

                                            </mat-panel-title>
                                        </mat-expansion-panel-header>
                                        <div class="row mt-3" >
                                            <app-filter-action-user-opp
                                                (onPushFilterActionUtilisateurOpp)="EmitFilterActionUtilisateurOpp($event)"
                                                (returnToparent)="getResetActionUtilisateurOpp($event)"
                                                [Reset]="onResetActionUtilisateurOpp" class="w-100">
                                            </app-filter-action-user-opp>
                                        </div>
                                    </mat-expansion-panel>

                                </mat-accordion>
                            </div>
                        </div> -->
                    </mat-tab>
                </mat-tab-group>
            </div>
        </div>
    </div>
</form>
