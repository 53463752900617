<div class="row" [ngClass]="{'cover': !allowed_to_update}">
    <div class="col card p-3 border-0">
        <div class="col">
            <div class="card-doc-title ml-3 my-3 row">
                <fa-icon class="mr-2 fa-lg" [icon]="fa['faCommentDots']"></fa-icon>
                Commentaires
            </div>
            <button type="button" class="add-btn-en btn-add-comment-affaire-position position-absolute " *ngIf="!addCommentaire" value="Ajouter un commentaire" (click)="AddComm()">
        <span class="add-bg-btn">
          <fa-icon [icon]="fa['faPlus']"></fa-icon>
        </span>
      </button>
            <ng-container class="p-3" *ngIf="show_loading_add">
                <div class="row mb-3 mt-3">
                    <div class="text-center m-auto">
                        <mat-spinner [diameter]="30"></mat-spinner>
                    </div>
                </div>
            </ng-container>
            <div class="comment scrollbar" [ngClass]="{ 'initial-style': listComments.length < 2 }">
                <div class="row pt-2" *ngIf="listComments.length < 1 && !show_loading_add">
                  <div class="col-12  d-flex justify-content-center">
                    <img src="assets/icons/nodata.svg">
                  </div>
                  <div class="col-12  d-flex justify-content-center">
                    <p class="noData">Pas de données disponibles</p>
                  </div>

          </div>
                <div class=" card-commentaire mt-2 mb-2 mr-1" *ngFor="let comment of listComments , let i = index">
                    <div class="row">
                        <div class="pl-4 text-center mt-3 " *ngIf="comment.user_image">
                            <img src="{{ comment.user_image }}" class="user-photo" />
                        </div>
                        <div class="pl-4 text-center mt-3 " *ngIf="comment.user_image == null">
                            <img src="../../../assets/img/user.png" class="user-photo" />
                        </div>
                        <div class="  pt-3 mw-75 ">

                            <div class="col-12  userName">
                                <p class="m-0">
                                     {{ comment.user_name }}</p>
                            </div>


                            <div class="col-12 small greyColor">
                                <p class="dateComment"> <fa-icon [icon]="fa['faCalendar']"></fa-icon> {{ comment.date | date: 'dd/MM/yyyy HH:mm' }}</p>
                            </div>

                         </div>
                    </div>

                        <div *ngIf="comment.commentaire!=null" class="row  p-2">

                            <div class="col-12">
                                <div class="comment-container">

                                  <p [class.collapsed]="isCollapsed[i]"  [class.expanded]="!isCollapsed[i]">
                                    {{ isCollapsed[i] ? (comment.commentaire | slice: 0:280)  + (comment.commentaire.length > 280 ? "...." : "") : comment.commentaire }}
                                  </p>
                                  <button (click)="toggleReadMore(i)"  *ngIf="comment.commentaire.length > 300" class="read">
                                    {{ isCollapsed[i] ? 'Voir plus' : 'Voir moins' }}
                                  </button>
                                </div>
                              </div>




                        </div>
                        <div class="row pb-2">

                            <div class="col-3   "  [ngClass]="{'col-md-4': addCommentaire}" *ngFor="let doc of comment.docs">
                              <div class="fileComment m-2">
                                <a href="{{doc.doc}}" target="_blank"  >
                                    <div class="row imgBox" *ngIf="doc.doc?.includes('.png') || doc.doc?.includes('.jpeg') || doc.doc?.includes('.jpg')">
                                        <img class="imgStyle " src='{{doc.doc}}' alt="">

                                        <div class="ml-2  links">{{expandName(doc.name)}}</div>
                                    </div>


                                    <div  class="row m-2  " *ngIf="!doc.doc?.includes('.png') && !doc.doc?.includes('.jpeg') && !doc.doc?.includes('.jpg')">

                                      <img class=" my-1" src='../../../assets/img/FileIcon.svg'>
                                          <div class="ml-2 mt-2 links"> {{expandName(doc.name)}}</div>
                                      </div>

                                    <!-- <span  class="small" > <fa-icon class="fa mr-1 blueColor" [icon]="fa['faPaperclip']"></fa-icon><a href="{{doc}}" target="_blank">{{doc.split('/')[doc.split('/').length-1]}}</a></span> -->
                                </a>
                              </div>
                            </div>
                        </div>


                </div>
            </div>
        </div>
    </div>
    <div class="col-4 card ml-2 p-3" *ngIf="addCommentaire">
        <button class="icon2-pos text-white w-100 border-0 position-absolute">
      <div class="row" (click)="AddComm()">
        <fa-icon class=" p-1  fa fa-lg text-white" [icon]="fa['faTimesCircle']"></fa-icon>
        <span class="col mt-1"> Ajouter un Commentaire</span>
      </div>
    </button>

        <form [formGroup]="form">
            <div class="modal-body" class="form-group">
                <textarea name="comment" class="col textComment mt-5" placeholder="Ecrivez Votre Commentaire ici . . . " cols="30" formControlName="comment" rows="5"></textarea>
                <div class="mb-3 col-md-12">
                    <!-- <label for="formFile" class="form-label">Joindre des fichiers</label> -->
                    <!-- <input (change)="this.onFilechange($event)" class="form-control" multiple type="file" id="formFile"> -->
                    <div class="form-group mt-1">
                        <label for="fileField" class="attachment">
              <div class="row btn-file mt-1">
                <div class="btn-file__preview mt-1"></div>
                <div class="btn-file__actions mt-1">
                  <div class="btn-file__actions__item mt-1 col-xs-12 text-center " id="file-upload-container"
                  (dragover)="onDragOver($event)" (drop)="onDrop($event)">
                    <div class="btn-file__actions__item--shadow mt-1">


                      <fa-icon class="fa-icon-size "  [icon]="fa['faUpload']"></fa-icon>&nbsp;

                      <div class="  mt-2"></div>
                      <p class="import">Importez ou faites glisser les fichiers ici pour les ajouter</p>
                    </div>
                  </div>
                </div>
              </div>
              <input (change)="onFilechange($event)"  accept=".pdf, .jpg, .png, .jpeg, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .zip, .odt, .txt"  name="file" type="file" id="fileField">
            </label>
            <p *ngIf="showTypeDoc" class="errorMsg  mt-3">
              <fa-icon class="mr-2 fa-lg" [icon]="fa['faExclamationCircle']"></fa-icon>
              Le type fichier doit être de type pdf, jpg, png, jpeg, doc, docx, ppt, pptx, xls, xlsx, zip, odt, txt
          </p>
                    </div>
                    <div class="row " [ngClass]="{'files  scrollbar': fileList.length>1 }" >
                        <div class="col-md-12 fileComment d-flex  align-items-center mb-2 py-2 " *ngFor="let file of fileList">

                            <button  type="button" (click)="removefile(file)" class="deleteFile">
                              <img  src='../../../assets/icons/deleteIcon.svg'>
                              </button>

                            <img *ngIf="file.types=='image'" class="imgStyle" src='{{file.path}}' alt="">
                            <img *ngIf="file.types=='file'" src='../../../assets/img/FileIcon.svg'>


                           <span class="file-name  links ml-2">{{expandName(file.name)}}  </span>

                        </div>
                    </div>
                    <div *ngIf="loading" class="row">
                      <div class="text-center m-auto">
                          <mat-spinner [diameter]="15"></mat-spinner>
                      </div>
                  </div>
                </div>
            </div>

            <div class="d-flex justify-content-center">
              <button type="button" class="btn-dt-ajouter mr-2" [disabled]="!this.form.value.comment && this.fileList.length===0" [ngClass]="{'disable-btn':!this.form.value.comment && this.fileList.length===0}" (click)="onSubmit()" type="submit">
                <fa-icon class="fa fa-lg" [icon]="fa['faPlusCircle']"></fa-icon>&nbsp; Ajouter
              </button> 
             
                  <button type="button" class="btn-dt-fermer  ml-2"  aria-label="Close"
                  (click)="AddComm()">
            <fa-icon class="fa-icon-style fa-lg " [icon]="fa['faTimesCircle']"></fa-icon>&nbsp;
            Annuler
        </button> 
                
            </div>
            <p class="info mt-3">
                <fa-icon class="mr-2 fa-lg" [icon]="fa['faInfoCircle']"></fa-icon>
                Les informations renseignées ne doivent en aucun cas être des données sensibles,ni porter sur la vie privée.
            </p>
        </form>
    </div>
</div>
