import { ApiConfigurationService } from 'src/app/services/apiConfiguration/api-configuration.service';
import { environment } from 'src/environments/environment';
import { BankInformation } from './../../../../../../entity/BankInformation';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { NotificationType, NotificationMessage } from 'src/app/entity/NotificationMessage';
import { DatePipe } from '@angular/common';
import { EstablishmentInformation } from './../../../../../../entity/Opportunity/EstablishmentInformation';
import { Component, EventEmitter, OnInit, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { Prospect } from '../../../../../../entity/Prospect';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Entreprise } from '../../../../../../entity/Opportunity/Entreprise';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { AdressEmitter } from 'src/app/components/auto-complete-adresses/auto-complete-adresses.component';
import { Country } from '@angular-material-extensions/select-country';

import { FR as France } from '../prospect-pro-form/json-dict/fr';
import { TN as Tunis } from '../prospect-pro-form/json-dict/tn';
import { FormlyFormOptions } from '@ngx-formly/core';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { ProspectCLientService } from 'src/app/services/prospect-client.service';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ProspectFiltredListModalComponent } from '../prospect-filtred-list-modal/prospect-filtred-list-modal/prospect-filtred-list-modal.component';
import { ProspectProFiltredListComponent } from '../prospect-pro-filtred-list/prospect-pro-filtred-list/prospect-pro-filtred-list.component';
class formbuilder {
  data;
  schema;
}
export class BodyConf {
  name: any;
  title: any;
  disabled: boolean = false;
}
export class CustomFormControl {
  formControlName: string;
}
export class chercheBody {
  key: any;
  value: any;
  operateur: any = '=';
}

export class FilterBody {
  nom_commercial: any = '';
  RS: any = '';
  tel: any = '';
  info_com: {} = {};
}
@Component({
  selector: 'app-prospect-pro-form',
  templateUrl: './prospect-pro-form.component.html',
  styleUrls: ['./prospect-pro-form.component.css'],
})
export class ProspectProFormComponent implements OnInit, OnChanges {
  minDate: string;
  @Input() isUpdate?: boolean;
  @Input() allowed_to_update: boolean;
  isfilled = false;
  fa = fa;
  countryLanguage: any;

  step = 0;
  listProspects_str: string[] = [];
  entrepriseForm: FormGroup;
  showError: boolean = false;
  isLoadingNaf: boolean = true;
  isLoadingFormeJuridique: boolean = true;
  FormeJuridique = [
    { value: 'EIRL', nom: 'EIRL' },
    { value: 'EURL', nom: 'EURL' },
    { value: 'SARL', nom: 'SARL' },
    { value: 'SAS', nom: 'SAS' },
    { value: 'SA', nom: 'SA' },
    { value: 'SNC', nom: 'SNC' },
  ];

  Naf = [
    { id: 1, nom: 'Test 1' },
    { id: 2, nom: 'Test 2' },
    { id: 3, nom: 'Test 3' },
    { id: 4, nom: 'Test 4' },
  ];

  @Output() onUpdateEntreprise: EventEmitter<Entreprise> = new EventEmitter();
  @Output() onAddProspect: EventEmitter<any> = new EventEmitter();
  @Output() onDeleteProspect: EventEmitter<number> = new EventEmitter();
  @Output() onUpdateProspect: EventEmitter<{ prospect: Prospect; index: number }> = new EventEmitter();
  @Output() onSelectToFillOldProspect: EventEmitter<string> = new EventEmitter();
  prospectProClone: Entreprise = new Entreprise();
  @Input() entreprise: Entreprise = null;
  @Input() prospectPro: Entreprise = new Entreprise();
  @Input() addProspectBtn: boolean = true;
  @Input() type: number = 1;
  defaultCountry: Country;
  @Input() schemaValue: any;
  AdresseComplement: string = '';
  telCountry = environment.country;

  establishmentInformation: EstablishmentInformation = new EstablishmentInformation();
  prospectSchema: any = null;
  keyFormProspect: string = environment.keyFormProspect;
  keyFormProspectPro: string = environment.keyFormEntreprise;

  form: any;
  model: any = {};
  options: FormlyFormOptions = {};
  fields = [];
  prospectFormnew: formbuilder = new formbuilder();
  listkeys = [
    { name: 'nom_commercial', title: 'Nom Commercial', disabled: false },
    { name: 'RS', title: 'Raison sociale', disabled: false },
    { name: 'tel', title: 'Téléphone', disabled: false },
  ];

  dialogRef: MatDialogRef<ProspectProFiltredListComponent, any>;
  filterBody: FilterBody = new FilterBody();
  @Input() modeAjout;
  constructor(
    private apiOpportunitService: ApiOpportunitService,
    private notificationsService: NotificationsService,
    private _datePipe: DatePipe,
    private apiConfigurationService: ApiConfigurationService,
    private formlyJsonschema: FormlyJsonschema,
    private prospectCLientService: ProspectCLientService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.formInfoHolder.push(new chercheBody());
    const currentDate = new Date();
    this.minDate = this._datePipe.transform(currentDate, 'yyyy-MM-dd');
    if (this.telCountry === 'TN') {
      this.countryLanguage = Tunis.data;
    } else if (this.telCountry === 'FR') {
      this.countryLanguage = France.data;
    }
    if (this.telCountry === 'FR') {
      this.defaultCountry = {
        name: 'France',
        alpha2Code: 'FR',
        alpha3Code: 'FRA',
        numericCode: '250',
        callingCode: '+33',
      };
    } else if (this.telCountry === 'TN') {
      this.defaultCountry = {
        name: 'Tunisie',
        alpha2Code: 'TN',
        alpha3Code: 'TN',
        numericCode: '',
        callingCode: '+216',
      };
    }
    this.fillForm();
    // this.updateEntrepriseInfo();
    this.getConfigurationForm();
    this.getConfigurationFormPro();
    if (this.allowed_to_update === undefined) {
      this.allowed_to_update = true;
    }
    this.buildForm();
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  numberlength(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else if (event.target.value.length === 8) {
      this.entrepriseForm.controls.siret.setValue(event.target.value + event.key + '00001');
      return true;
    } else if (event.target.value.length === 9) {
      return false;
    }
  }

  preventPanelOpen(event: Event): void {
    // Prevent the click event from propagating further, stopping the panel from opening
    event.stopPropagation();
  }

  numberlengthSiret(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } else if (event.target.value.length === 14) {
      return false;
    }
  }

  numberlengthNaf(event): boolean {
    if (event.target.value.length === 5 && this.telCountry === 'FR') return false;
    if (event.target.value.length === 8 && this.telCountry === 'TN') return false;
  }

  async getConfigurationForm(): Promise<void> {
    this.apiConfigurationService.getParamConfig(this.keyFormProspect).subscribe((response) => {
      this.prospectSchema = response.value;
    });
  }

  async getConfigurationFormPro(): Promise<void> {
    this.apiConfigurationService.getParamConfig(this.keyFormProspectPro).subscribe((response) => {
      response.value.schema.properties.forEach((element) => {
        const item: BodyConf = new BodyConf();
        item.name = element.name;
        item.title = element.title;
        item.disabled = false;
        this.listkeys.push(item);
      });
    });
  }

  fillForm() {
    if (this.prospectProClone)
      this.entrepriseForm = new FormGroup({
        id: new FormControl(this.prospectProClone.id),
        //   siren: new FormControl(this.prospectProClone.siren),
        fax: new FormControl(this.prospectProClone.fax),
        form_juridique: new FormControl(this.prospectProClone.form_juridique),
        // siret: new FormControl(this.prospectProClone.siret),
        // naf: new FormControl(this.prospectProClone.naf, [Validators.pattern(this.countryLanguage?.PROSPECT.NAF.REGEX)]),
        effectif: new FormControl(this.prospectProClone.effectif),
        RS: new FormControl(this.prospectProClone.RS, [Validators.required]),
        FJ: new FormControl(this.prospectProClone.FJ),
        mail: new FormControl(this.prospectProClone.mail, [
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ]),
        // nom_commercial: new FormControl(this.prospectProClone.nom_commercial),
        web: new FormControl(this.prospectProClone.web, [
          Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?\\.([a-z.]{2,6})[/\\w .-]*/?'),
        ]),
        tel: new FormControl(this.prospectProClone.tel),
        convention_collective: new FormControl(this.prospectProClone.convention_collective, [
          Validators.pattern('^[a-zA-Z0-9 ]+$'),
        ]),
        respensable: new FormControl(this.prospectProClone.respensable),
        tranche_effectifs: new FormControl(this.prospectProClone.tranche_effectifs),
        numero_tva_intra: new FormControl(this.prospectProClone.numero_tva_intra),
        date_creation: new FormControl(this.prospectProClone.date_creation),
        streetNumber: new FormControl(this.prospectProClone.adress?.streetNumber),
        streetName: new FormControl(this.prospectProClone.adress?.streetName),
        CP: new FormControl(this.prospectProClone?.adress?.CP),
        ville: new FormControl(this.prospectProClone?.adress?.ville),
        //  adress: new FormControl(),
      });
    const codePostal: string =
      this.prospectProClone?.adress?.CP == null
        ? this.prospectProClone?.adress?.postalCode
        : this.prospectProClone?.adress?.CP;
    this.entrepriseForm.controls.CP.setValue(codePostal);
  }

  reset() {
    this.prospectProClone = new Entreprise();
    this.fillForm();
    this.updateEntrepriseInfo();
  }

  prospectFinder(index) {
    this.onSelectToFillOldProspect.emit(index);
  }

  addProspect() {
    this.onAddProspect.emit('add');
  }

  deleteProspect(index) {
    // this.onDeleteProspect.emit(index);
    if (this.prospectPro.listProspectsLier[index - 1].id) {
      this.prospectPro.listProspectsLier[index - 1].deleted = true;
      this.updateProspect(this.prospectPro.listProspectsLier[index - 1], index - 1);
    } else {
      if (this.isUpdate) this.prospectPro.listProspectsLier.splice(index - 1, 1);
      else this.onDeleteProspect.emit(index - 1);
    }
    // this.store$.dispatch(addOppActions.DeleteProspectAction({ payload: index }));
  }

  updateEntrepriseInfo() {
    const entreprise: Entreprise = new Entreprise(this.entrepriseForm.value);

    const jsonnn = JSON.parse(JSON.stringify(this.model));
    const objCopy = { ...entreprise.form };
    objCopy.data = jsonnn;
    entreprise.form = objCopy;

    entreprise.adress.streetName = this.entrepriseForm.value.streetName ? this.entrepriseForm.value.streetName : '';
    entreprise.adress.streetNumber = this.entrepriseForm.value.streetNumber
      ? this.entrepriseForm.value.streetNumber
      : '';
    entreprise.adress.ville = this.entrepriseForm.value.ville ? this.entrepriseForm.value.ville : '';
    entreprise.adress.postalCode = this.entrepriseForm.value.CP ? this.entrepriseForm.value.CP : '';
    entreprise.invalidForms = this.findInvalidControls();
    entreprise.type_opportunite = 'entrepise';
    this.onUpdateEntreprise.emit(entreprise);
  }

  updateProspect($event: Prospect, index: number) {
    // if (this.isUpdate) index++;
    this.onUpdateProspect.emit({ prospect: $event, index: index });
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.entrepriseForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prospectPro = new Entreprise();
    this.prospectPro.mainProspect = this.entreprise.mainProspect;
    this.prospectPro.listProspectsLier = this.entreprise.listProspectsLier;
    this.prospectPro = this.entreprise;
    this.prospectProClone = JSON.parse(JSON.stringify(this.prospectPro));

    this.model = this.prospectPro.form.data;

    this.fillForm();

    if (changes?.schemaValue?.currentValue?.schema) {
      this.prospectFormnew = this.schemaValue = changes?.schemaValue?.currentValue;
      this.buildForm();
      if (!this.prospectFormnew || Object.keys(this.prospectFormnew).length === 0) {
        this.isfilled = false;
      }
      if (this.fields[0].fieldGroup.length > 0) {
        this.isfilled = true;
      }
    }
  }

  hasError($event: boolean) {}

  getNumber($event: any, tel: string) {
    this.entrepriseForm?.controls.tel.setValue($event);
  }

  isValid(field: string) {
    if (field === 'RS' && this.entrepriseForm?.controls[field].value != null) {
      this.entrepriseForm?.controls[field].setValue(this.entrepriseForm?.controls[field].value.trimStart());
    }
    return this.entrepriseForm?.controls[field].invalid;
  }

  autoRemplirProspectPro(siren?: number): void {
    if (siren != null && siren?.toString()?.length < 9) {
      this.deleteProspectProchamps();
    } else if (siren?.toString()?.length === 9) {
      this.apiOpportunitService.GetEstablishmentInformations(siren).subscribe(
        (data: EstablishmentInformation) => {
          this.establishmentInformation = data;
          this.entrepriseForm.controls.siret.setValue(data.unite_legale.etablissement_siege.siret);
          this.entrepriseForm.controls.tranche_effectifs.setValue(data.unite_legale.tranche_effectifs);
          this.entrepriseForm.controls.date_creation.setValue(data.unite_legale.date_creation);
          // this.entrepriseForm.controls.numero_tva_intra.setValue(data.unite_legale.numero_tva_intra);
        },
        () => {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.info;
          notificationMessage.title = 'Aucun SIREN trouvé avec ce numéro:';
          notificationMessage.message = siren.toString();
          this.notificationsService.sendMessage(notificationMessage);
        }
      );
    }
  }

  deleteProspectProchamps(): void {
    this.entrepriseForm.controls.siret.setValue(null);
    this.entrepriseForm.controls.tranche_effectifs.setValue(null);
    this.entrepriseForm.controls.date_creation.setValue(null);
    this.entrepriseForm.controls.numero_tva_intra.setValue(null);
  }

  creationDateChanged(): void {
    this.entrepriseForm.controls.date_creation.setValue(
      this._datePipe.transform(this.entrepriseForm.controls.date_creation.value, 'yyyy-MM-dd')
    );
  }

  /**
   * Permets de détecter le pays
   * @param object
   */
  telInputObject(object): void {
    object.setCountry(object.j);
  }

  /**
   * Permets de retourner les informations bancaire saisie
   * @param $event
   */
  getBankInformation($event: BankInformation): void {
    this.entreprise.information_bancaire = $event;
    this.onUpdateEntreprise.emit(this.entreprise);
  }

  onChangeAdresseComplement(valeur: string) {
    this.AdresseComplement = valeur;
  }

  onChangeAdresseSaisieAuto(adress: AdressEmitter) {
    this.entrepriseForm.controls.CP.setValue(adress.postal_code);
    this.entrepriseForm.controls.streetName.setValue(adress.route);
    this.entrepriseForm.controls.streetNumber.setValue(adress.street_number);
    this.entrepriseForm.controls.ville.setValue(adress.locality);
    this.updateEntrepriseInfo();
  }

  buildForm(): void {
    this.prospectFormnew = this.schemaValue;

    this.form = new FormGroup({});
    if (this.prospectFormnew != null) {
      this.options = {};
      if (this.prospectFormnew.schema) {
        const data = this.prospectFormnew;
        const formPropreties: any = data?.schema?.properties;

        if (formPropreties?.length > 0) {
          data.schema.properties = {};
          formPropreties.forEach((element) => {
            data.schema.properties[element.name] = element;
            if (this.model[element.name] && Object.values(this.model).length === 0)
              this.model[element.name] = element.default;
          });
        }

        if (data?.data)
          for (const [key, value] of Object.entries(data?.data)) {
            if (value != null) this.model[key] = value;
          }
        if (data.schema.properties)
          this.fields = [this.formlyJsonschema.toFieldConfig(JSON.parse(JSON.stringify(data.schema)))];
      }
    }
  }

  isDropdownVisible = true;

  stopClickPropagation(event: Event) {
    event.stopPropagation();
  }

  showhidePop() {
    this.isDropdownVisible = !this.isDropdownVisible;
    this.clearModal();
  }

  showhidePopfirst() {
    if (this.isDropdownVisible === false) {
      this.isDropdownVisible = true;
    }
  }

  formInfoHolder: chercheBody[] = [];

  onAdd() {
    const item = new chercheBody();
    this.formInfoHolder.push(item);
  }

  deleteSearch(i: number) {
    this.formInfoHolder.splice(i, 1);
    this.SelectedOptionDisabled();
    this.updateFilterBody();
  }

  SelectedOptionDisabled() {
    this.listkeys.forEach((item) => {
      const isDisabled = this.formInfoHolder.some((element) => element.key === item.name);
      item.disabled = isDisabled;
    });
  }

  setValues(index: number, property: string, event: any): void {
    const selectedValue = event;
    switch (property) {
      case 'key':
        this.formInfoHolder[index].key = selectedValue;
        break;
      case 'operateur':
        this.formInfoHolder[index].operateur = selectedValue;
        break;
      case 'value':
        this.formInfoHolder[index].value = selectedValue.target.value;
        break;
      default:
        break;
    }
    this.SelectedOptionDisabled();
    this.updateFilterBody();
  }

  updateFilterBody(): void {
    this.filterBody = new FilterBody();
    this.formInfoHolder.forEach((item) => {
      switch (item.key) {
        case 'RS':
          this.filterBody.RS = item.value;
          break;
        case 'nom_commercial':
          this.filterBody.nom_commercial = item.value;
          break;
        case 'tel':
          this.filterBody.tel = item.value;
          break;
        default:
          this.filterBody.info_com[item.key] = item.value;
          break;
      }
    });
  }

  clearModal() {
    this.formInfoHolder = [new chercheBody()];
  }

  OnOpen() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50%';
    dialogConfig.data = {
      filterBody: this.filterBody,
    };
    this.dialogRef = this.dialog.open(ProspectProFiltredListComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((data: Entreprise) => {
      //  this.prospectPro.mainProspect = data;

      if (data) this.setData(data);
    });
  }

  loaderInfo: boolean = false;
  setData(data: Entreprise) {
    if (data.id) {
      this.loaderInfo = true;
      this.prospectCLientService.GetProspectProByID(data.id).subscribe(
        (data) => {
          this.loaderInfo = false;
          this.prospectProClone = data.data;
          this.prospectPro = data.data;
          this.fillForm();
          this.model = this.prospectProClone.form.data;
          this.updateEntrepriseInfo();
          this.clearModal();
          this.SelectedOptionDisabled();
        },
        (erro) => {
          this.loaderInfo = false;
          this.SelectedOptionDisabled();
        }
      );
    }
  }
}
