<div class="row" style="margin-top: 15px">

  <div class="col-6">
    <div class="form-group">
      <mat-form-field style="width: 100%;">
        <input (change)="EmitFilterProspec()"  [(ngModel)]="prospect.nom_prenom"
          matInput placeholder="Nom & Prénom" type="text">
      </mat-form-field>

    </div>
  </div>
  <div class="col-6">
    <div class="form-group">
      <mat-form-field style="width: 100%;">
        <!-- pattern="[0-9]{9}" -->
        <input (change)="EmitFilterProspec()"   name="phone"
          title="Three letter country code" [(ngModel)]="prospect.telephone" matInput placeholder="Téléphone"
          type="number" #phone="ngModel">
      </mat-form-field>
      <div *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="message-erreur">
        <div *ngIf="phone.errors.pattern">
          <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>&nbsp;Téléphone doit contenir 9 chiffres
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <div class="form-group">
      <mat-form-field style="width: 100%;">
        <input (change)="EmitFilterProspec()" 
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" [(ngModel)]="prospect.adresse_mail" name="mail"
          #mail="ngModel" matInput placeholder="Email" type="text">
      </mat-form-field>
      <div *ngIf="mail.invalid && (mail.dirty || mail.touched)" class="message-erreur">

        <div *ngIf="mail.errors.pattern">
          <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>&nbsp;Format invalide !
        </div>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="form-group">
      <mat-form-field style="width: 100%;">
        <input (change)="EmitFilterProspec()"  [(ngModel)]="prospect.ville_CP"
          matInput placeholder="Ville/CP" type="text">
      </mat-form-field>

    </div>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <div class="form-group">
        <mat-form-field style="width: 100%;">
            <input (change)="EmitFilterProspec()" [(ngModel)]="prospect.RS" matInput
                placeholder="Raison sociale" type="text">
        </mat-form-field>
    </div>
</div>
  <div class="col-6">
    <div class="form-group">
      <ng-container >
        <mat-form-field style="width: 100%;">
          <mat-label>Mots-clés</mat-label>
          <ng-container class="p-3" *ngIf="allTagsPros?.length === 0">
            <div class="row ">
                <div class="text-center m-auto">
                    <mat-spinner [diameter]="15"></mat-spinner>
                </div>
            </div>
        </ng-container>
          <mat-chip-list #tagsPros>
            <mat-chip *ngFor="let tags of ListTagsPros" [selectable]="selectable" [removable]="removable"
              (removed)="removeTagsProspect(tags)" (removed)="EmitFilterProspec()">
              {{tags}}
              <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
            </mat-chip>
            <input (click)="EmitFilterProspec()" placeholder="Selectionner..." #TagsProsInput
              [formControl]="tagsProsFromCtrl" [matAutocomplete]="autoTagsPros" [matChipInputFor]="tagsPros"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoTagsPros="matAutocomplete" (optionSelected)="selectedTagsProspect($event)">
            <mat-option *ngFor="let tags of filteredTagsPros | async" [value]="tags.id">
              {{tags.libelle}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
  <!-- <div class="col-6">
    <div class="form-group">
      <ng-container >
        <mat-form-field style="width: 100%;">
          <mat-label>Zone</mat-label>
          <ng-container class="p-3" *ngIf="allZones?.length === 0">
            <div class="row ">
                <div class="text-center m-auto">
                    <mat-spinner [diameter]="15"></mat-spinner>
                </div>
            </div>
        </ng-container>
          <mat-chip-list #Zone>
            <mat-chip *ngFor="let zone of ListZones" [selectable]="selectable" [removable]="removable"
              (removed)="removeZones(zone)" (removed)="EmitFilterProspec()">
              {{zone}}
              <fa-icon matChipRemove *ngIf="removable" style="color: gray;" [icon]="fa['faTimes']"></fa-icon>
            </mat-chip>
            <input (click)="EmitFilterProspec()" placeholder="Selectionner Zone..." #ZonesInput
              [formControl]="zoneFromCtrl" [matAutocomplete]="autoZones" [matChipInputFor]="Zone"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes">
          </mat-chip-list>
          <mat-autocomplete #autoZones="matAutocomplete" (optionSelected)="selectedZones($event)">
            <mat-option *ngFor="let zone of filteredZones | async" [value]="zone.id">
              {{zone.nom}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-container>
    </div>
  </div> -->
</div>