import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ChangeDetectorRef,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterProspectTicketComponent } from '../filter-prospect-ticket/filter-prospect-ticket.component';
import { FilterReclamationTicketComponent } from '../filter-reclamation-ticket/filter-reclamation-ticket.component';
import { FilterTicketTicketComponent } from '../filter-ticket-ticket/filter-ticket-ticket.component';
import { FilterUtilisateursTicketComponent } from '../filter-utilisateurs-ticket/filter-utilisateurs-ticket.component';
import {
  faSearch,
  faPlus,
  faCalendar,
  faChartBar,
  faChartPie,
  faBriefcase,
  faUserTie,
  faFileAlt,
  faPhoneSquareAlt,
  faUser,
  faUserEdit,
  faFile,
  faCheckCircle,
  faSyncAlt,
  faFilter,
  faRedoAlt,
} from '@fortawesome/free-solid-svg-icons';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { FilterTicket, Prospect, Ticket, Utilisateur } from 'src/app/entity/TicketsAndSupport/FilterTicket';
import { FilterHistoryService } from 'src/app/services/ApiFilterSave/filter-history.service';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';

@Component({
  selector: 'app-filtres-ticket',
  templateUrl: './filtres-ticket.component.html',
  styleUrls: ['./filtres-ticket.component.css'],
})
export class FiltresTicketComponent implements OnInit , OnChanges {
  faSearch = faSearch;
  faPlus = faPlus;
  faCalendar = faCalendar;
  faChartBar = faChartBar;
  faChartPie = faChartPie;
  faBriefcase = faBriefcase;
  faUserTie = faUserTie;
  faFileAlt = faFileAlt;
  faFile = faFile;
  faPhoneSquareAlt = faPhoneSquareAlt;
  faUser = faUser;
  faUserEdit = faUserEdit;
  faCheckCircle = faCheckCircle;
  faSyncAlt = faSyncAlt;
  fa = fa;
  faFilter = faFilter;
  faRedoAlt = faRedoAlt;
  filterTickets: FilterTicket = new FilterTicket();
  panelOpenState = false;
  collapseFilterBox: boolean = true;
  @Input() filterSsearch;
  @Output() onPushFilters: EventEmitter<FilterTicket> = new EventEmitter();
  @Output() onSubmitFilter: EventEmitter<any> = new EventEmitter();

  onResetPros: boolean = false;
  onResetUtil: boolean;
  onResetTickets: boolean = false;
  // onResetReclamations: boolean = false;
  detectReset: boolean = false;
  detect: boolean = false;
  @ViewChild(FilterProspectTicketComponent) FilterProspectTicketComponent: FilterProspectTicketComponent;
  @ViewChild(FilterReclamationTicketComponent) FilterReclamationTicketComponent: FilterReclamationTicketComponent;
  @ViewChild(FilterTicketTicketComponent) FilterTicketTicketComponent: FilterTicketTicketComponent;
  @ViewChild(FilterUtilisateursTicketComponent) FilterUtilisateursTicketComponent: FilterUtilisateursTicketComponent;
  filterParamString: string = null;
  collectionParamString: string = null;

  @Output() collapseCloseToparent: EventEmitter<boolean> = new EventEmitter();
  @Input()collapseFromParent : boolean = false;

  constructor(
    private datePipe: DatePipe,
    private cdRef: ChangeDetectorRef,
    private notificationsService: NotificationsService,
    private apiFIlterHistoryService: FilterHistoryService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((queryParams) => {
      this.filterParamString = queryParams.get('filter');
      this.collectionParamString = queryParams.get('collection');
      if (this.filterParamString !== null && this.collectionParamString !== null) {
        if (!this.filterParamString || !this.collectionParamString) {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = 'Opération echoué';
          notificationMessage.message = "La valeur du filtre n'est pas un nombre valide.";
          this.notificationsService.sendMessage(notificationMessage);
        } else {
          this.getFilterByID(this.filterParamString, this.collectionParamString);
          this.collapseFilterBox = true;
        }
        this.collapseFilterBox = false;
      }
    });
  }

  toggleFilterCorp() {
    this.collapseFilterBox = !this.collapseFilterBox;
    this.collapseCloseToparent.emit(false);

  }

  closefilter(event) {
    this.collapseFilterBox = !this.collapseFilterBox;
  }

  getFilterByID(IDFilter, IDCollection) {
    const unsubscribeFilterSave = this.apiFIlterHistoryService.getFiltrebyID(IDFilter, IDCollection).subscribe(
      (response) => {
        this.getFilterFromCollection(response.input);
      },
      (error) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = 'Opération echoué';
        notificationMessage.message = error.error.message;
        this.notificationsService.sendMessage(notificationMessage);
      }
    );
  }

  resetForm() {
    this.router.navigate([], {
      queryParams: {
        filter: null,
        collection: null,
      },
      queryParamsHandling: 'merge',
    });
    localStorage.removeItem('savedFilter');
    localStorage.removeItem('savedCollection');
    this.onResetPros = true;
    this.onResetUtil = true;
    // this.onResetReclamations = true;
    this.onResetTickets = true;
    this.detectReset = true;
    this.cdRef.detectChanges();
    this.onSubmitFilter.emit('reset');
    this.toggleFilterCorp();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterSsearch) {
      if (this.filterSsearch === true) {
        this.onResetPros = true;
        this.onResetUtil = true;
        this.onResetTickets = true;
        this.cdRef.detectChanges();
      }
    }
    if(this.collapseFromParent){
      this.collapseFilterBox = true;
    }
  }

  getResetPros($event) {
    this.onResetPros = $event;
  }

  getResetUtil($event) {
    this.onResetUtil = $event;
  }

  // getResetReclamations($event) {
  //   this.onResetReclamations = $event;
  // }

  getResetTickets($event) {
    this.onResetTickets = $event;
  }

  EmitFilters() {
    this.onPushFilters.emit(this.filterTickets);
  }

  EmitFilterUtilisateur($event: Utilisateur) {
    this.filterTickets.utilisateur = $event;
    this.EmitFilters();
  }

  EmitFilterProspec($event: Prospect) {
    this.filterTickets.prospect = $event;
    this.EmitFilters();
  }

  EmitFilterTickets($event: Ticket) {
    this.filterTickets.ticket = $event;
    this.detect = true;
    this.EmitFilters();
  }

  getFilterFromCollection($event) {
    localStorage.setItem('filtre', JSON.stringify($event));
    this.FilterProspectTicketComponent?.ngOnInit();
    this.FilterReclamationTicketComponent?.ngOnInit();
    this.FilterTicketTicketComponent?.ngOnInit();
    this.FilterUtilisateursTicketComponent?.ngOnInit();

    this.onPushFilters.emit($event);
    this.submitedFilter();
  }

  submitedFilter() {
    if (this.detect) {
      this.onSubmitFilter.emit('ticket');
      this.detect = false;
    } else {
      this.onSubmitFilter.emit('submit');
    }
    this.toggleFilterCorp();
  }

  submitFilterEnter(event: any) {
    if (event.keyCode === 13) {
      this.onSubmitFilter.emit('');
      this.toggleFilterCorp();
    }
  }
}
