import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  faSearch,
  faPlus,
  faCalendar,
  faChartBar,
  faChartPie,
  faBriefcase,
  faUserTie,
  faFileAlt,
  faPhoneSquareAlt,
  faUser,
  faUserEdit,
  faFile,
  faCheckCircle,
  faSyncAlt,
  faFilter,
  faBookmark,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import * as fa from '@fortawesome/free-solid-svg-icons';

import { FilterEcheanceEcheanceComponent } from '../filter-echeances/filter-echeance-echeance/filter-echeance-echeance.component';
import { FiltreAffaireComponent } from '../filter-echeances/filtre-affaire/filtre-affaire.component';
import { FiltreEcheancierComponent } from '../filter-echeances/filtre-echeancier/filtre-echeancier.component';

import { FilterEcheances } from 'src/app/entity/FilterEcheances';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { FilterHistoryService } from 'src/app/services/ApiFilterSave/filter-history.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
@Component({
  selector: 'app-filter-echeances',
  templateUrl: './filter-echeances.component.html',
  styleUrls: ['./filter-echeances.component.css'],
})
export class FilterEcheancesComponent implements OnInit, OnChanges {
  collapseFilterBox: boolean = true;
  faSearch = faSearch;
  faPlus = faPlus;
  faCalendar = faCalendar;
  faChartBar = faChartBar;
  faChartPie = faChartPie;
  faBriefcase = faBriefcase;
  faUserTie = faUserTie;
  faFileAlt = faFileAlt;
  faFile = faFile;
  faPhoneSquareAlt = faPhoneSquareAlt;
  faUser = faUser;
  faUserEdit = faUserEdit;
  faCheckCircle = faCheckCircle;
  faSyncAlt = faSyncAlt;
  faFilter = faFilter;
  faBookmark = faBookmark;
  faCheck = faCheck;
  fa = fa;

  @Input() resetAllFilter: boolean;
  @Output() onPushFilters: EventEmitter<FilterEcheances> = new EventEmitter();
  @Output() onSubmitFilter: EventEmitter<any> = new EventEmitter();
  @Output() returnToparent = new EventEmitter<boolean>();
  @ViewChild(FilterEcheanceEcheanceComponent) FilterEcheanceEcheanceComponent: FilterEcheanceEcheanceComponent;
  @ViewChild(FiltreAffaireComponent) FiltreAffaireComponent: FiltreAffaireComponent;
  @ViewChild(FiltreEcheancierComponent) FiltreEcheancierComponent: FiltreEcheancierComponent;
  Allfilter: FilterEcheances = new FilterEcheances();
  resetAffaire: boolean = false;
  resetEcheance: boolean = false;
  resetEcheancier: boolean = false;
  filterParamString: string = null;
  collectionParamString: string = null;
  detect: boolean = false;
  @Output() collapseCloseToparent: EventEmitter<boolean> = new EventEmitter();
  @Input()collapseFromParent : boolean = false;

  constructor(
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private apiFIlterHistoryService: FilterHistoryService,
    private cdRef: ChangeDetectorRef,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((queryParams) => {
      this.filterParamString = queryParams.get('filter');
      this.collectionParamString = queryParams.get('collection');
      if (this.filterParamString !== null && this.collectionParamString !== null) {
        if (!this.filterParamString || !this.collectionParamString) {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = 'Opération echoué';
          notificationMessage.message = "La valeur du filtre n'est pas un nombre valide.";
          this.notificationsService.sendMessage(notificationMessage);
        } else {
          this.getFilterByID(this.filterParamString, this.collectionParamString);
          this.collapseFilterBox = true;
        }
        this.collapseFilterBox = false;
      }
    });
  }

  getFilterByID(IDFilter, IDCollection) {
    const unsubscribeFilterSave = this.apiFIlterHistoryService.getFiltrebyID(IDFilter, IDCollection).subscribe(
      (response) => {
        this.getFilterFromCollection(response.input);
      },
      (error) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = 'Opération echoué';
        notificationMessage.message = error.error.message;
        this.notificationsService.sendMessage(notificationMessage);
      }
    );
  }

  getFilterFromCollection($event) {
    localStorage.setItem('filterEcheance', JSON.stringify($event));
    this.FilterEcheanceEcheanceComponent.ngOnInit();
    this.FiltreAffaireComponent.ngOnInit();
    this.FiltreEcheancierComponent.ngOnInit();
    this.onPushFilters.emit($event);
    this.submitFilter();
  }

  toggleFilterCorp() {
    this.collapseFilterBox = !this.collapseFilterBox;
    this.collapseCloseToparent.emit(false);

  }

  closefilter(event) {
    this.collapseFilterBox = !this.collapseFilterBox;
  }

  submitFilterEnter(event: any) {
    if (event.keyCode === 13) {
      //  this.onSubmitFilter.emit('');
      this.toggleFilterCorp();
    }
  }

  EmitFilterAffaire(event) {
    this.Allfilter.organisme = event.organisme;
    this.Allfilter.operateur_organisme = event.operateur_organisme;
    this.Allfilter.ligne_de_produit = event.ligne_de_produit;
    this.Allfilter.operateur_ligne_de_produit = event.operateur_ligne_de_produit;
    this.Allfilter.gamme = event.gamme;
    this.Allfilter.operateur_gamme = event.operateur_gamme;
    this.EmitFilters();
  }

  EmitFilterEcheance(event) {
    this.detect = true;
    this.Allfilter.date_prelevement = event.date_prelevement;
    this.Allfilter.status_echeances = event.status_echeances;
    this.Allfilter.operateur_status_echeances = event.operateur_status_echeances;
    this.EmitFilters();
  }

  EmitFilterEcheancier(event) {
    this.Allfilter.date_debut_periode = event.date_debut_periode;
    this.Allfilter.date_fin_periode = event.date_fin_periode;
    this.Allfilter.motif = event.motif;
    this.Allfilter.operateur_motif = event.operateur_motif;
    this.Allfilter.status_echeanciers = event.status_echeanciers;
    this.Allfilter.operateur_status_echeanciers = event.operateur_status_echeanciers;
    this.EmitFilters();
  }

  EmitFilters() {
    this.onPushFilters.emit(this.Allfilter);
  }

  submitFilter() {
    if (this.detect) {
      this.onSubmitFilter.emit('echeance');
      this.detect = false;
    } else {
      this.onSubmitFilter.emit('submit');
    }
    this.toggleFilterCorp();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.resetAllFilter?.currentValue === true) {
      this.resetAffaire = true;
      this.resetEcheance = true;
      this.resetEcheancier = true;
      this.cdRef.detectChanges();
    } 
    
    if(this.collapseFromParent){
      this.collapseFilterBox = true;
    }
  }

  resetForm() {
    this.router.navigate([], {
      queryParams: {
        filter: null,
        collection: null,
      },
      queryParamsHandling: 'merge',
    });
    localStorage.removeItem('savedFilter');
    localStorage.removeItem('savedCollection');
    this.resetAffaire = true;
    this.resetEcheance = true;
    this.resetEcheancier = true;
    this.cdRef.detectChanges();
    this.onSubmitFilter.emit('reset');
    this.toggleFilterCorp();
    this.returnToparent.emit(false);
  }

  getResetEcheancier(event) {
    this.resetEcheancier = event;
  }

  getResetEcheance(event) {
    this.resetEcheance = event;
  }

  getResetAffaire(event) {
    this.resetAffaire = event;
  }
}
