<div class=" card-historiques  bg-white p-4" [ngClass]="{'cover': !allowed_to_update}"*ngIf="histAccess" >
  <div class="card-doc-title ml-3 row" >
    <fa-icon class="mr-2 fa-lg"  [icon]="fa['faHistory']" ></fa-icon>
    Historique des Actions
  </div>
  <mat-form-field class="w-100 p-3">
    <input matInput (keyup)="applyFilter($event)" placeholder="Recherche..." #input>
  </mat-form-field>
  <div class="mat-elevation-z8">
    <table mat-table class="w-100" [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Date de l’action</th>
        <td mat-cell *matCellDef="let row" > {{row.date}} </td>
      </ng-container>
      <ng-container matColumnDef="commentaire">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Commentaire standard </th>
        <td mat-cell *matCellDef="let row" > {{row.commentaire}} </td>
      </ng-container>
      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Utilisateur </th>
        <td mat-cell *matCellDef="let row" > {{row.mail}} </td>
      </ng-container>
      <ng-container matColumnDef="commentaireD">
        <th mat-header-cell *matHeaderCellDef mat-sort-header > Commentaire détaillé </th>
        <td mat-cell *matCellDef="let row" > {{row.comm_opp}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="7"><div class="col-12 mt-2 d-flex justify-content-center">
          <img src="assets/icons/nodata.svg">

        </div>
        <div class="col-12  d-flex justify-content-center">
          <p class="noData">Pas de données disponibles</p>
        </div> </td>
      </tr>
    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div>
<hr>
<div>
  <app-historique-communication *ngIf="histCommAccess"></app-historique-communication>
</div>
