import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { EcheancierService } from 'src/app/services/ApiEcheancier/echeancier.service';
import { map, startWith } from 'rxjs/operators';
import { FilterEcheances } from 'src/app/entity/FilterEcheances';
import { DatePipe } from '@angular/common';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { RangeDateComponent } from 'src/app/components/rangeDate/range-date/range-date.component';

class Status{
  id : string;
  libelle : string;
}
@Component({
  selector: 'app-filter-echeance-echeance',
  templateUrl: './filter-echeance-echeance.component.html',
  styleUrls: ['./filter-echeance-echeance.component.css']
})
export class FilterEcheanceEcheanceComponent implements OnInit , OnChanges {
  filterEcheance: FilterEcheances = new FilterEcheances();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Output() onPushFilterEcheance: EventEmitter<FilterEcheances> = new EventEmitter();
  readonly rangeDatePrelevement = RangeDateComponent;

    fa = fa;
  /** status */
  _unsubscribeAll: Subscription[]=[];
  listeAllStatus: Status [] = [];;
  selectable = true;
  removable = true;
  filteredStatus: Observable<any[]>;
  ListStatus: string[] = [];
  listStatusSelected: string[] = [];
  @ViewChild('statusInput') statusInput: ElementRef<HTMLInputElement>;
StatusFromCtrl: FormControl = new FormControl('');
@ViewChild('pickerDC') pickerDC: MatDateRangePicker<any>; 
/** date de prelevment */
date_debut_prelevement : any ="";
date_fin_prelevement: any ="";

isReadOnly = false;
@Input() resetEcheance : boolean;
@Output() returnToparent = new EventEmitter<boolean>();

constructor( private echeancierService : EcheancierService,
  private datePipe: DatePipe,
  ) { }


ngOnInit(): void {
this.listStatus()
  this.filteredStatus = this.StatusFromCtrl.valueChanges.pipe(
    startWith(null),
    map((product: string | null) => (product ? this._filter() : this.listeAllStatus.slice()))
  );  
}

openDateprelevement() {
  if (this.pickerDC) {
    this.pickerDC.open();
  }

}

clearDateprelevement(){
 
   this.date_debut_prelevement = null;
   this.date_fin_prelevement = null;
   this.filterEcheance.date_prelevement = []
   this.EmitFilterEcheance()
 }



  listStatus(){
    this.echeancierService
    .listeAllStatusEcheance().subscribe((response)=>{
  this.listeAllStatus = response.status;
    }); 

  }

  selectOptionStatut(option: string): void {
    this.filterEcheance.operateur_status_echeances= option;
    this.EmitFilterEcheance()
  }


   selectedStatus(event: MatAutocompleteSelectedEvent): void {
    if (!this.listStatusSelected.includes(event.option.value)) {
    this.ListStatus.push(event.option.viewValue);
    this.listStatusSelected.push(event.option.value);}
    this.statusInput.nativeElement.value = '';
    this.StatusFromCtrl.setValue(null);
    this.EmitFilterEcheance()
  }

  removeStatus(product: string): void {
    const index = this.ListStatus.indexOf(product);
    if (index >= 0) {
      this.ListStatus.splice(index, 1);
      this.listStatusSelected.splice(index, 1);
    }

  } 

   private _filter(): any[] {
     let filterValue = this.StatusFromCtrl.value.toString().toLowerCase();
     if (filterValue === null) filterValue = '';
     return this.listeAllStatus.filter((status) => status.libelle.toLowerCase().includes(filterValue));
   } 


   EmitFilterEcheance() {
    if (this.listStatusSelected.length===0){
      this.filterEcheance.operateur_status_echeances="="
    }
    let dateDebut = "";
    let dateFin = "";
    if (this.date_debut_prelevement !=="" && this.date_debut_prelevement !== null){
    dateDebut =this.datePipe.transform(this.date_debut_prelevement, 'yyyy-MM-dd');  
    } else{
      this.filterEcheance.date_prelevement = []  
    } 
    if (this.date_fin_prelevement!=="" && this.date_fin_prelevement !== null){
      dateFin= this.datePipe.transform(this.date_fin_prelevement, 'yyyy-MM-dd');
      } else{
        this.filterEcheance.date_prelevement =[]
      }
 if ( dateFin !== "" && dateDebut !== "" ) this.filterEcheance.date_prelevement = [dateDebut , dateFin]

    this.filterEcheance.status_echeances = this.listStatusSelected;  
    this.onPushFilterEcheance.emit(this.filterEcheance);

   }

  

  ngOnChanges(changes: SimpleChanges) {
    this.resetEcheanceForm(changes.resetEcheance.currentValue);
  }

  resetEcheanceForm(Reset) {
    if (Reset === true) {
       

      this.filterEcheance.status_echeances = this.listStatusSelected= [];
      this.ListStatus = [];
      this.filterEcheance.operateur_status_echeances = '=';
      this.date_debut_prelevement = "";
      this.date_fin_prelevement = "";
      this.filterEcheance.date_prelevement = []
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterEcheance.emit(this.filterEcheance);


    }
  }

}

