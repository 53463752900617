<div class="row  mt-2" >

    <div class="col-6">
        <div class="form-group">
            <mat-form-field class="w-100">
                <input (change)="EmitFilterDestinataire()"
                    [(ngModel)]="filterDestinataire.nom_prenom_destinataire" matInput
                    placeholder="Nom & Prénom destinataire" type="text">
            </mat-form-field>

        </div>
    </div>
    <div class="col-6">
        <div class="form-group">
            <mat-form-field class="w-100">
                <input  (change)="EmitFilterDestinataire()"
                    [(ngModel)]="filterDestinataire.adresse_destinataire" matInput placeholder="N° et nom de rue "
                    type="text">
            </mat-form-field>

        </div>
    </div>

    <div class="col-6">
        <div class="form-group">
            <mat-form-field class="w-100">
                <input [(ngModel)]="filterDestinataire.cp_destinataire" 
                (change)="EmitFilterDestinataire()" pattern="[-+]?[0-9]*[.,]?[0-9]+" name="nombre"
                    #nombre="ngModel" matInput placeholder="Code postal " type="text">
            </mat-form-field>
            <!-- <div *ngIf="nombre.invalid && (nombre.dirty || nombre.touched)" class="message-erreur">
                <div *ngIf="nombre.errors.pattern">
                    <i class="fa fa-exclamation-triangle"></i>&nbsp;Seulement des chiffres !
                </div>
            </div> -->
        </div>
    </div>

    <div class="col-6">
        <div class="form-group">
            <mat-form-field class="w-100">
                <input [(ngModel)]="filterDestinataire.ville_destinataire"
                    (change)="EmitFilterDestinataire()" matInput placeholder="Ville" type="text">
            </mat-form-field>

        </div>
    </div>


    <div class="col-12">
        <div class="form-group">
            <mat-form-field class="w-100">
                <input [(ngModel)]="filterDestinataire.complement_adresse_destinataire"
                (change)="EmitFilterDestinataire()" matInput
                    placeholder="Complément d'adresse" type="text">
            </mat-form-field>

        </div>
    </div>
</div>