<div class=" card-historiques  bg-white p-4">
  <div class="card-doc-title ml-3 row">
    <div class="col-md-6 d-flex align-items-center">
      <fa-icon class="mr-2 fa-lg" [icon]="fa['faObjectUngroup']"></fa-icon>
      <label class="title-history-action"> <b>Objet(s) / Bien(s) à assurer</b> </label>
    </div>
    <div class="col-md-6" >
      <div class="text-right ">
        <button type="button" (click)="addobjet()" class="add-btn-em">
          <img class="fa-icon-add" src="assets/icons/add-icon.svg">&nbsp;
          <span>Ajouter un objet</span>

        </button>
      </div>
    </div>

  </div>
  <ng-container>

    <div class="mt-3 col-12">
      <div class="row  col-12 " *ngIf="showobjetAssure">
        <div class="p-4 card  col-md-12">
          <span class="example-list-section font-weight-bold">
            <!-- <mat-checkbox class="example-margin" [disabled]="allComplete"   [checked]="allComplete" [color]="objectsAssurer.color"
              [indeterminate]="someComplete()" (change)="setAll($event.checked)">
              {{objectsAssurer.name}}
            </mat-checkbox> -->
            <b>{{objectsAssurer.name}}</b>
          </span>
          <span class="example-list-section p-2">
            <ul class="list-without-puce">
              <li *ngFor="let objects_Assurer of objectsAssurer.objects_Assurer">
                <mat-checkbox  [disabled]="objects_Assurer.completed" [(ngModel)]="objects_Assurer.completed" [color]="objects_Assurer.color"
                  (ngModelChange)="someComplete()">
                  <b>{{objects_Assurer.nom}}</b>
                </mat-checkbox>
              </li>
            </ul>
          </span>
              <span class="example-list-section font-weight-bold pl-3"  *ngIf="objectsAssurer.objects_Assurer.length==0">
                <!-- <div class="d-flex justify-content-center">
                Pas d'objets à assurer
              </div> -->
              </span>
        </div>
      </div>

      <form>


        <div class="row" *ngFor="let objetAssure of listObjetAssurer;index as i; first as isFirst ">

          <app-form-objet-assurer class="w-100" [formObjectAssurer]="objetAssure" (dataform)="getformdata($event)" (dataformcopie)="copieformdata($event)" (dataformdelete)="deleteformdata($event)">
          </app-form-objet-assurer>
        </div>

      </form>
      <div class="row pt-2"  *ngIf="listObjetAssurer?.length==0">
          <div class="col-12  d-flex justify-content-center">
            <img src="assets/icons/nodata.svg">

          </div>
          <div class="col-12  d-flex justify-content-center">
            <p class="noData">Pas de données disponibles</p>
          </div>
   
      </div>
    </div>
  </ng-container>
</div>
