import { DocumentsOpportunite } from 'src/app/entity/Opportunity/DocumentsOpportunite';
import { Documents } from './../../entity/Affaires/Documents';
import { Subject } from 'rxjs-compat/Subject';
import { FormlyJsonschema } from '@ngx-formly/core/json-schema';
import { ActivatedRoute } from '@angular/router';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ApiOpportunitService } from 'src/app/services/ApiOpportunite/api-opportunite.service';
import { NotificationsService } from 'src/app/shared/NotificationsService/notifications.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { ApiDocumentsService } from 'src/app/services/ApiDocuments/api-documents.service';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';
import { FormGroup } from '@angular/forms';
import { ApiAffairesService } from 'src/app/services/ApiAffaires/api-affaires.service';

@Component({
  selector: 'app-shared-documents',
  templateUrl: './shared-documents.component.html',
  styleUrls: ['./shared-documents.component.css'],
})
export class SharedDocumentsComponent implements OnInit {
  @Input() exist: DocumentsOpportunite;
  @Input() type;
  @Input() show_three_points: boolean;
  @Input() switch_upload_affaires: boolean = false;
  @Input() switch_upload_opportunite: boolean = false;
  @Input() switch_upload_ticket: boolean = false;
  @Input() documents_rajoutes: boolean;
  @Input() file: File = null;
  @Input() consulterFile: boolean;
  @Input() i;
  @Input() portedoc: boolean;
  @Input() prospect_id; // affaire
  @Input() opportunite_id; // opportuinte //affaire
  @Input() affaire_id; // affaire
  @Input() contrat_id; // affaire
  @Input() tickets_id; // tickets
  @Output() shareDocument: EventEmitter<any> = new EventEmitter();
  @Input() allowed_to_update: boolean;
  @Input() allowed_to_update_affaire: boolean;
  show_loading: boolean = false;
  show_loading_add: boolean = false;
  show_loading_form: boolean = false;
  ListFiles: File[] = [];
  index = 0;
  id_document: string = '';
  name_document: string;
  idDoc: string = '';
  fa = fa;
  shortLink: string = '';
  selectedFile : File;
  body: any;

  /**
   * Déclarations pour formly-form
   */
  data: any = null;

  form = new FormGroup({});
  fields = [];
  model: any = {};
  loadingBar: boolean = false;
  loadingData: boolean = false;
  @ViewChild('closeModal') closeModal: ElementRef;
  openModal: boolean;
  accee: boolean;

  constructor(
    private notificationsService: NotificationsService,
    private apiDocumentsService: ApiDocumentsService,
    private formlyJsonschema: FormlyJsonschema
  ) {}

  ngOnInit(): void {
    if (this.type === 'A') {
      this.accee = this.allowed_to_update_affaire;
    } else if (this.type === 'O') {
      this.accee = this.allowed_to_update;
    } else {
      this.accee = true;
    }
  }

  consulterFiles(idDoc: string) {
    if (!this.consulterFile) {
      this.idDoc = idDoc;
      this.consulterFile = true;
    } else this.consulterFile = false;
  }

  getFiles(event, idDocuemnt: string, nameDocument: string) {
    this.id_document = idDocuemnt;
    this.name_document = nameDocument;
    this.file = event.target.files[0];
    // this.ListFiles[this.index] = this.file;
    // this.exist.files.push(this.file);
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      
      // Store each file in the arrays
      this.ListFiles.push(file);
      this.exist.files.push(file);
    }
    this.index++;
    if (this.switch_upload_affaires === true) {
      this.onUploadAffaires();
    } else if (this.switch_upload_opportunite === true) {
      this.onUploadOpportunite();
    } else if (this.switch_upload_ticket === true) {
      this.onUploadTicket();
    }
  }

  removeFile(index: number): void {
    if (index >= 0 && index < this.exist.files.length) {
      const fileToDelete = this.exist.files[index];
      const fileIndexInListFiles = this.ListFiles.findIndex(file => file.name === fileToDelete.name);
      if (fileIndexInListFiles !== -1) {
          this.ListFiles.splice(fileIndexInListFiles, 1);
      }
  }

    this.exist.files.splice(index, 1);
    if (this.switch_upload_affaires === true) {
      this.onUploadAffaires();
    } else if (this.switch_upload_opportunite === true) {
      this.onUploadOpportunite();
    } else if (this.switch_upload_ticket === true) {
      this.onUploadTicket();
    }
  }

  onUploadAffaires() {
    this.show_loading_add = true;
        this.shareDocument.emit(this.show_loading_add);
    const formData: FormData = new FormData();
    formData.append('id_affaire', this.affaire_id);
    formData.append('id_pros', this.prospect_id);
    formData.append('id_contrat', this.contrat_id);
    formData.append('id_type_doc', this.id_document);
    formData.append('id_opp', this.opportunite_id);
    formData.append('name_doc', this.name_document);
     this.ListFiles.forEach((file) => formData.append('file[]', file));
     this.apiDocumentsService.uploadDocument$.next({ documentId:this.id_document ,Formdata :formData});

    /* this.apiDocumentsService.UploadDocuments(formData).subscribe(
      (response: any) => {
        this.ApiAffairesService.pushdocumenthistorique('test');
        this.show_loading_add = false;
        if (typeof response === 'object') {
          this.shortLink = response.link;
        }
        if (!response.error) {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.success;
          notificationMessage.title = 'Opération réussite';
          notificationMessage.message = 'Vos fichiers ont été téléchargés avec succès';
          this.notificationsService.sendMessage(notificationMessage);
          this.shareDocument.emit();
        }
        // this.loadListDocuments()
      },
      (error) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = 'Opération echoué';
        notificationMessage.message = error.message;
        this.notificationsService.sendMessage(notificationMessage);
        this.show_loading_add = false;
      }
    ); */
  }

  onUploadOpportunite() {
    this.show_loading = true;
    // this.shareDocument.emit(this.show_loading);
    const formData: FormData = new FormData();
    formData.append('id_pros', this.prospect_id);
    formData.append('id_document', this.id_document);
    formData.append('id_opp', this.opportunite_id);
    formData.append('name_doc', this.name_document);

    this.ListFiles.forEach((file) => formData.append('file[]', file));
    this.apiDocumentsService.uploadDocument$.next({ documentId:this.id_document ,Formdata :formData});
    /*  this.apiOpportuinteServices.uploadDocumentOpportunite(formData).subscribe(
      (response: any) => {
        if (!response.error) {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.success;
          notificationMessage.title = 'Opération réussite';
          notificationMessage.message = 'Vos fichiers ont été téléchargés avec succès';
          this.notificationsService.sendMessage(notificationMessage);
        }
        this.show_loading = false;
        this.shareDocument.emit();
      },
      (error) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = 'Opération echoué';
        notificationMessage.message = error.message;
        this.notificationsService.sendMessage(notificationMessage);
        this.show_loading = false;
      }
    ); */
  }

  expandName(name: string): string {
    name = name?.trim();
    if (name?.length < 40) {
      return name;
    } else {
      return name?.substring(0, 40) + '...';
    }
  }

  onUploadTicket() {
    this.show_loading_add = true;
    this.shareDocument.emit(this.show_loading_add);
    const formData: FormData = new FormData();
    formData.append('id_document', this.id_document);
    formData.append('id_ticket', this.tickets_id);
    formData.append('name_doc', this.name_document);
    this.ListFiles.forEach((file) => formData.append('file[]', file));
    this.apiDocumentsService.uploadDocument$.next({ documentId:this.id_document ,Formdata :formData});

    /* this.apiTicketsServices.uploadDocumentTicket(formData).subscribe(
      (response: any) => {
        this.show_loading_add = false;
        if (typeof response === 'object') {
          // this.shortLink = response.link;
        }
        if (!response.error) {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.success;
          notificationMessage.title = 'Opération réussite';
          notificationMessage.message = 'Vos fichiers ont été téléchargés avec succès';
          this.notificationsService.sendMessage(notificationMessage);
          this.shareDocument.emit();
        }
        // this.loadListDocuments();
      },
      (error) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = 'Opération echoué';
        notificationMessage.message = error.message;
        this.notificationsService.sendMessage(notificationMessage);
        this.show_loading_add = false;
      }
    ); */
  }

  getIdDocument(id: string): void {
    this.openModal = true;
    this.loadingData = true;
    this.idDoc = id;
    this.form = new FormGroup({});

    if (this.type === 'A') {
      this.body = {
        entity: 'A',
        id_entity: this.affaire_id,
        type_document: id,
        data: {},
      };
    } else if (this.type === 'O') {
      this.body = {
        entity: 'O',
        id_entity: this.opportunite_id,
        type_document: id,
        data: {},
      };
    } else if (this.type === 'T') {
      this.body = {
        entity: 'T',
        id_entity: this.tickets_id,
        type_document: id,
        data: {},
      };
    }

    this.apiDocumentsService.GetInformationSpecifique(this.body).subscribe(
      (data) => {
        this.data = data.form;
        if (
          this.data !== undefined &&
          this.data.schema !== undefined &&
          this.data.schema.properties !== undefined &&
          this.data.schema.properties.length > 0
        ) {
          const params: any = this.data.schema.properties;
          const infoSpecifique: any[] = [];
          if (this.data.schema !== null) {
            this.data.schema.properties = {};
            this.model = {};
            params?.forEach((element) => {
              infoSpecifique[element.name] = element;
              this.model[element.name] = element.default;
            });
            this.data.schema.properties = infoSpecifique;

            this.fields = [this.formlyJsonschema.toFieldConfig(this.data.schema)];
          }

          let i = 0;
          for (const [key, value] of Object.entries(this.data.data)) {
            const keys = Object.keys(this.model);
            keys.sort();
            if (key === keys[i]) this.model[key] = value;
            i++;
          }

          this.loadingData = false;
        } else {
          this.data = null;
          this.loadingData = false;
        }
      },
      () => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = 'Opération echoué';
        notificationMessage.message = 'Erreur lors du chargement du formulaire';
        this.notificationsService.sendMessage(notificationMessage);
        this.show_loading_form = false;
        this.loadingData = false;
      }
    );
  }

  onSaveForm(): void {
    this.show_loading_form = true;

    if (this.type === 'A') {
      this.body = {
        entity: 'A',
        id_entity: this.affaire_id,
        type_document: this.idDoc,
        data: this.model,
      };
    } else if (this.type === 'O') {
      this.body = {
        entity: 'O',
        id_entity: this.opportunite_id,
        type_document: this.idDoc,
        data: this.model,
      };
    } else if (this.type === 'T') {
      this.body = {
        entity: 'T',
        id_entity: this.tickets_id,
        type_document: this.idDoc,
        data: this.model,
      };
    }

    this.apiDocumentsService.SaveInformationSpecifique(this.body).subscribe(
      (response) => {
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.success;
        notificationMessage.title = 'Opération réussite';
        notificationMessage.message = 'Le formulaire a été enregistré avec succès';
        this.notificationsService.sendMessage(notificationMessage);
        this.show_loading_form = false;
        this.closeModal.nativeElement.click();
      },
      (error) => {
        if (error.includes('304')) {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.info;
          notificationMessage.title = 'Opération echoué';
          notificationMessage.message = 'Aucun changement détecté';
          this.notificationsService.sendMessage(notificationMessage);
          this.show_loading_form = false;
          this.closeModal.nativeElement.click();
        } else {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = 'Opération echoué';
          notificationMessage.message = "Erreur lors de l'enregistrement du formulaire";
          this.notificationsService.sendMessage(notificationMessage);
          this.show_loading_form = false;
          this.closeModal.nativeElement.click();
        }
      }
    );
  }

  onClearForm(): void {
    this.closeModal.nativeElement.click();
    this.model = null;
    this.fields = [];
    this.data = null;
    this.openModal = false;
  }
}
