<div class="card-doc bg-white p-3 border-0" [ngClass]="{'cover': !allowed_to_update}">

    <div class="card-doc-title ml-3 row">
        <fa-icon class="mr-3 mx-1 fa-lg" [icon]="fa['faFileImport']"></fa-icon>
        Porte Documents
    </div>

    <div class="row p-3">
        <div class="col-md-12 p-4 div-list-exist scrollbar scrolling">
            <div class="row d-flex justify-content-center mb-3">
                <ng-container *ngIf="show_loading">
                    <div class="row">
                        <div class="col-12 text-center">
                            <div class="m-auto mb-2">
                                <mat-spinner [diameter]="30"></mat-spinner>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="row">
                <div class="col-xl-4 col-lg-12 accordion" *ngFor="let exist of listDocuments; let i=index" id="accordionFolder">
                    <app-shared-documents [exist]="exist" [portedoc]="true" [show_three_points]="true" [type]="'O'" [switch_upload_opportunite]="true" [prospect_id]="id_prospect" [opportunite_id]="opportunity_id" [i]="i" (shareDocument)="getSharedDocument($event)" [allowed_to_update]="allowed_to_update"></app-shared-documents>
                </div>
            </div>
        </div>
    </div>
</div>

<!--
<div class="modal fade" id="addDocument" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <form #myForm="ngForm">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Nouveau Dossier</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
                </div>
                <div class="modal-body">
                    <mat-form-field class="example-full-width" style="width: 100%;">
                        <mat-label>Nom du Dossier</mat-label>
                        <input matInput name="name" [(ngModel)]="addDocuments.name" required #name="ngModel">
                    </mat-form-field>
                    <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger">
                        <div *ngIf="name.errors.required">
                            <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
                            Le nom du dossier est obligatoire!
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                     <button  type="button" class="btn-dt-fermer" data-dismiss="modal">
                        <fa-icon class="fa fa-lg" [icon]="fa['faTimesCircle']"></fa-icon>&nbsp;
                          Fermer
                    </button>
                    <button type="button" class="btn-dt-ajouter" (click)="addDocument()" [disabled]="!myForm.form.valid" data-dismiss="modal">
            <fa-icon class="fa fa-lg" [icon]="fa['faPlusCircle']"></fa-icon>&nbsp;
            Ajouter
          </button>
                </div>
            </form>
        </div>
    </div>
</div>
 -->
