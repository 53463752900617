import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as fa from '@fortawesome/free-solid-svg-icons';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FilterEcheances } from 'src/app/entity/FilterEcheances';
import { map, startWith } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { EcheancierService } from 'src/app/services/ApiEcheancier/echeancier.service';
import { Observable, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Status } from 'src/app/entity/TicketsAndSupport/InformationTicket';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { RangeDateComponent } from 'src/app/components/rangeDate/range-date/range-date.component';

@Component({
  selector: 'app-filtre-echeancier',
  templateUrl: './filtre-echeancier.component.html',
  styleUrls: ['./filtre-echeancier.component.css']
})
export class FiltreEcheancierComponent implements OnInit, OnChanges {

  filterEcheancier: FilterEcheances = new FilterEcheances();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @Output() onPushFilterEcheancier: EventEmitter<FilterEcheances> = new EventEmitter();
  fa = fa;
  readonly rangeDatePeriod = RangeDateComponent;


  _unsubscribeAlll: Subscription[]=[];
  listeAllStatus: Status [] = [];;
  selectablee = true;
  removablee = true;
  filteredStatus: Observable<any[]>;
  ListStatus: string[] = [];
  listStatusSelected: string[] = [];
  @ViewChild('statusInput') statusInput: ElementRef<HTMLInputElement>;
StatusFromCtrl: FormControl = new FormControl('');
  /** Motif */
  _unsubscribeAll: Subscription[] = [];
  listeAllMotif: any[] = [];;
  selectable = true;
  removable = true;
  filteredMotif: Observable<any[]>;
  ListMotif: string[] = [];
  listMotifSelected: string[] = [];
  @ViewChild('MotifInput') MotifInput: ElementRef<HTMLInputElement>;
  MotifFromCtrl: FormControl = new FormControl('');

  /** date de prelevment */
  date_debut_periode: any = null;
  date_fin_periode: any = null;

  @ViewChild('pickerDC') pickerDC: MatDateRangePicker<any>; 

  @Output() returnToparent = new EventEmitter<boolean>();

  @Input() resetEcheancier: boolean;
  constructor(private echeancierService: EcheancierService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.listStatus();
    this.listMotif();
    this.filteredMotif = this.MotifFromCtrl.valueChanges.pipe(
      startWith(null),
      map((product: string | null) => (product ? this._filter() : this.listeAllMotif.slice()))
    );


  this.filteredStatus = this.StatusFromCtrl.valueChanges.pipe(
    startWith(null),
    map((product: string | null) => (product ? this._filterr() : this.listeAllStatus.slice()))
  );  
  }

  openDatePeriod() {
    if (this.pickerDC) {
      this.pickerDC.open();
    }
 
  }

  clearDatePeriod(){
    this.filterEcheancier.date_debut_periode = null;
    this.filterEcheancier.date_fin_periode = null;
     this.date_debut_periode = null;
     this.date_fin_periode = null;
     this.EmitFilterEcheancier()
   }

   

  listMotif() {
    this.echeancierService
      .listeAllMotif().subscribe((response) => {
        this.listeAllMotif = response.motif
      });

  }

  selectOptionStatut(option: string): void {
    this.filterEcheancier.operateur_status_echeanciers= option;
this.EmitFilterEcheancier()
  }

  selectOptionMotif(option: string): void {
    this.filterEcheancier.operateur_motif= option;
    this.EmitFilterEcheancier()
  }

  selectedMotif(event: MatAutocompleteSelectedEvent): void {
    if (!this.listMotifSelected.includes(event.option.value)) {
    this.ListMotif.push(event.option.viewValue);
    this.listMotifSelected.push(event.option.value);}
    this.MotifInput.nativeElement.value = '';
    this.MotifFromCtrl.setValue(null);
    this.EmitFilterEcheancier()
  }

  removeMotif(product: string): void {
    const index = this.ListMotif.indexOf(product);
    if (index >= 0) {
      this.ListMotif.splice(index, 1);
      this.listMotifSelected.splice(index, 1);
    }
    if (this.listMotifSelected.length ===0){
      this.filterEcheancier.operateur_motif="="
    }
  }

  private _filter(): any[] {
    let filterValue = this.MotifFromCtrl.value.toString().toLowerCase();
    if (filterValue === null) filterValue = '';
    return this.listeAllMotif.filter((Motif) => Motif.libelle.toLowerCase().includes(filterValue) );
  }


  EmitFilterEcheancier() {
    
      const dateDebut = this.datePipe.transform(this.date_debut_periode, 'yyyy-MM-dd');
      this.filterEcheancier.date_debut_periode = dateDebut;

   


      const dateFin = this.datePipe.transform(this.date_fin_periode, 'yyyy-MM-dd');
      this.filterEcheancier.date_fin_periode = dateFin;

 
    if (this.listMotifSelected.length ===0){
      this.filterEcheancier.operateur_motif="="
    }
    if (this.listStatusSelected.length ===0){
      this.filterEcheancier.operateur_status_echeanciers="="
    }
    this.filterEcheancier.motif = this.listMotifSelected;
    this.filterEcheancier.status_echeanciers = this.listStatusSelected;  
    this.onPushFilterEcheancier.emit(this.filterEcheancier);

  }


  ngOnChanges(changes: SimpleChanges) {
    this.resetEcheancierForm(changes.resetEcheancier.currentValue);
  }
  
  resetEcheancierForm(Reset) {
    if (Reset === true) {
      this.ListMotif = [];
      this.ListStatus = [];
      this.filterEcheancier.motif = this.listMotifSelected = [];
      this.filterEcheancier.status_echeanciers = this.listStatusSelected = [];
      this.filterEcheancier.operateur_status_echeanciers = "=";
      this.filterEcheancier.operateur_motif = "=";
      this.filterEcheancier.date_debut_periode = null;
      this.filterEcheancier.date_fin_periode = null;
      this.date_debut_periode = null;
      this.date_fin_periode =null;
      Reset = false;
      this.returnToparent.emit(Reset);
      this.onPushFilterEcheancier.emit(this.filterEcheancier);
    }
  }


  listStatus(){
    this.echeancierService
    .listeAllStatusEcheancier().subscribe((response)=>{
  this.listeAllStatus = response.status;
    }); 

  }


   selectedStatus(event: MatAutocompleteSelectedEvent): void {
    if (!this.listStatusSelected.includes(event.option.value)) {
    this.ListStatus.push(event.option.viewValue);
    this.listStatusSelected.push(event.option.value);}
    this.statusInput.nativeElement.value = '';
    this.StatusFromCtrl.setValue(null);
    this.EmitFilterEcheancier()
  }

  removeStatus(product: string): void {
    const index = this.ListStatus.indexOf(product);
    if (index >= 0) {
      this.ListStatus.splice(index, 1);
      this.listStatusSelected.splice(index, 1);
    }
    if (this.listStatusSelected.length ===0){
      this.filterEcheancier.operateur_status_echeanciers="="
    }
  } 

   private _filterr(): any[] {
     let filterValue = this.StatusFromCtrl.value.toString().toLowerCase();
     if (filterValue === null) filterValue = '';
     return this.listeAllStatus.filter((status) => status.libelle.toLowerCase().includes(filterValue) );
   } 

}
