import { Component, Input, OnInit, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { ArrayFormly } from 'src/app/entity/Formbuilder/ArrayFormly';
import { FormField } from 'src/app/entity/Formbuilder/FormField';
import { MultiselectFormly } from 'src/app/entity/Formbuilder/MultiselectFormly';
import { NumberFormly } from 'src/app/entity/Formbuilder/NumberFormly';
import { RadioFormly } from 'src/app/entity/Formbuilder/RadioFormly';
import { SelectFormly } from 'src/app/entity/Formbuilder/SelectFormly';
import { CustomOptions } from 'src/app/entity/Formbuilder/SharedElementsFormly';
import { TextAreaFormly } from 'src/app/entity/Formbuilder/TextAreaFormly';
import { TextFormly } from 'src/app/entity/Formbuilder/TextFormly';
import swal from 'sweetalert2';
@Component({
  selector: 'shared-form-params',
  templateUrl: './form-params.component.html',
  styleUrls: ['./form-params.component.css'],
})
export class SharedFormParamsComponent implements OnInit {
  @Input() item: FormField;
  @Input() dropZoneCreatedModel: FormField[] = [];
  @Output() onChangeParams = new EventEmitter<any>();
  @Output() onErrorParams = new EventEmitter<boolean>();
  disableId: boolean = false;
  formFieldParams: FormGroup;
  fieldOptions: FormGroup;
  count = 0;
  public editorOptions: JsonEditorOptions;
  public data: any;

  jsonArray: any;

  @ViewChild(JsonEditorComponent) editor: JsonEditorComponent;
  existetitle: boolean = false;

  constructor() {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
    this.editorOptions.mode = 'code';
  }

  ngOnInit(): void {
    this.count=0;
    for (let i = 0; i < this.dropZoneCreatedModel.length; i++) {
      const obj = this.dropZoneCreatedModel[i];
      if (obj.name === this.item.name) {
        this.count++;
      }
    }
    if (this.count > 1) {
      this.existetitle = true;
      this.onErrorParams.emit(this.existetitle);
    }else{
      this.existetitle = false;
      this.onErrorParams.emit(this.existetitle);
    }
    this.initFormFields();
  }

  initFormFields() {
    let item: any;

    /*
     * Init radio and select field params form
     * */

    this.fieldOptions = new FormGroup({
      label: new FormControl(''),
      value: new FormControl(''),
    });

    /*
     * Init Full item config form
     * */
    switch (this.item.typename) {
      case 'Text':
        item = this.item as TextFormly;
        this.formFieldParams = new FormGroup(
          {
            col: new FormControl(item.widget.formlyConfig.templateOptions.col),
            name: new FormControl(item.name),
            minLength: new FormControl(item.minLength),
            maxLength: new FormControl(item.maxLength),
            pattern: new FormControl(item.pattern),
            format: new FormControl(item.format),
            label: new FormControl(item.title),
            description: new FormControl(item.description),
            default: new FormControl(item.default),
          },
          { updateOn: 'change' }
        );
        break;
      case 'Text Area':
        item = this.item as TextAreaFormly;
        this.formFieldParams = new FormGroup(
          {
            col: new FormControl(item.widget.formlyConfig.templateOptions.col),
            name: new FormControl(item.name),
            label: new FormControl(item.title),
            description: new FormControl(item.description),
            default: new FormControl(item.default),
            placeholder: new FormControl(item.widget.formlyConfig.templateOptions.placeholder),
            rows: new FormControl(item.widget.formlyConfig.templateOptions.rows),
          },
          { updateOn: 'change' }
        );
        break;
      case 'Number':
        item = this.item as NumberFormly;
        this.formFieldParams = new FormGroup(
          {
            col: new FormControl(item.widget.formlyConfig.templateOptions.col),
            name: new FormControl(item.name),
            label: new FormControl(item.title),
            description: new FormControl(item.description),
            default: new FormControl(item.default),
            minimum: new FormControl(item.minimum),
            exclusiveMinimum: new FormControl(item.exclusiveMinimum),
            maximum: new FormControl(item.maximum),
            exclusiveMaximum: new FormControl(item.exclusiveMaximum),
            multipleOf: new FormControl(item.multipleOf),
          },
          { updateOn: 'change' }
        );
        break;
      case 'Array':
        item = this.item as ArrayFormly;
        this.formFieldParams = new FormGroup(
          {
            col: new FormControl(item.widget.formlyConfig.templateOptions.col),
            name: new FormControl(item.name),
            label: new FormControl(item.title),
            minItems: new FormControl(item.minItems),
            maxItems: new FormControl(item.maxItems),
          },
          { updateOn: 'change' }
        );
        this.data = item.items;
        break;
      default:
        this.formFieldParams = new FormGroup({
          col: new FormControl(this.item.widget.formlyConfig.templateOptions.col),
          name: new FormControl(this.item.name),
          label: new FormControl(this.item.title),
          description: new FormControl(this.item.description),
          default: new FormControl(this.item.default),
        });
    }
    if (this.item.name === 'idObject') {
      this.formFieldParams.controls.name.disable();
      this.disableId = true;
    }
  }

  updateItem() {
    this.count=0;
    this.existetitle = false;

    this.onErrorParams.emit(this.existetitle);
    let item: any;

    const formValue = this.formFieldParams.getRawValue();
    this.item.name = formValue.name;
    this.item.title = formValue.label;
    for (let i = 0; i < this.dropZoneCreatedModel.length; i++) {
      const obj = this.dropZoneCreatedModel[i];
      if (obj.name === formValue.name) {
        this.count++;
      }
    }
    if (this.count > 1) {
      this.existetitle = true;
      this.onErrorParams.emit(this.existetitle);
    }else{
      this.existetitle = false;
      this.onErrorParams.emit(this.existetitle);
    }

    this.item.description = formValue.description;
    this.item.default = formValue.default;
    this.item.widget.formlyConfig.templateOptions.col = formValue.col;
    switch (this.item.typename) {
      case 'Text Area':
        item = this.item as TextAreaFormly;
        item.widget.formlyConfig.templateOptions.placeholder = this.formFieldParams.value.placeholder;
        item.widget.formlyConfig.templateOptions.rows = this.formFieldParams.value.rows;
        break;
      case 'Number':
        item = this.item as NumberFormly;
        item.minimum = this.formFieldParams.value.minimum;
        item.exclusiveMinimum = this.formFieldParams.value.exclusiveMinimum;
        item.maximum = this.formFieldParams.value.maximum;
        item.exclusiveMaximum = this.formFieldParams.value.exclusiveMaximum;
        item.multipleOf = this.formFieldParams.value.multipleOf;
        item.default = this.formFieldParams.value.default;

        break;
      case 'Text':
        item = this.item as TextAreaFormly;
        item.minLength = this.formFieldParams.value.minLength;
        item.maxLength = this.formFieldParams.value.maxLength;
        item.pattern = this.formFieldParams.value.pattern;
        item.format = this.formFieldParams.value.format;
        item.default = this.formFieldParams.value.default;
        break;
      case 'Array':
        item = this.item as ArrayFormly;
        item.minItems = this.formFieldParams.value.minItems;
        item.maxItems = this.formFieldParams.value.maxItems;
        item.default = this.formFieldParams.value.default;
        item.items = this.jsonArray;
        break;
    }
    // if(this.item.name === 'idObject' && this.item.title ===""){
    //  this.alertWarning()
    // }else
    this.onChangeParams.emit(this.item);
  }

  onChangeJson($event: any) {
    this.jsonArray = $event;
    this.updateItem();
  }

  deletePossibleOption(item: FormField, index: number) {
    if (item instanceof RadioFormly || item instanceof SelectFormly) {
      item.widget.formlyConfig.templateOptions.options.splice(index, 1);
    }
    if (item instanceof MultiselectFormly) {
      item.widget.formlyConfig.templateOptions.options.splice(index, 1);
    }
    this.updateItem();
  }

  addPossibleOpt(item: FormField) {
    if (item instanceof RadioFormly || item instanceof SelectFormly) {
      item.widget.formlyConfig.templateOptions.options.push(
        new CustomOptions(this.fieldOptions.value.label, this.fieldOptions.value.value)
      );
    }

    if (item instanceof MultiselectFormly) {
      item.widget.formlyConfig.templateOptions.options.push(
        new CustomOptions(this.fieldOptions.value.value, this.fieldOptions.value.value)
      );
    }
    this.fieldOptions.reset();
    this.updateItem();
  }

  alertWarning() {
    swal.fire({
      title: 'Erreur',
      text: "Le nom de l'identifiant de l'objet est obligatoire",
      icon: 'error',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Fermer',
      confirmButtonColor: '#d53a3a',
    });
  }
}
