<div class="card-head text-center">
    <div class="row details-header">
        <button type="button" (click)="retourToList()" class="btn-dt-retourr mt-1" >
            <div class="icon-position">
                <fa-icon class="fa-icon-style" [icon]="fa['faArrowLeft']"></fa-icon>&nbsp;
            </div>
        </button>
        <div class="row">
            <div class="row details-spinner">

                <div class="col mr-3">
                    <button type="button" class="btn-load" (click)="clearForm()">
                        <fa-icon [icon]="fa['faRedo']"></fa-icon>
                    </button>
                </div>
            </div>
            <button type="button" class="btn-dt-save" (click)="add()">
                <fa-icon class="fa-lg" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
                Enregistrer
            </button>
        </div>
    </div>
</div>
<!--
-->

<div class="card border-0 m-3 mb-5 p-3 ">
    <div class="row ">
        <div class="col-md-6">
            <h2 *ngIf="ModeAjout">Ajouter garantie</h2>
            <h2 *ngIf="!ModeAjout">Modifier garantie</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <span class="text-danger">( * ) champs obligatoire!</span>
        </div>
    </div>
    <hr>

    <div class="row ">
        <div class="col">
            <form [formGroup]="grantieForm" (ngSubmit)="add()">

                <div class="row mt-3">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="w-100">
                                <mat-label>Code garantie</mat-label>
                                <input type="text" matInput formControlName="code" required placeholder="Code garantie">
                                <mat-error
                                    *ngIf="grantieForm.get('code').hasError('required') && grantieForm.get('code').touched">
                                    Le code est requis.
                                </mat-error>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="w-100">
                                <mat-label>Nom garantie</mat-label>
                                <input type="text" matInput formControlName="nom" required placeholder="Nom garantie">
                                <mat-error
                                    *ngIf="grantieForm.get('nom').hasError('required') && grantieForm.get('nom').touched">
                                    Nom garantie est requis.
                                </mat-error>
                            </mat-form-field>

                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="w-100">
                                <mat-label>Valeur minimale</mat-label>
                                <input type="text" matInput formControlName="valeur_min" (keypress)="numberOnly($event)"
                                    placeholder="Valeur minimale">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="w-100">
                                <mat-label>Valeur maximale</mat-label>
                                <input type="text" matInput formControlName="valeur_max" (keypress)="numberOnly($event)"
                                    placeholder="Valeur maximale">
                                 
                                <mat-error *ngIf="grantieForm.hasError('valeurMinInferieureValeurMax')">
                                    La valeur minimale doit être inférieure à la valeur maximale.
                                </mat-error>
                            </mat-form-field>

                        </div>
                    </div>

                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="w-100">
                                <mat-label>Unité</mat-label>
                                <mat-select formControlName="unite" required>

                                    <mat-option value="{{currency}}">{{currency}}</mat-option>
                                    <mat-option value="%">%</mat-option>

                                </mat-select>
                                <mat-error
                                    *ngIf="grantieForm.get('unite').hasError('required')  && grantieForm.get('unite').touched">
                                    L'unité' est requis.
                                </mat-error>
                            </mat-form-field>

                        </div>
                    </div>


                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="w-100">
                                <mat-label>Description</mat-label>
                                <textarea matInput placeholder="Description..."
                                    formControlName="description"></textarea>
                            </mat-form-field>
                        </div>
                    </div>



                    <div class="col-md-6">
                        <div class="form-group">
                            <app-icon-picker
                             [searchText]="icon"
                            (checkedNameIcon)="onCheckedNameIcon($event)"
                             [req]="false" 
                            (onChangeIcon)="onSelectIcon($event)"
                            (onChangeNameIcon)="onSelectNameIcon($event)"></app-icon-picker>
                        </div>
                    </div>
                    <div class="col-md-6 mt-5 col-sm-12">
                        <label class="mr-5">Cotisation:</label>
                        <label class="switch ">
                            <input type="checkbox" name="multiple" formControlName="cotisation">
                            <span class="slider round"></span>
                        </label>
                        <label class="mr-5">Capitaux assurés:</label>
                        <label class="switch ">
                            <input type="checkbox" name="capito_assure" formControlName="capitaux_assure">
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
            </form>

        </div>

    </div>



</div>