<div class="modal-content">
    <div class="modal-header row">
    <div class="col-11">
        <h3 class="text-center title justify-content-center m-0 w-100">
            Actions Groupées
        </h3>
    </div>
        <div class="col-1">
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    </div>
    <div class="row">
        <div class="modal-body">
            <div class="row">
                <div class="col-6">
                    <div class="form-group">
                        <mat-form-field class="full-width m" appearance="standard">
                            <mat-label>Affecter à </mat-label>
                            <input type="text" matInput [(ngModel)]="affecteUser" [formControl]="affecterAFormControl"
                                name="affecte"  [matAutocomplete]="auto" />
                            <mat-autocomplete autoActiveFirstOption [displayWith]="displayContactFn"
                                #auto="matAutocomplete">
                                <mat-option *ngFor="let option of listUserFilter | async" [value]="option">
                                    {{ option.nom + ' ' + option.prenom }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-6  w-100">
                    <mat-form-field class="w-100 mt-3 ">
                        <mat-label>Changer statut</mat-label>
                        <mat-select [(ngModel)]="selectedStatus">
                          <mat-option *ngFor="let status of statusList" value="{{status.id}}"> {{status.libelle.split('(')[0].trim()}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="row w-100 mt-2 mb-4 text-center d-flex justify-content-center">
      
    
            <button type="button" class="btn-dt-save-popup mr-2" (click)="Enregistrer()">
                <fa-icon class="fa-icon-style fa-lg" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
                Appliquer
            </button>

            <button type="button" class="btn-dt-cancel-popup " aria-label="Close" (click)="activeModal.dismiss('')">
                <fa-icon class="fa-icon-style fa-lg" [icon]="fa['faTimesCircle']"></fa-icon>&nbsp;
                Annuler
            </button>
        
    </div>
</div>