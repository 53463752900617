<div class="modal-content">
    <div class="modal-header row">
        <div class="col-11">
            <h3 class="text-center title justify-content-center m-0 w-100">
                Actions Groupées
            </h3>
        </div>
        <div class="col-1">
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="modal-body">
            <mat-accordion class="example-headers-align" *ngIf=" act_grp_statut">
                <ng-container>
                    <mat-expansion-panel class=" mt-2" [expanded]="panelOpenState">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="row ">
                                    <div class="row filtre-spn">
                                        <fa-icon class="fa-lg mr-2" [icon]="fa['faLightbulb']"></fa-icon>
                                        <span>Statut Actuel</span>
                                    </div>
                                </div>
                            </mat-panel-title>
                            <mat-panel-description>
                                <!--Description-->
                                <div class="col-10"></div>
                                <div class="col-2 d-flex justify-content-end align-items-center">
                                </div>
                            </mat-panel-description>


                        </mat-expansion-panel-header>
                        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

                            <mat-tree-node *matTreeNodeDef="let node;" matTreeNodeToggle matTreeNodePadding>
                                <button mat-icon-button matTreeNodeToggle *ngIf="node.expandable "
                                    [attr.aria-label]="'Toggle ' + node.item">
                                    <mat-icon class="mat-icon-rtl-mirror">
                                        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                    </mat-icon>
                                    {{node.item}}
                                </button>
                                <mat-radio-button *ngIf="!node.expandable "
                                    (change)="todoLeafItemSelectionToggle(node,$event)">{{node.item}}</mat-radio-button>

                            </mat-tree-node>
                        </mat-tree>
                    </mat-expansion-panel>
                </ng-container>
            </mat-accordion>
            <mat-accordion class="example-headers-align" *ngIf="act_grp_tags">
                <ng-container>
                    <mat-expansion-panel class=" mt-2" [expanded]="panelOpenState">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="row ">
                                    <div class="row filtre-spn">
                                        <fa-icon class="fa-lg mr-2" [icon]="fa['faTag']"></fa-icon>
                                        <span>Ajouter mots-clés</span>
                                    </div>
                                </div>
                            </mat-panel-title>
                            <mat-panel-description>
                                <!--Description-->
                                <div class="col-10"></div>
                                <div class="col-2 d-flex justify-content-end align-items-center">
                                </div>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <shared-tags [actionGroupe]="'opp'" [liste]="listeOpp" [type]="'opportunite'"
                            (newItemEvent)="addItem($event)"></shared-tags>
                    </mat-expansion-panel>
                </ng-container>
            </mat-accordion>
            <mat-accordion class="example-headers-align" *ngIf="act_grp_tags">
                <ng-container>
                    <mat-expansion-panel class=" mt-2" [expanded]="panelOpenState">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="row ">
                                    <div class="row filtre-spn">

                                        <svg width="22" height="50" viewBox="0 0 96 110" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0 17.1876V49.3067C0 52.9591 1.43571 56.461 4.00714 59.0391L41.7214 96.8516C47.0786 102.223 55.7571 102.223 61.1143 96.8516L89.7214 68.17C95.0786 62.7989 95.0786 54.0977 89.7214 48.7266L52.0071 10.9141C49.4357 8.33601 45.9429 6.89656 42.3 6.89656H10.2857C4.60714 6.87507 0 11.4942 0 17.1876Z"
                                                fill="#2a96ff" />
                                            <path
                                                d="M62.6859 47.995L49.2574 48.2565L49.0112 35.2909C48.9602 32.6052 46.6954 30.4258 44.0097 30.4781C42.7104 30.5111 41.4763 31.0549 40.5749 31.9917C39.6735 32.9284 39.1775 34.1826 39.1945 35.4821L39.4406 48.4477L26.0121 48.7093C24.7128 48.7423 23.4787 49.2861 22.5773 50.2228C21.6759 51.1596 21.1799 52.4137 21.1969 53.7133C21.2479 56.399 23.5128 58.5784 26.1985 58.5261L39.627 58.2646L39.889 72.0637C39.94 74.7494 42.2048 76.9288 44.8905 76.8765C46.2797 76.8494 47.4731 76.2703 48.3362 75.3734C49.1993 74.4764 49.7321 73.2616 49.7057 71.8725L49.4437 58.0734L62.8723 57.8118C64.2614 57.7848 65.4548 57.2056 66.3179 56.3087C67.181 55.4118 67.7138 54.197 67.6875 52.8078C67.6365 50.1221 65.4188 47.9881 62.6859 47.995Z"
                                                fill="white" />
                                        </svg>
                                        <span class="ml-2 mt-3">Supprimer mots-clés</span>
                                    </div>
                                </div>
                            </mat-panel-title>
                            <mat-panel-description>
                                <!--Description-->
                                <div class="col-10"></div>
                                <div class="col-2 d-flex justify-content-end align-items-center">
                                </div>
                            </mat-panel-description>


                        </mat-expansion-panel-header>
                        <ng-container>
                            <ng-container>
                                <div class="tags-box">
                                    <label *ngFor="let tag of activeTags; let index = index"
                                        class="tag-active d-inline-flex m-1 p-2"
                                        [ngStyle]="{'background-color':tag.couleur_background, 'color':tag.couleur}">
                                        {{tag.libelle}}
                                        <fa-icon class="fa-lg ml-2 remove-tag" [icon]="fa['faTimes']"
                                            (click)="removeTag(index , tag.id)"></fa-icon>
                                    </label>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="activeTags.length < 1">
                                <p>Pas des mots-clés</p>
                            </ng-container>
                        </ng-container>
                    </mat-expansion-panel>
                </ng-container>
            </mat-accordion>

            <mat-accordion class="example-headers-align" *ngIf="act_grp_pubpostage">
                <ng-container>
                    <mat-expansion-panel class=" mt-2" [expanded]="panelOpenState">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="row ">
                                    <div class="row filtre-spn">
                                        <fa-icon class="fa-lg mr-2" [icon]="fa['faTag']"></fa-icon>
                                        <span>Ajouter Mots-clés Prospects</span>
                                    </div>
                                </div>
                            </mat-panel-title>
                            <mat-panel-description>
                                <!--Description-->
                                <div class="col-10"></div>
                                <div class="col-2 d-flex justify-content-end align-items-center">
                                </div>
                            </mat-panel-description>


                        </mat-expansion-panel-header>
                        <shared-tags [actionGroupe]="'opp'" [liste]="listeOpp" [type]="'prospect'"
                            (listProspectTag)="addListTagProspect($event)"></shared-tags>
                    </mat-expansion-panel>
                </ng-container>
            </mat-accordion>
        </div>
    </div>
    <div class="row w-100 mt-2 mb-3 text-center d-flex justify-content-center">
     
        <button type="button" class="btn-dt-save-popup mr-2" (click)="save()">
            <fa-icon class="fa-icon-style fa-lg" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
            Appliquer
        </button>
        <button type="button" class="btn-dt-cancel-popup " aria-label="Close" (click)="activeModal.dismiss('')">
            <fa-icon class="fa-icon-style fa-lg" [icon]="fa['faTimesCircle']"></fa-icon>&nbsp;
            Annuler
        </button>
    </div>
</div>