<div class="card-doc bg-white p-3 border-0">
  <div class="card-doc-title ml-3 row" >
      <fa-icon class="mr-3 mx-1 fa-lg" [icon]="fa['faBook']"></fa-icon>
      <label class="title-card-details">Porte Documents</label>
  </div>
  <!-- <button type="button"  class="btn-document-add btn-position-add-document" data-title="Ajouter un dossier">
    <fa-icon class="fa fa-lg" [icon]="fa['faPlus']"></fa-icon>
  </button> -->
  <div class="row">
      <div class="col-md-12 p-4 div-list-exist scrollbar">
  
          <div class="col-4 light" >
            <div class="row flex-nowrap" style="cursor: pointer;">
              
                <div class="col-1 m-2 pl-0 greyColor" >
                    <fa-icon class="folder-icon" [icon]="fa['faFolder']"></fa-icon>
                </div>
                <div class="col-9" [attr.data-toggle]="'collapse'">
                    <p class=" greyColor ml-3 mt-2">
                      Divers <span *>( * )</span>
                    </p>
                    <p class="ml-3 greyColor text-dark ">
                        1 fichier(s)
                    </p>
                </div>
                <div class="col-2 text-right mt-3 position-relative pl-0" >
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                      <fa-icon  class="bi-three-dots-vertical"  [icon]="fa['faEllipsisV']"></fa-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item>
                        <input type="file" hidden multiple  />
                        <label f class="add-file">
                          <fa-icon [icon]="fa['faPlusCircle']"></fa-icon>
                          Ajouter un ficher
                        </label>
                      </button>
          
                        <button mat-menu-item data-toggle="modal" data-target="#formModal" >
                        <label class="add-file">
                          <fa-icon [icon]="fa['faTable']"></fa-icon>
                          Formulaire
                        </label>
                      </button>
                    </mat-menu>
          
                </div>
            </div>
          </div>
      </div>
</div>

</div>

<div class="card-bloc bg-white p-3">
  <div class="card-doc-title ml-3 row" >
    <h2>Documents relatifs à l'affaire</h2>
</div>
<div class="row">
  <div class="col-md-6 div-list-no-exist p-3 mb-2">
      <div class="row">
          <label class="label-title mb-2 ml-2 text-secondary">Documents à Ajouter</label>
      </div>
  </div>
  <div class="col-md-6 div-list-exist p-3">
    <div class="row">
        <label class="label-title mb-2 text-primary">Documents Rajoutés</label>
    </div>
</div>
</div>
</div>
<app-code [code]="code_porte_document"></app-code>
<br>

<div class="col-md-6 col-sm-12 accordion" id="folder_tableaux_de_garanties_collapse">
  <div class=" mb-4 ">
      <div class=" documentDiv" id="headingFolder">
          <div class="row flex-nowrap">
              <div class="col-1 mt-1 pl-0">
                  <fa-icon class="folder-icon" [icon]="fa['faFolder']"></fa-icon>
              </div>
              <div class="col-10 text-left cursor" data-toggle="collapse"
                  data-target="#tableaux_de_garanties_collapse" aria-expanded="false"
                  aria-controls="collapseTwo">
                  <p class="text-dark ml-3 mt-2">
                      Tableaux de garanties (Fichier PDF)
                  </p>
              </div>
              <div class="col-1 text-right mt-0 position-relative pl-0">
                  <button type="button" mat-icon-button
                      [matMenuTriggerFor]="menu_tableaux_de_garanties"
                      aria-label="Example icon-button with a menu">
                      <fa-icon class="bi-three-dots-vertical"
                          [icon]="fa['faEllipsisV']"></fa-icon>
                  </button>
                  <mat-menu #menu_tableaux_de_garanties="matMenu">
                      <button mat-menu-item>
                          <input type="file" accept=".pdf" id="tableaux_de_garanties"
                              name="tableaux_de_garanties"
                              hidden ngModel #tableaux_de_garanties="ngModel" />
                          <label for="tableaux_de_garanties" class="add-file">
                              <fa-icon [icon]="fa['faPlusCircle']"></fa-icon>
                              Ajouter un ficher
                          </label> </button>
                  </mat-menu>
              </div>
          </div>
      </div>
      <div id="tableaux_de_garanties_collapse" 
          class="collapse" aria-labelledby="headingFolder"
          data-parent="#folder_tableaux_de_garanties_collapse">
          <div class="">
              <div class="row d-flex d-flex align-items-center blocDocChild my-2">
                  <!-- <div class="col-1 pl-3">
                      <fa-icon class="file-icon" [icon]="fa['faFile']"></fa-icon>
                  </div> -->
                  <div class="col-11 pl-4">
                 
                          <div >
                              <a href="lienDoc" target="_blank" class="file-name ">
         nom doc

                              </a>
                          </div>

                  </div>
                  <div class="col-1">
                      <button type="button" class="deleteFileDoc " 
                         >
                          <img src="assets/icons/closeIcon.svg">
                      </button>

                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<app-code [code]="code_card_doc"></app-code>
<div class="row">
  <div class="col-md-4 col-sm-12">
    <div class="card-folder border border-primary mb-4 ">
      <div class="card-header">
        <div class="row flex-nowrap" >
            <div class="col-1 mt-2 pl-0">
                <fa-icon  class="file-icon"  [icon]="fa['faFile']"></fa-icon>
            </div>
            <div class="col-9 text-left">
                <p class="text-dark ml-3 mt-2">
                 Nom du Fichier
                </p>
            </div>
            <div class="col-2 text-right mt-2 position-relative pl-0" >
                <fa-icon  class="file-icon"  [icon]="fa['faTrash']"></fa-icon>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-code [code]="code_card_file"></app-code>
<div class="row">
  <div class="col-12">
    <h4>Sous Titres : </h4>
  </div>
  <div class="col-12">
    <label class="card-title">
      <fa-icon  [icon]="fa['faStream']">
      </fa-icon>
      Enregistrements & Documents</label>
  </div>
  <div class="col-12">
    <label class="card-title">
      <fa-icon  [icon]="fa['faStream']">
      </fa-icon>
      Enregistrements & Documents
    </label>
  </div>
  <div class="col-12">
    <label class="card-title">
      <fa-icon  [icon]="fa['faStream']">
      </fa-icon>
      Enregistrements & Documents
    </label>
  </div>
</div>
<label>Card : </label>
<div class="card-body-details p-3">
  Content
  <p>
    Compagnie n° :
  <label class="label-card-info">
    1215183
  </label>
  </p>
  <div class="alert-erreur">
    <fa-icon [icon]="fa['faExclamationTriangle']"></fa-icon>
    Alert d'erreur
  </div>
</div>

<app-code [code]="cardbody"></app-code>

<div class="row">
  <h4>Form Group:</h4>
</div>
<div class="row" style="background-color: white">
  <div class="col-7">
    <div class="form-group-container">
      <p class="form-group-container-title">Contact</p>
      <div class="row">
        <!--Email-->
        <div class="col-12">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>Email</mat-label>
            <input matInput type="email">
          </mat-form-field>

        </div>
        <!--Téléphone-->
        <div class="col-12">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>Téléphone</mat-label>
            <input type="tel" matInput placeholder="xxx-xxx-xxx" maxlength="9">
          </mat-form-field>
        </div>
        <!--2ème Téléphone-->
        <div class="col-12">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>2ème Téléphone</mat-label>
            <input type="tel" matInput placeholder="xxx-xxx-xxx" maxlength="9">
          </mat-form-field>
        </div>
        <!--Mobile-->
        <div class="col-12">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>Mobile</mat-label>
            <input type="mobile" matInput placeholder="xxx-xxx-xxx" maxlength="9">
          </mat-form-field>
        </div>
      </div>
    </div>

  </div>
</div>
<app-code [code]="code_form_group"></app-code>


<div class="row">
  <div class="col-12">
    <div class="fileContainer p-3">
      <div class="row">
        <h2 class="title-details-blue ml-1">
          <fa-icon  [icon]="fa['faStream']"></fa-icon>
          Joindre des fichiers
        </h2>
        <button type="button" class="btn-document-add  ajoutBtnDoc" data-title="Ajouter un fichier">
          <input type="file" hidden #fileDropRef2 id="audioDropRef2" accept="application/pdf" multiple  total-max-size="2000" />
          <label for="audioDropRef2" class="add-file">
              <fa-icon class="fa fa-lg fa-1x mt-2 " [icon]="fa['faPaperclip']"></fa-icon>
          </label>
        </button>
      </div> 
                  <div class="row scrollbar fileContainerChild">
                        <div class=" col-4  fileComment py-2 m-2  d-flex  align-items-center " >
                        <button  type="button"  class="deleteFile">
                          <img  src='../../../assets/icons/deleteIcon.svg'>
                          </button>
                   
                        <img  src='../../../assets/icons/FileIcon.svg'>
                            
                              
                       <span class="file-name  links ml-2">Nom du document  </span>
                   </div>
                   
               

          
           
            </div>
  </div>
</div>
<app-code [code]="code_list_tache_and_file"></app-code>
</div>

<div class="row">
  <div class="shadow-box-card-body">
  Content
</div>
</div>


<app-code [code]="boxShadowCard"></app-code>
