

<div class="card-head text-center">
    <div class="row details-header">
        <button type="button" (click)="retourToList()" class="btn-dt-retourr mt-1" >
            <div class="icon-position">
                <fa-icon class="fa-icon-style" [icon]="fa['faArrowLeft']"></fa-icon>&nbsp;
            </div>
        </button>
        <div class="row">
            <div class="row details-spinner">

                <div class="col mr-3">
                    <button type="button" class="btn-load" (click)="clearForm()">
          <fa-icon [icon]="fa['faRedo']"></fa-icon>
    </button>
                </div>
            </div>
            <button type="button" class="btn-dt-save" (click)="add()">
    <fa-icon class="fa-lg" [icon]="fa['faCheckCircle']"></fa-icon>&nbsp;
      Enregistrer
  </button>
        </div>
    </div>
</div>
<!--
-->

<div class="card border-0 m-3 mb-5 p-3 ">
    <div class="row ">
        <div class="col-md-6">
            <h2 *ngIf="ModeAjout">Ajouter groupe garantie</h2>
            <h2 *ngIf="!ModeAjout">Modifier groupe garantie</h2>

        </div>

    </div>
    <div class="row">
        <div class="col-12">
            <span class="text-danger">( * ) champs obligatoire!</span>
        </div>
    </div>
    <hr>

    <div class="row ">
        <div class="col">
            <form [formGroup]="grantieForm" (ngSubmit)="add()">

                <div class="row mt-3">
                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="w-100">
                                <mat-label>Libelle</mat-label>
                                <input type="text" matInput formControlName="libelle" required placeholder="Libelle">
                                <mat-error
                                *ngIf="grantieForm.get('libelle').hasError('required') && grantieForm.get('libelle').touched">
                               Libelle est requis.
                            </mat-error>
                            </mat-form-field>
                        </div>
                    </div>



                    <div class="col-md-6 col-sm-12">
                        <div class="form-group">
                            <mat-form-field class="w-100">
                                <mat-label>Description</mat-label>
                                <textarea matInput placeholder="Description..." rows="1" formControlName="description"></textarea>
                            </mat-form-field>
                        </div>
                    </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                    <app-icon-picker 
                                    [searchText]="icon" 
                                    [req]="false" 
                                    (onChangeIcon)="onSelectIcon($event)" 
                                    (onChangeNameIcon)="onSelectNameIcon($event)"
                                    (checkedNameIcon)="onCheckedNameIcon($event)"
                                    ></app-icon-picker>
                        </div>
                    </div>
                </div>
            </form>

        </div>

    </div>



</div>