/* eslint-disable accessor-pairs */
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  Injectable,
} from '@angular/core';
import {
  DayService,
  WeekService,
  WorkWeekService,
  MonthService,
  AgendaService,
} from '@syncfusion/ej2-angular-schedule';
import * as fa from '@fortawesome/free-solid-svg-icons';

import frLocale from '@fullcalendar/core/locales/fr';
import { ListTypeRendezVous } from '../../../../../entity/Opportunity/ListTypeRendezVous';
import { RendezVousCalendar } from '../../../../../entity/RendezVousCalendar';
import { Commerciaux } from '../../../../../entity/Opportunity/Commerciaux';
import { ApiOpportunitService } from '../../../../../services/ApiOpportunite/api-opportunite.service';
import { Calendar } from '../../../../../entity/Opportunity/Calendar';
import { CalendarOptions } from '@fullcalendar/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { NotificationsService } from '../../../../../shared/NotificationsService/notifications.service';
import { ApiRdvService } from '../../../../../services/ApiRdv/api-rdv.service';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import * as moment from 'moment';
import { ThemePalette } from '@angular/material/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import swal from 'sweetalert2';
export class TodoItemNode {
  // eslint-disable-next-line no-use-before-define
  children: TodoItemNode[];
  item: string;
  id: string;
}
export class TodoItemFlatNode {
  item: string;
  level: number;
  expandable: boolean;
  id: string;
}

@Injectable({ providedIn: 'root' })
export class ChecklistDatabase {
  dataChange: BehaviorSubject<TodoItemNode[]> = new BehaviorSubject<TodoItemNode[]>([]);

  get data(): TodoItemNode[] {
    return this.dataChange.value;
  }

  constructor() {
    this.initialize();
  }

  initialize() {}

  buildFileTree(obj: { [key: string]: any }, level: number, keyLevels: number): TodoItemNode[] {
    return Object.keys(obj).reduce<TodoItemNode[]>((accumulator, key) => {
      const value = obj[key];

      const node = new TodoItemNode();

      node.item = key;

      if (value === null || value === undefined) {
        // no action
      } else if (typeof value === 'object' && level < keyLevels) {
        node.item = value.name;
        node.children = this.buildFileTree(value.children, level + 1, keyLevels);
      } else if (typeof value === 'object') {
        node.item = value.etat;
        node.id = value.id;
        if (value.child.length > 0) {
          node.children = this.buildFileTree(value.child, level + 1, keyLevels);
        }
      } else {
        node.item = value;
        node.id = value;
      }

      return accumulator.concat(node);
    }, []);
  }
}

@Component({
  selector: 'app-calendrier',
  templateUrl: './calendrier.component.html',
  styleUrls: ['./calendrier.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService],
})
export class CalendrierComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() calendar: Calendar = null;
  @Output() onUpdateInfo: EventEmitter<Calendar> = new EventEmitter();
  @Output() onSelectStatus: EventEmitter<{ id: any; item: any }> = new EventEmitter();
  @Input() listCommerciaux: Commerciaux[] = [];

  listTypeRendezVous: ListTypeRendezVous[] = [];
  RdvTerrain: boolean = false;
  selectData: any;
  searchVal: string;
  selectedCommerce: any = null;
  calendarClone: Calendar = new Calendar();
  fa = fa;

  statutFilter: Observable<any[]> = null;
  status: any;
  loaderRDV: boolean = true;
  listCommerciauxFilter: Observable<Commerciaux[]>;
  private listStatus: any[] = [];
  /* Type Rendez vous */
  opportunitieTypeForm = new FormControl(null, [Validators.required]);
  /* Afffected To Commercial */
  affecterAFormControl: FormControl = new FormControl(null, [Validators.required]);
  /* Status de départ */
  statusForm: FormControl = new FormControl(null, [Validators.required]);
  selectedStatuss: any;

  constructor(
    private checklistDatabase: ChecklistDatabase,
    private apiOpportuniteService: ApiOpportunitService,
    private modalService: NgbModal,
    private RdvService: ApiRdvService,
    public datepipe: DatePipe,
    public NotificationsService: NotificationsService
  ) {}

  ngAfterViewInit(): void {}

  @Input() set listStatusCampagne(value: any) {
    if (value !== null) {
      this.selectedStatuss = value.statut_depart;
      this.listStatus = this.checklistDatabase.buildFileTree(value.tree, 0, 1);

      if (this.dataSource.data !== this.listStatus) {
        this.dataSource.data = this.listStatus;
        this.expand(this.dataSource.data, this.selectedStatuss?.id);
      }
    }
  }

  expand(data: TodoItemNode[], uniqueId: string): any {
    data.forEach((node) => {
      if (node.children && node.children.find((c) => c.id === uniqueId)) {
        this.treeControl.expand(node);
        this.checklistSelection.isSelected(this.selectedStatuss.id);
        this.expand(this.dataSource.data, node.id);
      } else if (node.children && node.children.find((c) => c.children)) {
        this.expand(node.children, uniqueId);
      }
    });
  }

  ngOnInit(): void {
    this.dateFinControl.valueChanges.subscribe((data) => {
      if (data === (null || undefined)) {
        return null;
      }
      // if (data < this.dateDebutControl.value) {
      //   alert('Date fin doit etre obligatoirement superieur a la date de début du rendez-vous');
      //   let date = new Date();
      //   date.setDate(this.dateDebutControl.value.getDate());
      //   date.setTime(this.dateDebutControl.value.getTime() + (1 * 60 * 60 * 1000));
      //   this.dateFinControl.setValue(date);
      // }
    });
    this.dateDebutControl.valueChanges.subscribe((data) => {
      const date = new Date();
      // if (data < date) {
      //   alert('La date du rendez-vous doit être obligatoirement dans le futur');
      //   data.setTime(date.getTime() + (1 * 60 * 1000));
      //   this.dateDebutControl.setValue(date);
      // }
      if (data === (null || undefined)) {
        return null;
      }
      // if (this.dateFinControl.value == (null || undefined) || this.dateFinControl.value <= data) {
      //   date = new Date();
      //   date.setDate(data.getDate());
      //   date.setTime(data.getTime() + (1 * 60 * 60 * 1000));
      //   this.dateFinControl.setValue(date);
      // }
    });
    this.getTypeRendezvous();
    this.listCommerciauxFilter = this.affecterAFormControl.valueChanges.pipe(
      map((value) => (typeof value === 'string' ? value : value?.nom + ' ' + value?.prenom)),
      map((value) => {
        return this._affecterAfilter(value);
      })
    );
  }

  parse(value: string): any {
    const parts = value.split(',');
    const dateparts = parts[0].split('/');
    const timeparts = parts[1].split(':');
    const date = new Date(+dateparts[2], +dateparts[1] - 1, +dateparts[0], +timeparts[0], +timeparts[1], +timeparts[2]);
    return date;
  }

  changerdatedebut(event) {
    const date = this.parse(event.targetElement.value);
    this.dateDebutControl.setValue(date);
  }

  changerdatefin(event) {
    const date = this.parse(event.targetElement.value);
    this.dateFinControl.setValue(date);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calendarClone = Object.assign({}, this.calendar);
    if (this.calendarClone.affecter == null || this.calendarClone.affecterLibelle == null) {
      this.calendarClone.affecter = localStorage.getItem('id_user');
      this.calendarClone.affecterLibelle = localStorage.getItem('nom') + ' ' + localStorage.getItem('prenom');
    }
    this.opportunitieTypeForm.setValue(`${this.calendarClone.id_type_opportunity}`);
    this.affecterAFormControl.setValue(this.calendar.affecterLibelle);
    this.RdvTerrain = false;
    this.RechargerListeRdv();
  }

  selectTypeRdv(value: any) {
    this.calendarClone.id_type_opportunity = +value;
    this.pushInfo();
  }

  onSelectStatut(item) {
    this.calendarClone.status = item.id;
    this.status = item.id;
    this.pushInfo();
  }

  selectCommercieaux(selectedCommercial) {
    this.calendarClone.affecterLibelle = selectedCommercial.nom + ' ' + selectedCommercial.prenom;
    this.calendarClone.affecter = selectedCommercial.id_md5;
    this.pushInfo();
  }

  private _affecterAfilter(value: string): Commerciaux[] {
    const filterValue = value.toLowerCase();
    return this.listCommerciaux.filter(
      (option) => (option.nom + ' ' + option.prenom).toLowerCase().indexOf(filterValue) > -1
    );
  }

  getTypeRendezvous() {
    this.apiOpportuniteService.getListRDV().subscribe((data: ListTypeRendezVous[]) => {
      this.loaderRDV = false;
      this.listTypeRendezVous = data;
    });
  }

  pushInfo() {
    this.calendarClone.invalidForms = [];
    if (this.calendarClone.id_type_opportunity == null) {
      this.calendarClone.invalidForms.push('type_opportunite');
    }
    if (this.calendarClone.id_type_opportunity !== 4) {
      if (this.calendarClone.dateDebut?.length < 1 || this.calendarClone.dateFin?.length < 1) {
        this.calendarClone.invalidForms.push('date_rendez_vous');
      }
    }
    this.onUpdateInfo.emit({ ...this.calendarClone });
  }

  /* --------------------------------- Mat Tree ------------------------------------------------- */
  treeControl = new NestedTreeControl<any>((node) => node.children);
  dataSource = new MatTreeNestedDataSource<any>();

  /** The selection for checklist */
  checklistSelection = new SelectionModel<any>(false /* multiple */);

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: any): void {
    this.checklistSelection.toggle(node);
    const selectedStatus = this.checklistSelection.selected[0];

    if (selectedStatus === undefined || selectedStatus === null) {
      this.calendarClone.status = null;
    } else {
      this.calendarClone.status = selectedStatus.id;
    }
    this.onSelectStatus.emit({ id: selectedStatus.id, item: selectedStatus.item });
  }

  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;
  status_depart = null;

  /* --------------------------------- Mat Tree ------------------------------------------------- */

  /* ---------------------------------RDV Caledar ------------------------------------------------- */

  @ViewChild('OppUpdateRdvModal') OppUpdateRdvModal: ElementRef;
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  RendezVousUser: RendezVousCalendar[] = [];

  RendezVous: RendezVousCalendar[] = [];

  calendarOptions: CalendarOptions = {
    initialView: 'timeGridWeek',
    timeZone: 'local',
    locale: 'fr',
    locales: [frLocale],
    dateClick: this.handleDateClick.bind(this),
    eventClick: this.handleEventClick.bind(this),
    select: this.handleDateSelect.bind(this),
    datesSet: this.visibleRangeHandler.bind(this),
    eventResize: this.handleEventUpdate.bind(this),
    eventDrop: this.handleEventUpdate.bind(this),
    customButtons: {
      dateRdv: {
        text: 'Date de rendez-vous',
        click: this.gotToRdvDateHandler.bind(this),
      },
    },
    events: this.RendezVous,
    headerToolbar: {
      center: 'dayGridMonth timeGridWeek',
      end: 'dateRdv today prevYear,prev,next,nextYear', // will normally be on the right. if RTL, will be on the left
    },
    nowIndicator: true,
    editable: true,
    selectable: true,
  };

  handleEventUpdate(event) {
    this.dateDebutControl.setValue(event.event.start);
    this.dateFinControl.setValue(event.event.end);
    const dateDebut: Date = new Date(this.dateDebutControl.value);
    const dateFin: Date = new Date(this.dateFinControl.value);
    this.calendarClone.dateDebut = dateDebut.toISOString();
    this.calendarClone.dateFin = dateFin.toISOString();
    this.pushInfo();
  }

  handleDateClick(arg) {
    if (this.calendarComponent.getApi().view.type === 'dayGridMonth') {
      this.calendarComponent.getApi().changeView('timeGridWeek', arg?.date?.toISOString());
      return null;
    }
    // if (new Date(arg.date) <= new Date()) {
    //   let notificationMessage = new NotificationMessage();
    //   notificationMessage.type = NotificationType.warning;
    //   notificationMessage.message = 'Rendez-vous doit être supérieur à la date de l\'opportunité';
    //   this.NotificationsService.sendMessage(notificationMessage);
    // } else {
    // if (new Date(arg.date) <= new Date()) {
    //   let notificationMessage = new NotificationMessage();
    //   notificationMessage.type = NotificationType.warning;
    //   notificationMessage.message = 'Rendez-vous doit être supérieur à la date de l\'opportunité';
    //   this.NotificationsService.sendMessage(notificationMessage);
    // } else
    else {
      this.dateDebutControl.setValue(arg.date);
      this.modalService.open(this.OppUpdateRdvModal, { ariaLabelledBy: 'modal-basic-title' });
      // }
    }
  }

  handleDateSelect(event) {
    if (this.calendarComponent.getApi().view.type === 'dayGridMonth') {
      return null;
    }
    // if (new Date(event.date) <= new Date()) {
    //   let notificationMessage = new NotificationMessage();
    //   notificationMessage.type = NotificationType.warning;
    //   notificationMessage.message = 'Rendez-vous doit être supérieur à la date de l\'opportunité';
    //   this.NotificationsService.sendMessage(notificationMessage);
    // } else {
    //   if (new Date(event.start) <= new Date()) {
    //     let notificationMessage = new NotificationMessage();
    //     notificationMessage.type = NotificationType.warning;
    //     notificationMessage.message = 'Rendez-vous doit être supérieur à la date de l\'opportunité';
    //     this.NotificationsService.sendMessage(notificationMessage);
    //   } else
    else {
      this.dateDebutControl.setValue(event.start);
      this.dateFinControl.setValue(event.end);
      this.modalService.open(this.OppUpdateRdvModal, { ariaLabelledBy: 'modal-basic-title' });
      // }
    }
  }

  handleEventClick(info) {
    info.jsEvent.preventDefault();
    if (info.event.id === '-1') {
      this.calendarClone.dateDebut = info.event.startStr.split('Z')[0];
      this.calendarClone.dateFin = info.event.endStr.split('Z')[0];
      this.modalService
        .open(this.OppUpdateRdvModal, { ariaLabelledBy: 'modal-basic-title' })
        .result.then((result) => {});
    } else {
      window.open(info.event.url, '_blank');
    }
  }

  errorAlert(data) {
    swal.fire({
      title: data,
      text: 'Opération Échoué',
      icon: 'error',
      showConfirmButton: true,
    });
  }

  updateRdvDetails() {
    if (this.dateDebutControl.value === null || this.dateFinControl.value === null) {
      this.errorAlert('date saisie erronée');
      return;
    }
    if (this.dateFinControl.value < this.dateDebutControl.value) {
      this.errorAlert('Date fin doit etre obligatoirement superieur a la date de début du rendez-vous');
      return;
    }
    const dateDebut: Date = new Date(this.dateDebutControl.value);
    const dateFin: Date = new Date(this.dateFinControl.value);
    this.calendarClone.dateDebut = dateDebut.toISOString();
    this.calendarClone.dateFin = dateFin.toISOString();
    this.modalService.dismissAll();
    this.pushInfo();
  }

  addOneHour(date: string) {
    let heure = <any>Number.parseInt(date.split('T')[1].split(':')[0]) + 1;
    if (heure < 10) {
      heure = '0' + heure;
    }
    return date.split('T')[0] + 'T' + heure + ':' + date.split('T')[1].split(':')[1];
  }

  dateDebut = null;
  dateEnd = null;
  RendezVousFullInfo: any[] = [];

  RechargerListeRdv() {
    if (this.calendarClone.id_type_opportunity === (4 || undefined || null)) {
      return;
    }
    this.updateListRdv = false;
    if (this.calendarClone.affecter && this.dateDebut != null && this.dateEnd != null) {
      this.RdvService.getListeRdv(this.calendarClone.affecter, this.dateDebut, this.dateEnd)
        .pipe(take(1))
        .subscribe((Response: RendezVousCalendar[]) => {
          this.RendezVousFullInfo = Response;
          this.buildCalendarRdv(Response);
        });
    }
  }

  firstOpenCalendar: boolean = true;

  private buildCalendarRdv(rendezVousCalendar: RendezVousCalendar[]) {
    this.RendezVous = [];
    rendezVousCalendar?.forEach((element) => {
      const rdv = new RendezVousCalendar();
      rdv.title = element.title;
      rdv.id = element.id_opp_md5;
      rdv.backgroundColor = '#8ad7ff';
      rdv.url = './opportunities/details/' + element.id_opp_md5;
      rdv.start = element.start;
      rdv.end = element.end;
      this.RendezVous.push(rdv);
    });
    if (this.calendarClone.dateDebut !== (null || undefined) || this.calendarClone.dateFin !== (null || undefined)) {
      const rdv = new RendezVousCalendar();
      rdv.title = 'Opportunité courante';
      rdv.id = '-1';
      rdv.backgroundColor = '#b3ffb4';
      rdv.editable = true;
      rdv.startEditable = true;
      rdv.durationEditable = true;
      rdv.start = this.calendarClone.dateDebut;
      rdv.end = this.calendarClone.dateFin;
      this.RendezVous.push(rdv);
    }
    this.updateListRdv = true;
   
    this.calendarComponent?.getApi().setOption('events', this.RendezVous);
    this.calendarComponent?.getApi().setOption('visibleRange', {
      start: this.dateDebut,
      end: this.dateEnd,
    });
    if (this.firstOpenCalendar) {
      this.gotToRdvDateHandler(null);
      this.firstOpenCalendar = false;
    }
  }

  gotToRdvDateHandler(event) {
    if (this.calendarClone.dateDebut !== (null || undefined || '')) {
      this.calendarComponent.getApi().gotoDate(this.parseDate(this.calendarClone.dateDebut));
    } else {
      this.calendarComponent?.getApi().gotoDate(new Date().toISOString());
    }
  }

  parseDate(dateStr: string, format: string = 'YYYY-MM-DDThh:mm:ssZ') {
    if (dateStr === (undefined || null)) {
      return null;
    } else {
      return moment(dateStr, format).toDate();
    }
  }

  visibleRangeHandler(data) {
    const startDateList = data.startStr.split('T');
    const endDateList = data.endStr.split('T');
    if (startDateList[0] !== this.dateDebut && endDateList[0] !== this.dateEnd) {
      this.dateDebut = startDateList[0];
      this.dateEnd = endDateList[0];
      this.RechargerListeRdv();
    }
  }

  /* -----------------------------END RDV Caledar------------------------------------------------- */
  updateListRdv: boolean = true;

  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  public dateDebutControl = new FormControl();
  public dateFinControl = new FormControl();
}
